import React from 'react';
import { phoneStandardView, translate, checkTextTranslation, imageAddress, priceStandardView } from '../../utils/useful'
import TLink from '../../components/TLink'
import HttpServices from '../../utils/Http.services';
import Loader from "react-loader-spinner";
import Modal from '../../components/Modal';

class MeAccounts extends React.Component {

  state = {
    data: []
  }

  componentDidMount() {
    this.fetch()
  }


  fetch() {
    this.setState({ isLoading: true })
    HttpServices.request('getAllWithData', { applet: 'accounts' }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })

      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info })
      }
    })
  }

  checkPermission(page, reference) {
    if (!page.roles) {
      return true
    }
    if (reference.info) {
      for (let i = 0; i < page.roles.length; i++) {
        const oneRole = page.roles[i];
        if (oneRole.role === reference.info.role) {
          return true
        }
      }
    }
  }


  prePay=(account)=>{
    this.setState({selectedAccount:account})
    this.paymentModal.showModal()
  }

  render() {

    return (
      <section>




        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>


          <div className="p-3 mt-4">
            <p className="text-big pb-3" style={{ borderBottom: '2px solid #ddd' }}>حساب ها</p>
            <div className="flexc">
              <div style={{ width: 100, height: 2, backgroundColor: '#FF6C00', marginTop: -2 }}></div>
              <div style={{ width: 50, height: 2, backgroundColor: '#FF6C00', marginTop: -2, marginRight: 10 }}></div>
            </div>
          </div>

          {this.state.isLoading && (
            <div className="flexcc pt-2" style={{}}>
              <Loader
                type={"Oval"}
                color={"rgba(253,121,45,1)"}
                height={50}
                width={50}
              />
            </div>
          )}


          <div className="row px-4 pb-4 pt-2">
            {this.state.data.map((prop, index) => {

              return (
                <div key={index} className="text-center col-6 col-lg-4 flexcc flex-colomn">
                  <div className="py-3 px-4 mt-2 position-relative  border-radius-8  w-100" >
                    <img src={imageAddress(prop.values.image)} width={80} className="mb-2 mt-0" />

                    <div className="mt-0">
                      <p className="font-bold" style={{ fontSize: 18 }}>{checkTextTranslation(prop.values.name)}</p>
                      <p className="mt-1" style={{ fontSize: 16 }}>{priceStandardView(prop.values.price)} تومان</p>

                      <p className="text-smaller mt-2 mb-2">{prop.values.description}</p>

                      <button onClick={()=>this.prePay(prop)} style={{ backgroundColor: 'rgb(253, 121, 45)', borderRadius: 20, padding: '6px 15px' }}>
                        <p className="text-smaller white">ارتقاء حساب</p>
                      </button>

                    </div>


                  </div>
                </div>
              )
            }
            )}
          </div>

        </div>

        <Modal ref={el => this.paymentModal = el} maxWidth={300}>
            <div className="text-center py-4 px-4">
              <p className="text-semibig font-bold">ارتقاع حساب</p>
              <p className="text-small mt-2 mb-3">شما به درگاه بانک متصل خواهید شد و پس از پرداخت حساب کاربری شما ارتقاء خواهد یافت</p>
           
              <button style={{ backgroundColor: 'rgb(253, 121, 45)', borderRadius: 20, padding: '6px 15px' }}>
                        <p className="text-small white">پرداخت {this.state.selectedAccount?.values?.price}</p>
                      </button>
            </div>
        </Modal>

      </section>
    );
  }
}

export default MeAccounts

