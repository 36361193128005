import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { getObject } from '../../utils/useful'
import { translate } from '../../utils/useful'

class ResourcePriceTypeStep extends React.Component {
    state = {
        key: '',
        selectedType: 'photo',
        data: {},
        isLoading: false,
        formHeaders: []
    }



    nextStage = () => {
        this.setState({ error: null })
        let type = getObject(this.props.data, 'values.priceType')

        if (type == null) {
            this.setState({ error: translate('choose-one-to-continue') })
        }
        if (type == 'free') {
            this.setState({isLoading:true})
            this.props.setData({ "values.price": 0 }, () => {
                this.props.uploadResource()
            })
        }
        if (type == 'premium') {
            this.props.gotoPrice()
        }
    }

    changeType(type) {
        this.props.setData({ "values.priceType": type })
    }


    render() {
        return (
            <div className="flexcc flex-column">


                <h2 className="mt-3 mb-1" style={{ fontSize: 27 }}>{translate('price')}</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p className="text-center" style={{ fontSize: 14, margin: '0px 20px 20px 20px' }}>{translate('price-type-desc')}</p>

                    <div className="row w-100 mt-5 mb-5 text-center">
                        <div className="col-12 col-md-6">
                            <button onClick={() => this.changeType('free')} className="hover-back p-3 transition-all border-radius-8" style={{ backgroundColor: getObject(this.props.data, 'values.priceType') === 'free' ? '#def' : null, border: getObject(this.props.data, 'values.priceType') === 'free' ? '1px #bcd dashed' : '1px transparent solid' }}>
                                <img src="https://image.flaticon.com/icons/svg/3199/3199301.svg" width="100px" />
                                <p className="font-bold text-big mt-2">{translate('free')}</p>
                            </button>
                        </div>

                        <div className="col-12 col-md-6">
                            <button onClick={() => this.changeType('premium')} className="hover-back p-3 transition-all border-radius-8" style={{ backgroundColor: getObject(this.props.data, 'values.priceType') === 'premium' ? '#def' : null, border: getObject(this.props.data, 'values.priceType') === 'premium' ? '1px #bcd dashed' : '1px transparent solid' }}>
                                <img src="https://image.flaticon.com/icons/svg/1060/1060541.svg" width="100px" />
                                <p className="font-bold text-big mt-2">{translate('premium')}</p>
                            </button>
                        </div>

                    </div>

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.nextStage}
                            isLoading={this.state.isLoading}
                            text={translate(getObject(this.props.data, 'values.priceType') === 'free' ? 'upload-resource' : 'next-step')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        {this.state.error && (
                            <p style={{ fontSize: 15, margin: '5px 0px', color: '#ee5050' }}>{this.state.error}</p>
                        )}
                        <TLink to={'/pages/terms'}><p style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('resource-accept-terms')}</p></TLink>
                        {/* <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>This website uses cookies to deliver the best possible user experience. With using the website you are accepting it</p> */}
                    </div>

                    <div className="w-100 text-center mb-5">
                        <button className="mb-1" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate("back-one-stage")}</button>
                    </div>


                </div>
            </div>
        )
    }
}

export default ResourcePriceTypeStep;