import React from 'react'
import { translate, currentLng, converNumberToPersian, priceStandardView } from '../utils/useful'
import { siteTheme, siteConfig } from '../variables/config'
import TLink from './TLink'
import stripHtml from "string-strip-html"
import { imageAddress } from '../utils/useful'

class SingleProduct extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    dataBuilder(data, schema) {
        let prop = {}
        for (const [key] of Object.entries(this.state.schema)) {
            prop[key] = this.valueBuilder(schema[key], data)
        }
        return prop
    }

    valueBuilder(key, data) {
        if (key != null && data) {
            let keysArray = key.split('.')
            let newData = data
            if (data.lng && keysArray[0] === "{{lng}}") {
                newData = data.lng[currentLng()]
            } else {
                newData = data[keysArray[0]]
            }
            if (keysArray[1] != null) {
                return this.valueBuilder(keysArray[1], newData)
            } else {
                return newData
            }
        } else {
            return null
        }
    }


    render() {
        let prop = this.props.data//this.dataBuilder(this.props.data, this.props.schema)
        return (
            <TLink to={'/product'} search={(prop.values.slug ? ("?slug=" + prop.values.slug) : ("?id=" + prop._id))} ref={el => this.box = el} className="text-center mt-0 flexc h-100 flex-column pb-4" style={{ border: '0px solid #eee', borderRadius: 15, backgroundColor: this.props.background ? this.props.background : 'transparent' }} >
                <img src={imageAddress(prop.values?.images[0], 'product', 'thumb')} alt="" style={{ borderRadius: '15px 15px 0px 0px', width: '100%', objectFit: 'contain' }} />
                <div className="mb-2 px-3">
                    <p className="mt-4 text-semibig">{prop.values.name}</p>
                    {/* <p className="text-normal mt-2 mb-2" style={{ fontWeight: 'normal' }}>{stripHtml(prop.values.description)}</p> */}
                    {!this.props.dontShowPrice && (
                        <p className="text-small mt-2" >{converNumberToPersian(priceStandardView(prop.values.price))} تومان {prop?.values?.unit ? ('/' + prop?.values?.unit) : ''}</p>
                    )}
                    {/* {prop?.values?.unit && (<p className={'text-small mt-2'}>در واحد {prop?.values?.unit}</p>)} */}
                </div>
                {/* <div className="text-small" style={{ color: '#007aff' }}>{translate('learn-more')}</div> */}
                {/* <div className="text-small">{translate("price", { price: prop.values.price })}</div> */}
                {/* <div className="flexcc mt-2">
                    {prop.portals?.map((prop, index) => {
                        return (
                            <TLink pathname={'/'} className="m-1 text-small font-bold" style={{ backgroundColor: siteTheme.backgroundColors[prop], color: siteTheme.colors[prop], borderRadius: 4, padding: '5px 12px' }}>
                                {translate(prop)}
                            </TLink>
                        )
                    })}
                </div> */}
            </TLink>
        )
    }
}

export default SingleProduct;