import React from "react";
import HttpService from '../../utils/Http.services';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import TLink from "../../components/TLink";
import StagesManager from "../../components/StagesManager";
import { chooseWallpaper, checkTextTranslation, translate } from "../../utils/useful";
import GuaranteeFirstStep from "../../components/guarantee/GuaranteeFirstStep";
import GuaranteeCodeStep from "../../components/guarantee/GuaranteeCodeStep";
import GuaranteeInfoStep from "../../components/guarantee/GuaranteeInfoStep";
import GuaranteeSubmitStep from "../../components/guarantee/GuaranteeSubmitStep";
import GuaranteeSuccessStep from "../../components/guarantee/GuaranteeSuccessStep";
import FastHelmet from "../../components/FastHelmet";


class Guarantee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: ['d7e2f7', 'eee1ff', 'fedfe7', 'dad6e4', 'f7dbd7', 'fff2e1'],
      loginInfo: {},
      wallpapers: [],
      stage: 0,
      canResend: true,
      errors: [],
      data: {},

    }
  }


  componentDidMount() {

    this.setState({ data: { 'phone': '0098' } })

    this.fetchWallpapers()
    // this.changeStage('submit')

  }


  letsStart = (cb) => {
    this.changeStage('code')
  }

  goToSubmitPage = (cb) => {
    this.changeStage('submit')
  }

  postGuaranteeCode = (cb) => {
    let data = this.state.data

    if (data) {
      HttpService.request("postGuaranteeCode", { code: data.code }, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        // console.log(fetchResult)
        if (fetchResult.info) {
          this.setState({ fetchedData: fetchResult.info })
          this.changeStage('info')
        }

        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.insertData" })
    }
  }


  postGuaranteeInfo = (cb) => {
    let data = this.state.data

    if (data) {
      HttpService.request("postGuaranteeInfo", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        // console.log(fetchResult)
        this.changeStage('success')

        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.insertData" })
    }
  }





  setData = (data, cb) => {
    let newData = this.state.data
    for (const [key, value] of Object.entries(data)) {
      newData[key] = value
    }
    this.setState({ data: newData }, () => {
      if (cb) { cb() }
    })
  }


  changeStage = (newStage, cb) => {
    if (this.stageManager) {
      this.stageManager.changeStage(newStage, cb)
    }
  }

  lastStage = () => {
    if (this.stageManager) {
      this.stageManager.lastStage()
    }
  }

  fetchWallpapers() {
    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'guarantee' } }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      console.log(fetchResult)
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  render() {
    return (
      <section className="d-flex w-100 position-relative" style={{ minHeight: '100vh', padding: '0px' }}>

        <FastHelmet title={translate('guarantee')} description={translate('guarantee')} />


        <section className="row p-0 w-100">

          <section className="d-none d-md-block col-12 col-md-5 col-lg-4" style={{ height: '100vh', position: 'sticky', top: 0, padding: '100px 30px 20px 30px', textAlign: 'center', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="h-100 w-100" style={{ background: '#00000050', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ position: 'relative' }}>
              <h1 className="text-mega-big white">OPIA</h1>
              <h1 className="text-big white">{checkTextTranslation('{{lang}}Guarantee')} </h1>
              <p className="white opacity-7" style={{ fontSize: 17, marginTop: 10, }}>{checkTextTranslation('{{lang}}quarantee-side-desc')}</p>
              {/* <p className="white opacity-7" style={{ fontSize: 17, marginTop: 10, }}>We respects your <TLink pathname="/pages/policy" style={{ textDecoration: 'underline', cursor: 'pointer' }}>privacy</TLink> and we expect you respect the <TLink pathname="/pages/terms" style={{ textDecoration: 'underline', cursor: 'pointer' }}>terms</TLink></p> */}
            </div>
          </section>

          <section className="col-12 col-md-7 col-lg-8" style={{ paddingTop: 100, opacity: this.state.mainOpacity, transition: 'all 0.5s', flex: '1 1 auto' }}>

            <StagesManager ref={el => this.stageManager = el} >
              <GuaranteeFirstStep initData={this.state.data} setData={this.setData} letsStart={this.letsStart} stage={0} stageName={'init'} />
              <GuaranteeCodeStep initData={this.state.data} setData={this.setData} postGuaranteeCode={this.postGuaranteeCode} stage={1} stageName={'code'} />
              <GuaranteeInfoStep initData={this.state.fetchedData} setData={this.setData} goToSubmitPage={this.goToSubmitPage} stageName={'info'} />
              <GuaranteeSubmitStep initData={this.state.data} setData={this.setData} postGuaranteeInfo={this.postGuaranteeInfo} stageName={'submit'} />
              <GuaranteeSuccessStep initData={this.state.data} stageName={'success'} />
            </StagesManager>

          </section>
        </section>

      </section>
    )
  }
}


const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Guarantee);
