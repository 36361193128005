import React from 'react';
import { phoneStandardView, translate, checkTextTranslation } from '../../utils/useful'
import TLink from '../../components/TLink'

class MeMainPage extends React.Component {

  checkPermission(page, reference) {
    if (!page.roles) {
      return true
    }
    if (reference.info) {
      for (let i = 0; i < page.roles.length; i++) {
        const oneRole = page.roles[i];
        if (oneRole.role === reference.info.role) {
          return true
        }
      }
    }
  }


  render() {

    return (
      <section>

        <div className="w-100 py-4 px-5 flexcb mb-2" style={{ backgroundImage: 'linear-gradient(#fbdfaa,#fce7b4)', borderRadius: 8 }}>
          <div className="">
            <p className="font-bold text-big">خرید بسته جدید</p>
            <p className="text-small mt-2">توضیحاتی در رابطه با مزایای حساب کاربری در اینجا آورده میشود</p>
            <TLink to={'/me/accounts'} className="flexc">
              <div className="px-4 py-2 mt-2" style={{ borderRadius: 8, backgroundColor: '#fff' }}>
                <p className="text-small">مشاهده</p>
              </div>
            </TLink>
          </div>
          <div>
            <img src="/images/1375333.svg" width="90px" alt="" />
          </div>
        </div>


        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>


          <div className="p-3 mt-4">
            <p className="text-big pb-3" style={{ borderBottom: '2px solid #ddd' }}>{translate('dashboard')}</p>
            <div className="flexc">
              <div style={{ width: 100, height: 2, backgroundColor: '#FF6C00', marginTop: -2 }}></div>
              <div style={{ width: 50, height: 2, backgroundColor: '#FF6C00', marginTop: -2, marginRight: 10 }}></div>
            </div>
          </div>

          <div className="row p-4 ">
            {this.props.tabs.map((prop, index) => {
              if (prop.showInDashboard && prop.check != "isCounterpart" || (prop.check == "isCounterpart" && this.props.user.isCounterpart)) {
                if (prop.check == 'user' && this.props.user.isCounterpart) {
                  return
                }
                if (this.checkPermission(prop, this.props.user)) {

                  return (
                    <div key={index} onClick={() => this.props.changePage(prop.key)} className="text-center col-6 col-lg-3 flexcc flex-colomn">
                      <button className="py-3 px-4 mt-2 position-relative button-light-hover1 border-radius-8  w-100" >
                        {/* <img src={prop.icon} width={60} className="mb-3 mt-2" /> */}
                        <i className={"fa fa-" + prop.icon} style={{ color: '#FF6C00', fontSize: 40 }}></i>

                        <div className="mt-2">
                          <p style={{ fontSize: 14 }}>{checkTextTranslation(prop.title)}
                            {prop.notif && (<span className={" white"} style={{ backgroundColor: '#ee5050', borderRadius: 20, padding: '2px 8px', height: 25, minWidth: 30 }}>3</span>)}
                          </p>

                          {/* <p className="mt-1 opacity-7">this is the description for this module</p> */}
                        </div>


                      </button>
                    </div>
                  )
                }
              }
            })}
          </div>

        </div>

      </section>
    );
  }
}

export default MeMainPage

