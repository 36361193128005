import React from 'react'
import { translate, checkTextTranslation, capitalLetter } from '../utils/useful'
import TLink from './TLink'
import { siteTheme } from '../variables/config'
import { withRouter } from 'react-router-dom'

class ChilikSearch extends React.Component {
    state = {
        currentKey: 'all',
        showTypes: false,
        types: {
            all: { name: '{{lang}}all', key: 'all' },
            photos: { name: '{{lang}}photos', key: 'photos' },
            vectors: { name: '{{lang}}vectors', key: 'vectors' },
        }
    }

    componentDidMount() {
        this.updateKeyword(this.props.searchkeyword)
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props.searchkeyword)
        if (prevProps.searchkeyword != this.props.searchkeyword) {
            this.updateKeyword(this.props.searchkeyword)
        }
    }

    updateKeyword(searchkeyword) {
        let type = this.state.types[this.props.type] ? this.props.type : 'all'
        this.setState({ searchkeyword, currentKey: type })
    }


    changeType(type) {
        this.setState({ currentKey: type.key, showTypes: false })
    }

    showTypes() {
        this.setState({ showTypes: true })
        document.addEventListener('mousedown', this.hideTypes);
    }

    hideTypes = () => {
        setTimeout(() => {
            this.setState({ showTypes: false })
            document.removeEventListener('mousedown', this.hideTypes);
        }, 300);
    }



    search() {
        if (this.state.searchkeyword && this.state.searchkeyword != '') {
            let keyword = this.state.searchkeyword.replace(/ /g, '-')
            this.props.history.push('/search/' + keyword + '?type=' + this.state.currentKey)
        }
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    render() {

        return (

            <div className="flexcc w-100" style={{ position: 'relative' }} >
                <div className="flexcc " style={{ width: '90%', padding: '0px 10px', height: 50, width: '80%', maxWidth: 500, backgroundColor: '#ffffffee', borderRadius: 8, border: '1px solid #fff', backdropFilter: 'blur(10px) saturate(180%)', }}>
                    <div style={{ position: 'relative' }}>
                        <button onClick={() => this.showTypes()} className="p-2 search-border">{checkTextTranslation(this.state.types[this.state.currentKey].name)}</button>
                        <div className="blur-back px-3 py-2 text-start" style={{ zIndex: 1, boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', display: this.state.showTypes ? "block" : "none", position: 'absolute', top: 45, backgroundColor: '#ffffffee', borderRadius: 4 }}>
                            {Object.values(this.state.types).map((prop, index) => {
                                return (
                                    <button key={index} onClick={() => this.changeType(prop)} >
                                        <span className="text-small opacity-9" >{checkTextTranslation(prop.name)}</span>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <input onKeyDown={(e) => this._handleKeyDown(e)} value={this.state.searchkeyword} onChange={e => this.setState({ searchkeyword: e.target.value })} className="w-100 h-100" placeholder={translate('search-resources')} style={{ padding: '5px 20px', fontSize: 16, backgroundColor: 'transparent', border: 'none' }} />
                    <button onClick={() => this.search()} className="p-0 mt-1"><img src="https://image.flaticon.com/icons/svg/483/483356.svg" height="30px" alt="" /></button>

                </div>
            </div>
        )
    }
}

export default withRouter(ChilikSearch)