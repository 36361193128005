import React from 'react'

class RandomBack extends React.Component {
    state = {
        key: '',
        primeColor: 0,
        secondColor: 1,
        sprimeColor: 0,
        ssecondColor: 1,
        firstBack: true,
    }

    componentDidMount() {
        this.changeColor()
        this.timer = setInterval(this.changeColor, 3000)
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer)
    }

    changeColor = () => {

        let primeColor = this.selectColor(this.state.firstBack ? "primeColor" : "sprimeColor")
        let secondColor = this.selectColor(this.state.firstBack ? "secondColor" : "ssecondColor", primeColor)
        if (this.state.firstBack) {
            this.setState({ sprimeColor: primeColor, ssecondColor: secondColor, firstBack: !this.state.firstBack })

        } else {
            this.setState({ primeColor: primeColor, secondColor: secondColor, firstBack: !this.state.firstBack })
        }
    }

    selectColor(whatColor, prime) {
        const min = 0;
        const max = this.props.colors.length - 1;
        const color = Math.floor(min + Math.random() * (max - min))

        if (this.state[whatColor] === color) {
            this.selectColor(whatColor, prime)
        } else {
            if (prime === color) {
                this.selectColor(prime)
            } else {
                return color
            }
        }
    }


    render() {
        return (
            <div className="w-100 h-100 position-absolute" style={{ zIndex: -1, top: 0, left: 0 }}>
                <div className="h-100 w-100 position-absolute" style={{ top: 0, left: 0, opacity: this.state.firstBack ? 1 : 0, transition: 'all 2s', background: "linear-gradient(to right bottom, #" + this.props.colors[this.state.primeColor] + " ,#" + this.props.colors[this.state.secondColor] + ")" }}></div>
                <div className="h-100 w-100 position-absolute" style={{ top: 0, left: 0, opacity: this.state.firstBack ? 0 : 1, transition: 'all 2s', background: "linear-gradient(to right bottom, #" + this.props.colors[this.state.sprimeColor] + " ,#" + this.props.colors[this.state.ssecondColor] + ")" }}></div>
            </div>
        )
    }
}

export default (RandomBack);