import React from 'react';
import { translate } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
// import FormViewer from '../../components/FormViewer'
// import Loader from 'react-loader-spinner'
import Modal from '../../components/Modal'
// import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';
// import moment from 'moment'
import moment from 'jalali-moment'


class MeOrders extends React.Component {


  state = {
    data: [],

    headers: [
      // { title: 'Description', key: 'description', type: 'text', showMain: false, },
      // { title: 'Country', type: 'select', key: 'country', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'countries' } }, settings: { required: false }, showMain: true },
      // { title: 'City', type: 'select', key: 'city', dependencies: [{ type: 'filter', action: 'enable', field: 'country', param: 'values.country' }], options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
      { type: 'SelectInput', key: 'values.city', information: { label: 'City', address: 'getAllWithData', filter: { applet: 'cities' }, fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: '{{lang}}insertTags', required: true }, options: { type: 'api', fields: { value: '_id', title: 'values.name', placeholder: '' }, address: '/values/getAll', filter: { page: 'cities' }, placeholder: '' } },
      { type: 'TextInput', key: 'values.address', information: { label: 'Address', placeholder: '{{lang}}insertAddress', required: true } },
      { type: 'TextInput', key: 'values.postalCode', information: { label: 'Postal code', placeholder: '{{lang}}insertAddress', required: true }, },
      // { type: 'TextInput', key: 'project', information: { label: 'Project Name', placeholder: '{{lang}}insertAddress', required: true } },
      // { title: 'Recipient Name', type: 'text', key: 'recipient', showMain: true },

      // { title: 'Submit Address', type: 'button', function: 'submit', options: { color: "#000", fontcolor: '#fff' }, showMain: true },
    ]
  }

  componentDidMount() {
    this.getOrders()
  }

  openModal(index) {

    let data = {}
    if (index != -1) {
      data = { ... this.state.data[index] }
    }
    // console.log(data)
    this.setState({ selectedOrder: data }, () => {

      this.orderInfoModal.showModal()
    })
  }


  // postAddress = () => {
  //   let data = this.form.getForm()
  //   if (data) {
  //     this.setState({ isLoading: true, errors: {} })
  //     data.applet = 'addresses'
  //     HttpService.request('submitForm', data, (fetchResult, fetchError) => {
  //       if (fetchError) { this.setState({ errors: fetchError }); return }
  //       this.setState({ isLoading: false })
  //       this.addAddress.hideModal()
  //       this.getOrders()
  //     })
  //   }
  // }

  getOrders = () => {
    this.setState({ isLoading: true, errors: {} })
    HttpService.request('getOrders', { applet: 'orders' }, (fetchResult, fetchError) => { // filter: { '**creator': true }
      this.setState({ isLoading: false })

      if (fetchError) { this.setState({ errors: fetchError.message }); return }
      // console.log()
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info }, () => {
          // this.initData()
        })
      }
      // this.getOrders()
    })

  }


  // initData() {
  //   if (Array.isArray(this.state.data) && this.props.data.address?._id) {
  //     this.state.data.forEach((oneData, index) => {
  //       if (oneData._id === this.props.data.address?._id) {
  //         this.setState({ selectedIndex: index })
  //       }
  //     });
  //   }
  // }


  // getInfo = (cb) => {
  //   if (this.state.selectedIndex >= 0) {
  //     let values = this.state.data[this.state.selectedIndex].values
  //     values.city = values.city._id
  //     values._id = this.state.data[this.state.selectedIndex]._id
  //     let data = { address: values }
  //     cb(data)
  //   } else {
  //     cb(null, "{{lang}}errors.selectAddress")
  //   }
  //   // return data
  // }


  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>
          <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('myOrders')}</p>
          </div>


          <div className="w-100" style={{ padding: '20px 25px 0px 25px', paddingBottom: this.state.data.length == 0 ? '25px' : '0px' }}>


            {!this.state.isLoading && this.state.data?.length == 0 && (
              <div className="mt-4 text-center">
                <img src="https://image.flaticon.com/icons/svg/3062/3062544.svg" alt="" width="100px" />
                <p className="text-center mb-3">{translate('noOrders')}</p>
              </div>
            )}


            <div >
              {this.state.data.map((prop, index) => {
                return (

                  <div key={index} className="" style={{ padding: 20, borderBottom: '1px solid #f2f6f8', borderRadius: 8 }}>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <div onClick={() => this.setState({ selectedIndex: index })} style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                        {/* <div>
                        <div style={{ backgroundColor: this.state.selectedIndex == index ? '#007aff' : '#cde', border: '3px solid #cde', transition: 'all 0.5s', width: 20, height: 20, borderRadius: 20, }}>
                        </div>
                      </div> */}

                        <div className=" mr-3 ml-3 ">
                          {prop.creationDate && (<p style={{ fontSize: 14, color: '#111', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{translate('orderDate')}: {moment(prop.creationDate).locale('fa').format('jYYYY jMMMM jDD')}</p>)}

                          <p style={{ fontSize: 14, color: '#111', fontWeight: 'bold' }}>{prop.values?.city?.values?.name}</p>
                          <p style={{ fontSize: 14, color: '#111' }}>{prop.address.address}</p>
                          <p style={{ fontSize: 13, color: '#456' }}>{translate("postalCodeInfo", { code: prop.address.postalCode })}</p>
                          {prop.status && (
                            <div style={{ display: 'inline-flex' }}>
                              <p className="mt-1 text-small" style={{ padding: '4px 10px', borderRadius: 4, backgroundColor: '#007aff10', color: '#007aff' }}>{translate(prop.status)}</p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div >
                        <div style={{ display: 'inline-flex' }}>
                          <button onClick={() => this.openModal(index)} className=" font-bold" style={{ fontSize: 14, color: '#007aff', padding: "7px 15px", borderRadius: 4 }}>{translate("details")}</button>
                        </div>
                      </div>
                    </div>
                  </div>

                )
              })}
            </div>


            <Modal ref={el => this.orderInfoModal = el} maxWidth={400} fullHeight={true}>
              <div className="text-left">

                <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
                  <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('orderDetails')}</p>
                </div>

                <div>
                  <p style={{ fontSize: 14, color: '#111', fontWeight: 'bold' }}>{this.state.selectedOrder?.values?.city?.values?.name}</p>
                  <p style={{ fontSize: 14, color: '#111' }}>{this.state.selectedOrder?.address.address}</p>
                  <p style={{ fontSize: 13, color: '#456' }}>{translate("postalCodeInfo", { code: this.state.selectedOrder?.address.postalCode })}</p>
                  {this.state.selectedOrder?.status && (
                    <div style={{ display: 'inline-flex' }}>
                      <p className="mt-1 text-small" style={{ padding: '4px 10px', borderRadius: 4, backgroundColor: '#007aff10', color: '#007aff' }}>{translate(this.state.selectedOrder?.status)}</p>
                    </div>
                  )}
                </div>


                <div className="w-100" style={{ padding: '20px 25px' }}>


                </div>
              </div>
            </Modal>

          </div>
        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(MeOrders);


