import React from 'react'
import { translate, currentLng } from '../utils/useful'
import { siteTheme, siteConfig } from '../variables/config'
import TLink from './TLink'
import stripHtml from "string-strip-html"
import { imageAddress } from '../utils/useful'

class BlogBox extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    dataBuilder(data, schema) {
        let prop = {}
        for (const [key] of Object.entries(this.state.schema)) {
            prop[key] = this.valueBuilder(schema[key], data)
        }
        return prop
    }

    valueBuilder(key, data) {
        if (key != null && data) {
            let keysArray = key.split('.')
            let newData = data
            if (data.lng && keysArray[0] === "{{lng}}") {
                newData = data.lng[currentLng()]
            } else {
                newData = data[keysArray[0]]
            }
            if (keysArray[1] != null) {
                return this.valueBuilder(keysArray[1], newData)
            } else {
                return newData
            }
        } else {
            return null
        }
    }


    render() {
        let prop = this.props.data//this.dataBuilder(this.props.data, this.props.schema)
        console.log(prop.values?.image)
        return (
            <div className="pb-4" style={{ backgroundColor: '#fff', borderRadius: 15 }}>
                <TLink to={'/blog'} search={(prop.values.slug ? ("?slug=" + prop.values.slug) : ("?id=" + prop._id))} ref={el => this.box = el} className=" mt-0" >
                    <img src={imageAddress(prop.values?.image, 'product', 'small')} alt="" style={{ borderRadius: '15px 15px 0px 0px', width: '100%', objectFit: 'contain' }} />
                    <div className="px-4">
                        <p className="font-bold mt-2 text-big">{prop.values.title}</p>
                        <p className="text-small mt-2 mb-2" >{stripHtml(prop.values.description)}</p>
                        {/* <p className="text-semibold mt-2 mb-2 font-bold" >قیمت: {prop.values.price} تومان</p> */}

                        {/* <div className="text-small" style={{ color: '#789' }}>{translate('read-more')}</div> */}
                    </div>
                    {/* <div className="text-small">{translate("price", { price: prop.values.price })}</div> */}
                    {/* <div className="flexcc mt-2">
                    {prop.portals?.map((prop, index) => {
                        return (
                            <TLink pathname={'/'} className="m-1 text-small font-bold" style={{ backgroundColor: siteTheme.backgroundColors[prop], color: siteTheme.colors[prop], borderRadius: 4, padding: '5px 12px' }}>
                                {translate(prop)}
                            </TLink>
                        )
                    })}
                </div> */}
                </TLink>
            </div>
        )
    }
}

export default BlogBox;