import React from 'react';
import { phoneStandardView, translate, pathMaker, checkTextTranslation, converNumberToPersian, imageAddress } from '../../utils/useful'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import { siteConfig } from '../../variables/config';
import moment from 'jalali-moment'

class PanelTabs extends React.Component {

  logout() {
    this.props.actions.logoutUser()
    this.props.history.push(pathMaker('/login'))
  }


  checkPermission(page, reference) {
    if (!page.roles) {
      return true
    }
    if (reference.info) {
      for (let i = 0; i < page.roles.length; i++) {
        const oneRole = page.roles[i];
        if (oneRole.role === reference.info.role) {
          return true
        }
      }
    }
  }

  getLevel() {
    let level = 'normal'
    if (this.props.user?.info?.level && this.props.user?.info?.levelExpiery && (moment(this.props.user?.info?.levelExpiery).valueOf() > moment(new Date().valueOf()))) {
      level = this.props.user?.info?.level
    }

    return level
  }

  render() {

    return (
      <section>

        <div className="mb-5" style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>
          <div className="px-4">
            <div className="py-3" style={{ borderBottom: '1px solid #FF6C00' }}>
              <div className="flexc p-0">
                <div className="profile-image mrd-4" style={{ width: 60, height: 60, borderRadius: 40, backgroundImage: this.props.user?.info?.image ? 'url(' + imageAddress(this.props.user.info.image) + ')' : 'url(/images/male.png)' }}>
                </div>

                <div>
                  <p className="text-semibig ">{this.props.user?.info?.name + ' ' + (this.props.user?.info?.family ? this.props.user?.info?.family : '')}</p>

                  <p className=" mt-1 " style={{ fontSize: 13 }}>{this.props.user?.info?.phone ? converNumberToPersian(phoneStandardView(this.props.user.info.phone)) : ''}</p>
                  {/* <p className=" mt-1 white" style={{ fontSize: 13 }}>Logged in as user</p> */}

                  <p className="text-smaller mt-1 ">تعداد آگهی باقی مانده: {this.props.user?.info?.balanceOptions?.ads ?? 0}</p>
                  {/* <p className="text-smaller mt-1 ">نوع حساب: {translate(this.getLevel())}</p>
                  {this.getLevel() != 'normal' && (
                    <p className="text-smaller mt-1 ">تاریخ انقضا: {moment(this.props.user?.info?.levelExpiery).locale('fa').format("YYYY/MM/DD")}</p>
                  )} */}

                </div>
              </div>
            </div>
          </div>

          <div className="pt-2">
            {siteConfig.wallet && (
              <button onClick={() => this.props.changePage('wallet')} className=" w-100 m-0" style={{ backgroundColor: '#e8eff2', borderRadius: 3, padding: '15px 10px', margin: 8 }}>
                <p style={{ fontSize: 14, color: '#345' }}>{translate('wallet-balance', { balance: this.props.user.info.balance ?? 0 })}</p>
              </button>
            )}
            {this.props.tabs.map((prop, index) => {
              if (prop.check != "isCounterpart" || (prop.check == "isCounterpart" && this.props.user.isCounterpart)) {
                if (prop.check == 'user' && this.props.user.isCounterpart) {
                  return
                }
                if (this.checkPermission(prop, this.props.user)) {
                  return (
                    <button className=" w-100 p-0 m-0" key={index} onClick={() => this.props.changePage(prop.key)} style={{ borderBottom: index != this.props.tabs.length - 1 ? '0px #eee solid' : 'none' }}>
                      <div className="flexc button-light-hover " style={{ padding: '15px 25px' }}>
                        <i className={"fa  mrd-4 fa-" + prop.icon} style={{ color: '#FF6C00', fontSize: 20 }}></i>
                        <div>
                          <p className="" style={{ fontSize: 14, color: '#345' }}>{checkTextTranslation(prop.title)}</p>
                          {/* {prop.key == 'wallet' && (<p className=" mt-1" style={{ fontSize: 11, color: '#789', fontWeight: 'bold' }}>0 USD</p>)} */}
                        </div>
                      </div>
                    </button>
                  )
                }
              }
            })}

            <button onClick={() => this.logout()} className="mt-5 w-100 white flexc" style={{ backgroundColor: '#E53935', borderRadius: '8px 8px 0px 0px', fontSize: 14, padding: '10px 20px' }}>
              <i className={"fa  mrd-4 fa-sign-out"} style={{ fontSize: 20 }}></i>
              {translate('logout').toUpperCase()}
            </button>


          </div>

        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PanelTabs));


