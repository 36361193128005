import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './stores/actionsList';
import Header from './views/Header'
import { Route, Switch } from "react-router-dom";

import i18n from 'i18next';
import { siteConfig } from './variables/config'
import { changeUrlWithLng, getToken, getUserInfo, pathMaker } from './utils/useful'
import Footer from './views/footer'
// import FooterChilik from './views/FooterChilik'

import routes from './variables/routes'
// import OpiaFooter from './views/footer';
// import RaeesFooter from './views/RaeesFooter';
import ResourcePage from './pages/Chilik/Resource'
import Modal from './components/Modal'


// import './App.css';

class App extends React.Component {
    state = {
        lngRoute: '',
        showPage: false
    }

    componentDidMount() {
        // i18n.defaultLng='pt-BR'
        let parent = this
        i18n.on('languageChanged', (lng) => {
            console.log(lng)
            this.setState({ showPage: false })

            if (siteConfig.forceUrl) {
                // console.log(window.location)
                changeUrlWithLng(lng)
            }
            parent.props.actions.changeLanguage('language', lng)
            this.setState({ showPage: true })

        })

        this.checkUser()
    }

    componentDidUpdate(e) {

        if (e.history.action === "PUSH" && this.state.prevLoc != e.history.location) {
            window.scrollTo(0, 0)
            this.setState({ prevLoc: e.history.location })
        }
    }


    checkUser() {
        if (this.props.user.loggedin) {
            if (getToken()) {
                getUserInfo((data, err, status) => {

                    if (status == 401) {
                        this.props.actions.logoutUser()
                    }
                    if (data) {
                        if (data.authoried || data.authorized) {
                            this.props.actions.setUser(data.user, null, true)
                        } else {
                            this.props.actions.logoutUser()
                        }
                    }
                })
            } else {
                this.props.actions.logoutUser()
            }
        }
    }

    hideModal = () => {
        if (this.props.resource._id) {
            // this.modal.hideModal()

            if (this.modal && this.modal.state?.showModal) {
                this.modal.hideModal()
            }
        } else {
            if (this.modal && this.modal.state?.showModal) {
                this.modal.hideModal()
            }
        }
        // console.log(this.state.location)
        if (this.state.location) {
            window.history.pushState(null, null, this.state.location);
        }
        this.setState({ location: null })
        this.props.actions.setResource({})

        // // })
    }



    render() {

        // console.log(this.props.resource?.data?._id)
        // console.log(this.props.user)

        if (this.state.showPage) {
            return (
                <div className={"container-fluid m-0 p-0"} >

                    <Header />


                    <div className="" style={{ minHeight: '100vh' }} >
                        <Switch >
                            {
                                routes.map((prop, index) => {
                                    return (
                                        <Route key={index} exact={prop.exact} path={(siteConfig.dontShowUrlForDefaultLng ? (i18n.language === siteConfig.defaultLng ? '' : '/' + i18n.language) : '/' + i18n.language) + prop.path} component={prop.component} />
                                    )
                                })
                            }
                        </Switch>
                        <Footer />
                    </div>

                </div>



            )
        } else {
            return (<div> </div>)
        }

    }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, resource: state.resource })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);