import React from 'react';
// import { translate } from '../utils/useful'
// import Section from '../components/Section'
// import TLink from '../components/TLink';
import HttpService from '../../utils/Http.services';
// import stripHtml from "string-strip-html";
// import { siteConfig } from '../variables/config';
import DataGridEH from '../../components/DataGridEH'
// import Product from '../components/Product'
import Resources from '../../components/Resource'
import { translate } from '../../utils/useful';
import ChilikSearch from '../../components/ChilikSearch';

class Categories extends React.Component {


  state = {
    data: {},
    resources: [],
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.location != newProps.location) {
      this.setState({ isLoadingPage: true, data: {}, resources: [] }, () => {
        this.fetch()
      })
    }
    
  }



  fetch() {

    let slug = this.props.match.params.slug
    this.setState({ isLoading: true })
    HttpService.request("getOneWithData", { filter: { "applet": 'categories', "values.slug": slug } }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info }, () => {
          this.fetchResources()
        })
      }
    })
  }


  fetchResources() {
    this.setState({ isLoading: true })
    console.log(this.state.data._id)
    HttpService.request("getAllWithData", { filter: { applet: 'resources', 'values.category': this.state.data._id } }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })
      if (fetchError) { return }
      console.log(fetchResult.info)
      this.setState({ resources: fetchResult.info })
    })
  }

  render() {

    return (
      <section style={{ paddingBottom: 80 }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h3 className=" text-ultra-big white mt-0 mb-2">{translate('search').toUpperCase()}</h3>

          <ChilikSearch />
          {/* <div className="flexcc w-100 mb-2">
            <div className="flexcc w-100" style={{ padding: '0px 10px', height: 50, width: '80%', maxWidth: 500, backgroundColor: '#ffffffee', borderRadius: 8, border: '1px solid #fff', backdropFilter: 'blur(10px) saturate(180%)', }}>
              <div>
                <button className="p-2" style={{ borderRight: '1px solid #ddd' }}>Photos</button>
              </div>
              <input className="w-100 h-100" placeholder="Search all recources ..." style={{ padding: '5px 20px', fontSize: 18, backgroundColor: 'transparent', border: 'none' }} />
              <button style={{}}><img src="https://image.flaticon.com/icons/svg/483/483356.svg" height="30px" /></button>
            </div>
          </div> */}
        </div>


        <section className="container">
          <div className="col-12 mb-5 mt-5">
            {/* {this.state.data.values?.name && ( */}
            <div className="row mb-5">
              <div className="col-12 col-md-6">
                <h1 className="mt-0 mb-2 text-mega-big mld-4">{this.state.data.values?.name}</h1>
                <p className="text-semibig mld-4">{this.state.data.values?.description}</p>
              </div>
              {/* <div className="col-12 col-md-6 text-center flexcc flex-column d-none d-md-flex">
                <div className="" style={{ backgroundColor: '#f2f6f8', borderRadius: 4, padding: '15px 60px', width: '80%' }}>
                  <h4 className="m-0 text-mega-big p-0">746</h4>
                  <p className="m-0">RESOURCES</p>

                </div>
              </div> */}
            </div>
            {/* )} */}


            <DataGridEH isLoading={this.state.isLoading} data={this.state.resources} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Resources} col="col-12 col-md-6 col-lg-4" />
          </div>
        </section>



      </section>
    );
  }
}

export default Categories;
