import React from 'react'
import Loader from 'react-loader-spinner'
import { translate } from '../utils/useful';

class DataGridEH extends React.Component {
    state = {
        key: '',
        width: '',
        rows: []
    }

    componentDidMount() {
        window.addEventListener('resize', this.setWidth);
        this.setWidth()
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.rowMaker()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setWidth);
    }

    setWidth = () => {
        let width = this.container.getBoundingClientRect().width
        this.setState({ width, rows: [] }, () => {
            this.rowMaker()
        })
    }

    rowMaker() {
        let width = this.state.width
        let rows = []
        let currentWidth = 0
        let currentHeight = 210
        let currentRow = { height: currentHeight, data: [] }
        // rows.push([])
        this.props.data.forEach((oneData, index) => {
            let newWidth = (currentHeight / oneData.values.file?.height) * oneData.values.file?.width
            oneData.nWidth = newWidth
            if (width >= currentWidth + newWidth) {
                currentWidth += newWidth
                currentRow.data.push(oneData)
                // if (index == this.props.data.length) {
                //     rows.push(currentRow)
                // }
            } else {
                let newHeight = (width / currentWidth) * currentRow.height
                currentRow.height = newHeight
                rows.push(currentRow)
                currentRow = { height: currentHeight, data: [] }
                currentRow.data.push(oneData)
                currentWidth = newWidth
            }
        });
        let newHeight = (width / currentWidth) * currentRow.height
        // let maxHeight = (currentRow.data[0].width / currentRow.data[0].height) * currentHeight
        if (newHeight > 450) {
            newHeight = 450
            currentRow.data.push({ type: 'fill' })
        }

        currentRow.height = newHeight
        rows.push(currentRow)
        this.setState({ rows })
    }


    render() {
        let Component = this.props.component
        return (
            <div className="w-100" ref={el => this.container = el}>

                <div className="flexcc">
                    {this.props.data && this.props.data.length === 0 && !this.props.isLoading && (
                        <p style={{ fontSize: 16, color: '#789', marginTop: 20 }}>{translate('found-nothing')}</p>
                    )}

                    {this.props.isLoading && (
                        <div className="mt-2">
                            <Loader
                                type={this.props.type ?? "Oval"}
                                color={this.props.color ?? "#202020"}
                                height={this.props.width ?? 50}
                                width={this.props.height ?? 50}
                            />
                        </div>
                    )}
                </div>

                {this.props.data && this.props.data.length > 0 && this.state.rows.map((row, index) => {
                    return (
                        <div key={index} className="d-inline-flex" style={{ height: row.height - 4, justifyContent: 'flex-start', flexWrap: index === this.state.rows - 1 ? 'wrap' : 'nowrap' }}>

                            {row.data.map((prop, index) => {
                                if (prop.type !== 'fill') {
                                    return (
                                        <div key={index} className="h-100" style={{ padding: '0px 3px' }} >
                                            <Component data={prop} schema={this.props.schema} options={this.props.options} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index} className='' ></div>
                                    )
                                }
                            })}
                        </div>
                    )
                })}




            </div>
        )
    }
}

export default (DataGridEH);