import React from 'react';
import { translate } from '../utils/useful'

class Section extends React.Component {

  styleKeys = ['background', "backgroundImage", "height", "backgroundAttachment", "minHeight"]

  styleBuilder() {
    let style = {}
    this.styleKeys.forEach(key => {
      if (this.props[key]) {
        style[key] = this.props[key]
        if (key === "backgroundImage") {
          style[key] = 'url(' + this.props[key] + ')'
        }
      }
    });
    return style
  }


  render() {

    let style = this.styleBuilder()

    return (
      <section className={"section-base "} style={style}>

        {this.props.overlay && (
          <div className="position-absolute w-100 h-100" style={{ background: this.props.overlay }} />
        )}
        <div className={"h-100 w-100 position-relative " + this.props.className}>
          {this.props.children}
        </div>

      </section>
    );
  }
}

export default Section;
