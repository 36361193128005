import React from 'react';
import { phoneStandardView, translate, checkTextTranslation, priceStandardView } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';

class CheckoutSide extends React.Component {

  state = {
    data: {}
  }

  calcPrice() {
    let price = 0

    if (Array.isArray(this.props.cart?.items)) {
      this.props.cart.items.forEach(item => {
        price = price + (item.data.values.price * item.count)
      })
    }

    return price
  }

  calcDiscount() {
    let discount = 0
    if (this.props.off?.price) {
      discount = this.props.off.price
    }
    return discount
  }

  calcTotalPrice() {
    return this.calcPrice() - this.calcDiscount()
  }



  render() {

    return (
      <section className="w-100 mb-5">

        <div className={'w-100'} style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>

          <div className="" style={{ backgroundColor: '#e0e6e8', padding: '20px 20px' }}>
            <div className="flexcc flex-column p-0 text-center">
              <img className="invert" src="/images/basket.png" alt="checkout" height="60px" />

              <div>
                <p className="font-bold  mt-2" style={{ fontSize: 16 }}>{translate('checkout-cart')}</p>
                <p className="font-small  mb-1" style={{ fontSize: 12 }}>{translate('continue-item-shopping')}</p>
              </div>
            </div>
          </div>

          {this.props.user.loggedin && (
            <div className="pl-4 pr-4 mt-4" style={{ alignItems: 'center', display: 'flex', }}>
              <img src="/images/male.png" alt="checkout" style={{ width: 54, height: 54, objectFit: 'cover', borderRadius: '50%' }} />

              <div className="mld-4">
                <p className="text-normal font-bold" style={{ color: '#202020' }}>{this.props.user?.info?.name}</p>
                <p className="text-small mt-1" style={{ color: '#789' }}>{this.props.user ? phoneStandardView(this.props.user?.info?.phone) : ''}</p>
              </div>
            </div>
          )}


          <div className=" flexcb ml-3 mr-3 mt-4" style={{ backgroundColor: '#f2f6f8', borderRadius: 8, padding: '10px 15px' }}>
            <div className="mld-4">
              {this.calcDiscount() > 0 && (
                <React.Fragment>
                  <p className="text-small mt-1 font-bold" style={{ color: '#789' }}>{translate('price')}</p>
                  <p className="text-simibig font-bold" style={{ color: '#202020' }}>{translate('currency', { price: this.calcPrice() })}</p>
                  <p className="text-small mt-1 font-bold" style={{ color: '#789' }}>{translate('discount')}</p>
                  <p className="text-simibig font-bold" style={{ color: '#ee5050' }}>{translate('currency', { price: this.calcDiscount() })}</p>
                </React.Fragment>
              )}
              <p className="text-small mt-1 font-bold" style={{ color: '#789' }}>{translate('total-price')}</p>

              <p className="text-big font-bold" style={{ color: '#202020' }}>{translate('currency', { price: priceStandardView(this.calcTotalPrice()) })}</p>


            </div>

            {/* <img className="opacity-2" src="/images/next-2.png" alt="checkout" style={{ width: 25, height: 25, objectFit: 'cover', borderRadius: '50%' }} /> */}
          </div>

          <div className=" flexcb ml-3 mr-3 mt-2" style={{ backgroundColor: '#f2f6f8', borderRadius: 8, padding: '10px 15px' }}>
            <div className="mld-4">
              <p className="text-small mt-1" style={{ color: '#789' }}>{translate('number-of-items')}</p>
              <p className="text-normal font-bold" style={{ color: '#789' }}>{this.props.cart.items.length} {translate('items')}</p>
            </div>
            {/* <img className="opacity-2" src="/images/next-2.png" alt="checkout" style={{ width: 25, height: 25, objectFit: 'cover', borderRadius: '50%' }} /> */}
          </div>

          <div className="w-100 d-flex flex-column mt-1 mb-3">

            {this.props.currentStage < this.props.stages.length - 2 && (
              <button onClick={() => this.props.nextShoppingStage()} className=" flexcc  " style={{ flex: 1, margin: '20px', backgroundColor: '#202020', borderRadius: 8, padding: '15px 15px' }}>
                <div className="mld-4">
                  <p className="text-normal font-bold white" >{translate('continue-shopping')}</p>
                </div>

                {/* <img className="mld-3" src="/images/basket.png" alt="checkout" style={{ width: 25, height: 25, objectFit: 'contain' }} /> */}
              </button>

            )}

            {((this.props.currentStage === this.props.stages.length - 2) || this.props.currentStage === 'PaymentMethod') && (
              <button onClick={() => this.props.nextShoppingStage()} className=" flexcc  " style={{ flex: 1, margin: '20px', backgroundColor: '#007aff', borderRadius: 8, padding: '15px 15px' }}>
                <div className="mld-4">
                  <p className="text-normal font-bold white" >{translate('finalize-shopping')}</p>
                </div>
              </button>

            )}


            {this.props.error && (
              <div className="pl-4 pr-4 mb-3 text-center">
                <small style={{ color: '#ee5050' }}>{checkTextTranslation(String(this.props.error))}</small>
              </div>
            )}

            {this.props.currentStage != 0 && (
              <div className="w-100 text-center mb-4">
                <button onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
              </div>
            )}

          </div>



        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutSide);

