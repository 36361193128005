import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { translate } from '../../utils/useful'
// import HttpService from '../../utils/Http.services';

class LoginPasswordStep extends React.Component {
    state = {
        errors: {},
        formHeaders: [{ key: 'password', type: 'PasswordInput', information: { label: '{{lang}}insertPasword',autoFocus:true, icon: 'https://image.flaticon.com/icons/svg/14/14204.svg', placeholder: '{{lang}}insertPasword', required: true } }]
    }




    componentDidMount() {
        window.addEventListener("keypress", this.keyPress)

    }
    componentWillUnmount() {
        window.removeEventListener("keypress", this.keyPress)
    }

    keyPress = (e) => {
        if (e?.keyCode == 13) {
            this.postPassword()
        }
    }



    postPassword = () => {
        this.setState({ isLoading: true, errors: {} })
        let data = this.form.getForm()
        this.props.setData(data, () => {
            this.props.postPassword((result, err) => {
                if (err) { this.setState({ errors: err }) }
                this.setState({ isLoading: false })
            })
        })
    }

    render() {
        return (

            <div className="flexcc flex-column">
                {this.props.newPassword ? (
                    <>
                        <h2 className="mt-4 mb-0" style={{ fontSize: 27 }}>کلمه عبور جدید</h2>
                        <p className="mb-4 mt-0" style={{ fontSize: 15 }}>کلمه عبور جدید را وارد کنید</p>
                    </>
                ) : (
                        <>
                            <h2 className="mt-4 mb-0" style={{ fontSize: 27 }}>{translate('password')}</h2>
                            <p className="mb-4 mt-0" style={{ fontSize: 15 }}>{translate('insertPasword')}</p>
                        </>
                    )}


                <div className="w-100" style={{ maxWidth: 400 }}>

                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postPassword}
                            isLoading={this.state.isLoading}
                            text={translate('submit')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                    </div>
                    <div className="w-100 text-center">
                        <TLink pathname={'/forgotpassword'} style={{ cursor: 'pointer', fontSize: 15, border: 'none', backgroundColor: 'transparent', outline: 'none', marginBottom: 10 }}>{translate('forgot-password')}</TLink>
                    </div>
                    <div className="w-100 text-center mt-2">
                        <button className="mb-3" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>
                </div>
            </div>

        )
    }
}

export default LoginPasswordStep;