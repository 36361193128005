import React from 'react';
import { translate, phoneStandardView, checkTextTranslation, converNumberToPersian } from '../utils/useful'
import { siteConfig } from '../variables/config';
import TLink from '../components/TLink'
import FormViewer from '../components/FormViewer'
import LoaderButton from '../components/LoaderButton';
import HttpService from '../utils/Http.services';
import FastHelmet from '../components/FastHelmet';

class Contactus extends React.Component {


  state = {
    stage: 0,
    socialNetworks: [
      { name: 'facebook', icon: '' },
      { name: 'linkedin', icon: '' },
      { name: 'instagram', icon: '' },
      { name: 'youtube', icon: '' },
      { name: 'aparat', icon: '' },

    ],



    headers: [

      { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: '', placeholder: '{{lang}}insertName', required: true } },
      { key: 'email', type: 'TextInput', information: { label: '{{lang}}email', icon: '', placeholder: '{{lang}}email', required: true } },
      { key: 'phone', type: 'TextInput', information: { label: '{{lang}}phone', icon: '', placeholder: '{{lang}}insertPhone', required: false } },

      { key: 'message', type: 'TextAreaInput', information: { label: '{{lang}}Message', placeholder: '{{lang}}insertMessage', rows: 4, required: true } },

      // { type: 'TextInput', key: 'name', information: { title: 'Name', required: true } },
      // { title: 'Phone', type: 'TextInput', key: 'phone', },
      // { title: 'Email', type: 'TextInput', key: 'email', },
      // { title: 'Message', type: 'TextAreaInput', key: 'body', settings: { required: true } },
      // { title: 'Section', key: 'section', type: 'select', options: { type: 'local', items: [{ title: "Sale", value: 'sale' }, { title: 'Managment', value: 'managment' }] }, settings: { required: true }, showMain: true },
    ],
    services: [
      { title: 'حذف واسطه‌ها', image: '/images/181036.svg', desc: 'در انبار مازاد، خریدار و فروشنده بدون هیچ واسطه ای می توانند به طور مستقیم با هم ارتباط گرفته و خرید انجام شود. این ارتباط گیری مستقیم سبب می شود علاوه بر کاهش هزینه های واسطه گری؛ بر حسب شرایط خریدار و فروشنده، نحوه ی پرداخت و یا نوع ارسال طبق آنچه طرفین توافق می کنند صورت گیرد و این موارد نیز منحصر به یک شرایط خاص نشوند' },
      { title: 'درج رایگان آگهی', image: '/images/180778.svg', desc: 'درج آگهی توسط کاربران رایگان انجام می شود و کاربران می توانند به راحتی نسبت به درج آگهی های مربوط به اقلام خود اقدام نمایند. با معرفی آنلاین آگهی ها، امکان دیده شدن آگهی ها توسط کاربران زیادی در وب فراهم می شود' },
      { title: 'دسته بندی کاربردی', image: '/images/181022.svg', desc: 'با توجه به این که انبار مازاد به طور تخصصی در زمینه ی رشته های مربوط به برق، الکترونیک و مخابرات فعالیت می کند، دسته بندی اقلام به طور ویژه برای این اقلام انجام شده و کاربران راحت تر می توانند نسبت به خرید و فروش در این حوزه اقدام کنند' },
      { title: 'جست و جوی سریع', image: '/images/2910447.svg', desc: 'براحتی می توانید از طریق بخش جستجو، نسبت به جستجوی اقلام مدنظر خود اقدام نمائید و یا از طریق دسته بندی سایت، کالاهای موجود در دسته ی مدنظر خود را مشاهده کنید' }
    ],

    // services: [
    //   { title: 'قیمت ایده‌آل ', image: '/images/181036.svg', desc: 'ارائه بهترین قیمت آثار به نسبت سایت‌های مشابه خارجی و داخلی' },
    //   { title: 'کاربری آسان', image: 'https://image.flaticon.com/icons/svg/180/180778.svg', desc: 'ایجاد فضایی کاربردی با قابلیت دسترسی راحت به تمامی بخش‌ها' },
    //   { title: 'برترین کیفیت‌ها', image: 'https://image.flaticon.com/icons/svg/181/181022.svg', desc: 'ارائه باکیفیت‌ترین عکس‌ها و وکتورها به کاربران' },
    //   { title: 'معرفی جهانی آثار', image: 'https://image.flaticon.com/icons/svg/181/181035.svg', desc: 'تمام جهان، صحنه‌ی نمایش هنرمندان چیلیک خواهد بود' }
    // ],


    fastAccess: [
      { title: '{{lang}}faq', link: '/pages/faq', image: '/images/faq.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' },
      { title: '{{lang}}terms', link: '/pages/terms', image: '/images/blog.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' },
      { title: 'مقالات', link: '/pages/blogs', image: '/images/policies.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }],

  }

  sendContact = () => {
    let data = {}
    data.values = this.form.getForm()
    if (data.values) {
      this.setState({ isLoading: true, errors: {} })

      data.applet = 'contacts'
      console.log(data)
      HttpService.request('submitForm', data, (fetchResult, fetchError) => {
        console.log(fetchError)
        console.log(fetchResult)
        this.setState({ isLoading: false })
        if (fetchError) { this.setState({ errors: fetchError }); return }
        this.setState({ stage: 1 })
      })
    }

  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8' }}>

        <FastHelmet title={translate('contactus')} description={translate('contactus')} />

        <div className="text-center" style={{ padding: '40px 20px 40px 20px', backgroundColor: '#FF6C00' }}>
          {/* <h1 className=" text-ultra-big white">{siteConfig.siteName}</h1> */}
          <h1 className=" text-mega-big white">{translate('contactus')}</h1>
        </div>






        <div className="container mt-5" ref="contact">
          <div className="row pt-4 d-flex ">
            <div className="col-12 col-lg-6">
              <div className="w-100 h-100" style={{ backgroundImage: 'linear-gradient(#fff,#f2f6f8)', padding: 0, borderRadius: 8, marginBottom: 0 }}>

                {this.state.stage == 0 && (
                  <div>

                    <div className="w-100 p-3" style={{ backgroundColor: '#FF6C00', borderRadius: '8px 8px 0px 0px' }}>
                      <p className="text-center    white " style={{ fontSize: 18, }}>تماس با ما</p>
                      <p className="text-center  mt-1 white  " style={{ fontSize: 14, opacity: 0.4 }}>CONTACT US</p>
                    </div>
                    <div className="p-5">
                      {/* <p className="text-center  mt-1 mb-5" style={{ fontSize: 15 }}>Please let us know your enquiry</p> */}
                      <FormViewer headers={this.state.headers} ref={el => this.form = el} inputClass={'modern-input'} />

                      <div className="text-center">
                        <LoaderButton
                          onClick={this.sendContact}
                          isLoading={this.state.isLoading}
                          text={translate('submit')}
                          type={"Oval"}
                          className="mt-4 mb-4"
                          buttonStyle={{ outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}
                          width={40}
                          height={40}
                          color={'#202020'}
                        />
                      </div>

                    </div>
                  </div>
                )}

                {this.state.stage == 1 && (
                  <div className="text-center mt-2" style={{ padding: 30 }}>
                    <div className="mb-4" style={{ backgroundColor: '#2cb5e820', padding: 20, borderRadius: 8 }}>
                      <p className=" " style={{ fontSize: 14, color: '#789' }}>{translate('contact-sent')}</p>
                      <p onClick={() => this.setState({ stage: 0 })} className=" mt-2" style={{ cursor: 'pointer', fontSize: 14, color: '#007aff' }}>{translate('send-new-contact')}</p>

                    </div>
                  </div>
                )}

              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="w-100 h-100" style={{ backgroundImage: 'linear-gradient(#fff,#f2f6f8)', padding: 0, borderRadius: 8, marginBottom: 0 }}>
                <div className="w-100 p-3" style={{ backgroundColor: '#FF6C00', borderRadius: '8px 8px 0px 0px' }}>
                  <p className="text-center    white " style={{ fontSize: 18, }}>اطلاعات تماس</p>
                  <p className="text-center  mt-1 white  " style={{ fontSize: 14, opacity: 0.4 }}>CONTACT INFORMATION</p>
                </div>
                <div>

                  <div className="p-5">
                    <div className="d-flex align-items-center "><img src="" width="40px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}>شرکت: </span><span>{siteConfig.siteName}</span></p></div>
                    {siteConfig.addressFa && (<div className="d-flex align-items-center mt-4"><img src="" width="40px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}>آدرس: </span><span>{siteConfig.addressFa}</span></p></div>)}
                    <div className="d-flex align-items-center mt-4"><img src="" width="30px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}>شماره تماس: </span><a style={{ color: '#345' }} href={"tel:" + siteConfig.phone}><span>{converNumberToPersian(siteConfig.phone)}</span></a></p></div>
                    <div className="d-flex align-items-center mt-4"><img src="" width="30px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="text-small" style={{ color: '#222' }}>ایمیل: </span><a style={{ color: '#345' }} href={"mailto:" + siteConfig.email}>{siteConfig.email}</a></p></div>

                    {this.state.socialNetworks.map((prop, index) => {
                      if (siteConfig[prop.name] && siteConfig[prop.name] != '')
                        return (
                          <div key={index} className="d-flex align-items-center mt-4">
                            <img src={prop.icon} width="30px" />
                            <p className="mld-4" style={{ lineHeight: 1, color: '#456' }}>
                              <span className="text-small" style={{ color: '#222' }}>{translate(prop.name)}: </span>
                              <a rel="no-follow" className="text-small " href={siteConfig[prop.name]} style={{ color: '#345' }}>{translate("follow-us")}</a>
                            </p>
                          </div>
                        )
                    })}
                  </div>

                  {/* <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/1409/1409945.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">Linkedin: </span><a href={siteConfig.facebook}>Follow us</a></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/1409/1409943.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">Facebook: </span><a href={siteConfig.linkedin}>Follow us</a></p></div> */}

                </div>
              </div>
            </div>

          </div>
        </div>



        <div style={{ marginTop: 0, paddingBottom: 10, }}>
          <div className="container mb-5 mediumiransansfont"  >
            <div className={' pr-0 mb-1  d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <h2 className=' mb-1 ' style={{ fontSize: 26, marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', marginLeft: 20, marginRight: 20, fontWeight: 'bold' }}>اطلاعات بیشتر</h2>
              </div>
            </div>

            <div className={'row text-center mt-3 ml-1 mr-1'}>
              {this.state.fastAccess.map((prop, index) => {
                return (
                  <TLink to={prop.link} className="col-12  col-md-4 col-lg-4 mt-4" key={index}>
                    <div>
                      <img src={prop.image} style={{ boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', width: '100%', marginBottom: 5, borderRadius: 8 }} />
                      <p className={'text-semibig mt-3'}>{checkTextTranslation(prop.title)}</p>
                      {/* <p className={'info-desc pr-1 pl-1 pr-md-0 pl-md-0'} style={{ fontSize: 13 }}>{prop.desc}</p> */}
                    </div>
                  </TLink>
                )
              })}

            </div>

          </div>
        </div>





      </section>
    );
  }
}

export default Contactus;
