import React from 'react'
import Loader from 'react-loader-spinner'
import { translate } from '../utils/useful'
import AdvertisementS from './AdvertisementS'
import TLink from './TLink'

class DataGrid extends React.Component {
    state = {
        key: ''
    }


    render() {
        return (
            <div className="row">
                {this.props.data?.map((prop, index) => {
                    let Component = this.props.component

                    return (
                        <>
                            <div key={index} className={this.props.col + ' mb-5'} >
                                <Component data={prop} mainKey={this.props.mainKey} schema={this.props.schema} dontShowPrice={this.props.dontShowPrice} background={this.props.background} settings={this.props.settings} />
                            </div>

                            {this.props.showAd && ((index + 1) % 4 == 0) && (
                                <div className={this.props.col + ' mb-5'} >
                                    <AdvertisementS />

                                </div>
                            )}
                        </>
                    )
                })}
                <div className="col-12 text-center ">

                    {this.props.data?.length === 0 && !this.props.isLoading && (
                        <p style={{ fontSize: 16, color: '#789', marginTop: 20 }}>{this.props.notFoundText ?? translate('found-nothing')}</p>
                    )}

                    {this.props.isLoading && (
                        <div className="mt-2">
                            <Loader
                                type={this.props.type ?? "Oval"}
                                color={this.props.color ?? "#202020"}
                                height={this.props.width ?? 50}
                                width={this.props.height ?? 50}
                            />
                        </div>
                    )}
                </div>

            </div>
        )
    }
}

export default (DataGrid);