import React from 'react';
import { translate } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
// import FormViewer from '../../components/FormViewer'
// import Loader from 'react-loader-spinner'
import Modal from '../../components/Modal'
// import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';
// import moment from 'moment'
import moment from 'jalali-moment'
import DataGridEH from '../../components/DataGridEH';
import Resources from '../../components/Resource'


class MeResources extends React.Component {


  state = {
    data: [],
    resources:[],

    headers: [
      // { title: 'Description', key: 'description', type: 'text', showMain: false, },
      // { title: 'Country', type: 'select', key: 'country', options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'countries' } }, settings: { required: false }, showMain: true },
      // { title: 'City', type: 'select', key: 'city', dependencies: [{ type: 'filter', action: 'enable', field: 'country', param: 'values.country' }], options: { type: 'api', fields: { value: '_id', title: 'values.name' }, address: domain + '/values/getAll', filter: { page: 'cities' } }, settings: { required: true }, showMain: false },
      { type: 'SelectInput', key: 'values.city', information: { label: 'City', address: 'getAllWithData', filter: { applet: 'cities' }, fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: '{{lang}}insertTags', required: true }, options: { type: 'api', fields: { value: '_id', title: 'values.name', placeholder: '' }, address: '/values/getAll', filter: { page: 'cities' }, placeholder: '' } },
      { type: 'TextInput', key: 'values.address', information: { label: 'Address', placeholder: '{{lang}}insertAddress', required: true } },
      { type: 'TextInput', key: 'values.postalCode', information: { label: 'Postal code', placeholder: '{{lang}}insertAddress', required: true }, },
      // { type: 'TextInput', key: 'project', information: { label: 'Project Name', placeholder: '{{lang}}insertAddress', required: true } },
      // { title: 'Recipient Name', type: 'text', key: 'recipient', showMain: true },

      // { title: 'Submit Address', type: 'button', function: 'submit', options: { color: "#000", fontcolor: '#fff' }, showMain: true },
    ]
  }

  componentDidMount() {
    this.getResources()
  }

  openModal(index) {

    let data = {}
    if (index != -1) {
      data = { ... this.state.data[index] }
    }
    console.log(data)
    this.setState({ initData: data }, () => {

      this.addAddress.showModal()
    })
  }


  // postAddress = () => {
  //   let data = this.form.getForm()
  //   if (data) {
  //     this.setState({ isLoading: true, errors: {} })
  //     data.applet = 'addresses'
  //     HttpService.request('submitForm', data, (fetchResult, fetchError) => {
  //       if (fetchError) { this.setState({ errors: fetchError }); return }
  //       this.setState({ isLoading: false })
  //       this.addAddress.hideModal()
  //       this.getOrders()
  //     })
  //   }
  // }

  getResources = () => {

    this.setState({ isLoadingData: true })
    // console.log(this.props.user.info._id)
    // 5f0ec9026d95031d5cf743b6
    HttpService.request("getAllWithData", { filter: { applet: 'resources', creator: this.props.user.info._id } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingData: false })
      if (fetchError) { return }
      this.setState({ resources: fetchResult.info })
    })



  }


  // initData() {
  //   if (Array.isArray(this.state.data) && this.props.data.address?._id) {
  //     this.state.data.forEach((oneData, index) => {
  //       if (oneData._id === this.props.data.address?._id) {
  //         this.setState({ selectedIndex: index })
  //       }
  //     });
  //   }
  // }


  // getInfo = (cb) => {
  //   if (this.state.selectedIndex >= 0) {
  //     let values = this.state.data[this.state.selectedIndex].values
  //     values.city = values.city._id
  //     values._id = this.state.data[this.state.selectedIndex]._id
  //     let data = { address: values }
  //     cb(data)
  //   } else {
  //     cb(null, "{{lang}}errors.selectAddress")
  //   }
  //   // return data
  // }


  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>
          <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('myResources')}</p>
          </div>



          <div className="row w-100 ml-0 mr-0 p-3 ">
            <div className="col-12 p-0 mb-5">

              <DataGridEH isLoading={this.state.isLoadingData} data={this.state.resources} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Resources} col="col-12 col-md-6 col-lg-4" options={{showEdit:true}} />


            </div>
          </div>


        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(MeResources);


