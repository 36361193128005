import React from "react";
// import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { imageAddress, priceStandardView, converNumberToPersian } from "../../utils/useful";
// import { contentUrl } from '../../Variables'


class Tabbar extends React.Component {
  constructor(props) {
    super(props);
  }


  showPrice() {
    if (this.props.data.values.transaction) {
      if (this.props.data.values.price) {
        return (
          <p className="boldiransansfont" style={{ margin: 0, marginTop: 10, color: '#000', opacity: 0.9, fontSize: 15 }}>قیمت: {converNumberToPersian(priceStandardView(this.props.data.values.price))} تومان</p>
        )
      } else {
        return (
          <p className="boldiransansfont" style={{ margin: 0, marginTop: 10, color: '#D4AF37', opacity: 0.9, fontSize: 15 }}>قیمت توافقی</p>
        )
      }
    } else {
      if (this.props.data.values.price || this.props.data.values.rentprice) {
        return (
          <div>
            <p className="boldiransansfont" style={{ margin: 0, marginTop: 10, color: '#D4AF37', opacity: 0.9, fontSize: 15 }}>ودیعه: {this.props.data.values.price ? converNumberToPersian(priceStandardView(this.props.data.values.price)) : 0} تومان</p>
            <p className="boldiransansfont" style={{ margin: 0, marginTop: 10, color: '#D4AF37', opacity: 0.9, fontSize: 15 }}>اجاره: {this.props.data.values.rentprice ? converNumberToPersian(priceStandardView(this.props.data.values.rentprice)) : 0} تومان</p>
          </div>
        )
      } else {
        return (
          <p className="boldiransansfont" style={{ margin: 0, marginTop: 10, color: '#D4AF37', opacity: 0.9, fontSize: 15 }}>قیمت توافقی</p>
        )
      }
    }

  }


  render() {
    return (
      <div className={' w-100'} style={{ display: 'flex' }} >
        <Link to={{ pathname: "/estate", search: '?id=' + this.props.data._id }} style={{ width: '100%', border: '1px solid #eee', backgroundColor: '#fff', justifyContent: 'start', alignItems: 'flex-start', display: 'flex', flexDirection: 'column', padding: 0, borderRadius: 10 }}>

          <img src={imageAddress(this.props.data.values.images, 'estate', 'small')} style={{ width: '100%', borderRadius: 10, marginTop: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} />
          <div style={{ width: '100%', textAlign: 'right', padding: 5, paddingLeft: 15, paddingRight: 15 }}>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>

              {this.showPrice()}

              <div className="d-flex" style={{ color: '#202020', marginTop: 8, flexWrap: 'wrap' }}>
                <div className="d-flex align-items-center">
                  <p style={{ fontSize: 12, marginLeft: 4, opacity: 0.9 }}>{this.props.data.values ? this.props.data.values.area : ''} متر</p>
                  <img src="/images/icons/bed.svg" width='22px' height='22px' style={{ opacity: 0.5 }} />
                </div>

                <div className="d-flex align-items-center mr-1">
                  <p style={{ fontSize: 12, marginLeft: 4, opacity: 0.9 }}>{this.props.data.values ? this.props.data.values.rooms : ''} خواب</p>
                  <img src="/images/icons/room.svg" width='22px' height='22px' style={{ opacity: 0.5 }} />
                </div>

                <div className="d-flex align-items-center mr-1">
                  <p style={{ fontSize: 12, marginLeft: 4, opacity: 0.9 }}>{this.props.data.values?.type?.values?.name}</p>
                  <img src="/images/icons/type.svg" width='22px' height='22px' style={{ opacity: 0.5 }} />
                </div>
              </div>
              <p style={{ margin: 0, color: '#202020', opacity: 0.9, fontSize: 11, marginTop: 10, marginBottom: 20 }}>واقع در کرج - {this.props.data.values?.region?.values?.name}</p>

            </div>
            {/* <div style={{ backgroundColor: '#f7f7f7', borderRadius: 5, padding: 5, marginTop: 10, width: '100%', marginBottom: 10, textAlign: 'center' }}>
                            <p style={{ color: '#999' }}>اطلاعات بیشتر</p>
                        </div> */}
          </div>

          <div style={{ position: 'absolute', top: 15, left: 30, backgroundColor: 'rgba(80,52,117,0.9)', color: '#fff', borderRadius: 30, fontSize: 14, padding: 2, paddingLeft: 10, paddingRight: 10, opacity: 0.9 }}>
            <p>{this.props.data.values.transaction}</p>
          </div>

        </Link>
      </div>
    );
  }
}



export default Tabbar;
