import React from "react";
import HttpService from '../utils/Http.services';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import TLink from "../components/TLink";
import StagesManager from "../components/StagesManager";
import { chooseWallpaper, checkTextTranslation, translate } from "../utils/useful";

import FastHelmet from "../components/FastHelmet";
import AddAdFirstStep from "../components/AddAd/AddAdFirstStep";
import AddAdInfoStep from "../components/AddAd/AddAdInfoStep";
import AddAdSuccessStep from "../components/AddAd/AddAdSuccessStep";
import Cookies from "universal-cookie";
import Loader from 'react-loader-spinner'


class AddAd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: ['d7e2f7', 'eee1ff', 'fedfe7', 'dad6e4', 'f7dbd7', 'fff2e1'],
      loginInfo: {},
      wallpapers: [],
      stage: 0,
      canResend: true,
      errors: [],
      data: {},

    }
  }


  componentDidMount() {

    this.setState({ data: { 'phone': '0098' } })
    this.setState({ isLoading: true })

    this.fetchWallpapers()

    const cookies = new Cookies();
    let token = cookies.get('token');

    if (token) {
      this.setState({ isLoading: true })
      this.waitForId(0)
    } else {
      this.props.history.push('/login')
      // this.setState({ unautherized: true })
    }
    // this.changeStage('submit')

    // this.changeStage('success')

  }


  componentDidUpdate(prevProps){
    if(this.props.history.location != prevProps.location){
      this.changeStage('init')
    }
  }

  waitForId = (count) => {
    console.log(count)
    if (this.props.user.info._id) {
      this.setState({ isLoading: false }, () => {
        // this.checkDefaultTab()
      })

      // this.setUser()
    } else {
      if (count < 10) {
        setTimeout(() => {
          this.waitForId(count+1)
        }, 500);
      } else {
        this.props.history.push('/login')
        // this.setState({ unautherized: true })
      }

    }
  }





  letsStart = (cb) => {
    this.changeStage('info')
  }

  // goToSubmitPage = (cb) => {
  //   this.changeStage('submit')
  // }

  // postGuaranteeCode = (cb) => {
  //   let data = this.state.data

  //   if (data) {
  //     HttpService.request("postGuaranteeCode", { code: data.code }, (fetchResult, fetchError) => {
  //       if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
  //       // console.log(fetchResult)
  //       if (fetchResult.info) {
  //         this.setState({ fetchedData: fetchResult.info })
  //         this.changeStage('info')
  //       }

  //       if (cb) { cb() }
  //     })
  //   } else {
  //     cb(null, { code: "{{lang}}errors.insertData" })
  //   }
  // }


  postAdInfo = (cb) => {
    let data = this.state.data

    if (data) {
      HttpService.request("postAdInfo", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        // console.log(fetchResult)
        this.changeStage('success')

        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.insertData" })
    }
  }





  setData = (data, cb) => {
    let newData = this.state.data
    for (const [key, value] of Object.entries(data)) {
      newData[key] = value
    }
    this.setState({ data: newData }, () => {
      if (cb) { cb() }
    })
  }


  changeStage = (newStage, cb) => {
    if (this.stageManager) {
      this.stageManager.changeStage(newStage, cb)
    }
  }

  lastStage = () => {
    if (this.stageManager) {
      this.stageManager.lastStage()
    }
  }

  fetchWallpapers() {
    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'ثبت آگهی' } }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      console.log(fetchResult)
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  startOver = () => {
    this.changeStage('init')
    this.setState({ data: {} })
  }


  render() {
    return (
      <section className="d-flex w-100 position-relative" style={{ minHeight: '100vh', padding: '0px', backgroundColor: '#fff' }}>

        <FastHelmet title={'ثبت آگهی'} description={'ثبت آگهی'} />

        {this.state.isLoading && (
          <div style={{ textAlign: 'center', width: '100%', padding: '30px', backgroundColor: '#fff', borderRadius: 8 }}>
            <Loader
              type="Oval"
              color="rgba(0,122,255,1)"
              height="50"
              width="50"
            />
          </div>
        )}

        {!this.state.isLoading && (
          <section className="row p-0 w-100">

            <section className="d-none d-md-block col-12 col-md-5 col-lg-4" style={{ height: '100vh', position: 'sticky', top: 0, padding: '100px 30px 20px 30px', textAlign: 'center', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover' }}>
              <div className="h-100 w-100" style={{ background: '#00000050', position: 'absolute', top: 0, left: 0 }}></div>
              <div style={{ position: 'relative' }}>
                <h1 className="text-mega-big white">ثبت آگهی</h1>
                <h2 className="text-semibig white m-1 opacity-7" style={{ fontWeight: 'normal' }}>anbarmazad.com</h2>
                <p className="white opacity-7" style={{ fontSize: 17, marginTop: 0, }}>در هنگام ثبت آگهی به قوانین و مقررات سایت توجه فرمایید</p>
                {/* <p className="white opacity-7" style={{ fontSize: 17, marginTop: 10, }}>We respects your <TLink pathname="/pages/policy" style={{ textDecoration: 'underline', cursor: 'pointer' }}>privacy</TLink> and we expect you respect the <TLink pathname="/pages/terms" style={{ textDecoration: 'underline', cursor: 'pointer' }}>terms</TLink></p> */}
              </div>
            </section>

            <section className="col-12 col-md-7 col-lg-8" style={{ paddingTop: 60, opacity: this.state.mainOpacity, transition: 'all 0.5s', flex: '1 1 auto' }}>

              <StagesManager ref={el => this.stageManager = el} >
                <AddAdFirstStep initData={this.state.data} setData={this.setData} letsStart={this.letsStart} stage={0} stageName={'init'} />
                <AddAdInfoStep initData={this.state.data} setData={this.setData} postAdInfo={this.postAdInfo} stageName={'info'} />
                <AddAdSuccessStep initData={this.state.data} stageName={'success'} startOver={this.startOver} />
              </StagesManager>

            </section>
          </section>
        )}

      </section>
    )
  }
}


const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAd);
