import React from "react";
import Loader from 'react-loader-spinner'
import HttpService from '../../utils/Http.services';
import { checkTextTranslation } from "../../utils/useful";

class SelectInput extends React.Component {
    state = {
        options: [],
        isLoading: false
    }

    componentDidMount() {
        // console.log("HERE")
        if (this.props.header.information) {
            if (this.props.header.information.type === "local") {
                this.setState({ options: this.props.header.information.items })
            } else if (this.props.header.information.type === "api") {
                this.fetch()
            }
        }
    }



    fetch() {

        // console.log("HERE")
        // console.log(this.props.header.information.filter)
        let filter = this.props.header.information.filter
        if (this.props.header.information.sort) {
            filter.sort = this.props.header.information.sort
        }
        this.setState({ isLoading: true })
        HttpService.request(this.props.header.information.address, filter, (fetchResult, fetchError) => {
            if (fetchError) { return }
            // console.log(fetchError)
            var newData = []
            fetchResult.info.forEach(element => {

                var newTitle = this.spliter(element, this.props.header.information.fields.title)
                var newValue = this.spliter(element, this.props.header.information.fields.value)

                newData.push({ value: newValue, title: newTitle })
            });

            this.setState({
                options: newData,
                isLoading: false
            })

        })

    }


    spliter(source, string) {
        var stringArray = string.split('.')
        var finalString = source
        stringArray.forEach(element => {
            finalString = finalString[element]
        });
        return finalString
    }


    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] === condition) {
                return true
            }
        }
        return false
    }


    render() {
        let dataValue = ''
        if (this.props.data) {
            dataValue = typeof this.props.data == "object" ? this.props.data._id : this.props.data
        }


        return (
            <div className='w-100' style={{ position: 'relative' }}>
                <div style={{ position: 'relative' }}>
                    <select value={dataValue} disabled={this.conditionalSettings('disabled', true)} onChange={e => { this.props.changeValue(this.props.header.key, e.target.value) }} className=' form-control' style={{ WebkitAppearance: 'none', border: 'none', boxShadow: 'none', backgroundColor: 'transparent', color: '#3b3a40', width: '100%', padding: 0 }}>
                        <option disabled hidden value="">{this.props.header.information.placeholder ? checkTextTranslation(this.props.header.information.placeholder) : "Select ..."}</option>
                        {this.state.options?.map((prop, index) => {

                            return (
                                <option key={index} value={prop.value}>{prop.title}</option>
                            )
                        })}
                    </select>

                    {this.conditionalSettings('showInfo', true) && (
                        <p onClick={() => { if (!this.state.isLoading) { this.props.showInfo(this.props.header.information.filter, this.props.data, this.props.settings.url, this.props.settings.page) } }} style={{ fontSize: 13, color: this.state.isLoading ? '#789' : '#007aff', marginTop: 3, cursor: 'pointer', marginLeft: 3 }}>Show information</p>
                    )}

                    {this.state.isLoading && (
                        <div style={{ position: 'absolute', top: 4, right: 25 }}>
                            <Loader
                                type="Oval"
                                color="rgba(0,122,255,1)"
                                height="25"
                                width="25"
                            />
                        </div>
                    )}

                </div>

            </div>
        );
    }
}

export default SelectInput;
