import React from 'react';
import { translate, imageAddress, checkTextTranslation } from '../../utils/useful'
import Section from '../../components/Section'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import TLink from '../../components/TLink';
import DataGridEH from '../../components/DataGridEH'
import Resources from '../../components/Resource'
import Modal from '../../components/Modal'
import HttpService from '../../utils/Http.services';
import { siteConfig } from '../../variables/config'
import Loader from 'react-loader-spinner'
import Collapsible from 'react-collapsible';


class Resource extends React.Component {

  state = {
    data: {},
    resources: [],
    currentImageIndex: 0,
    modalOpacity: 0,
    isLoadingPage: true,
    showInfo: false,
    showShare: false,
    shares: [
      { name: '{{lang}}facebook', url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}&t={{title}}', icon: '/images/facebook.svg' },
      { name: '{{lang}}linkedin', url: 'https://www.linkedin.com/shareArticle?mini=true&url={{url}}', icon: '/images/linkedin.svg' },
      { name: '{{lang}}twitter', url: 'https://twitter.com/intent/tweet?url={{url}}', icon: '/images/twitter.svg' },
      { name: '{{lang}}email', url: 'mailto:?body={{url}}', icon: '/images/email2.svg' },
      { name: '{{lang}}whatsapp', url: 'whatsapp://send?text={{url}}', icon: '/images/whatsapp.svg' }
    ],
    photoInfo: [
      { name: '{{lang}}ISO', key: 'ISO' },
      { name: '{{lang}}aperture', key: 'aperture' },
      { name: '{{lang}}cameraMake', key: 'cameraMake' },
      { name: '{{lang}}cameraModel', key: 'cameraModel' },
      { name: '{{lang}}focalLength', key: 'focalLength' },
      { name: '{{lang}}shutterSpeed', key: 'shutterSpeed' },

    ]
  }



  componentDidMount() {
    // this.paymentModal.showModal()
    this.init()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.location != newProps.location) {
      this.setState({ isLoadingPage: true, data: {}, resources: [] }, () => {
        this.fetch()
      })
    }
    if (this.props.data?._id != newProps.data?._id && this.props.data?._id) {
      this.init()
    }
  }

  init() {
    // console.log(this.props.data?._id)
    if (this.props.data && this.props.data?._id) {
      if (this.containerTop) {
        this.containerTop.scrollIntoView({ behavior: "smooth" });
        // console.log(this.container)
        // this.container.scroll(0,0)//.scrollTo({top:0})//.scrollTop = 0//.scrollTo(0, 0)
      }
      this.setState({ data: null, isLoadingPage: true, resources: [], isLoadingSimilars: true }, () => {
        this.setState({ data: this.props.data, isLoadingPage: false, isModal: true }, () => {
          this.fetchSimilar()
        })
      })
    } else {
      this.fetch()

    }

  }


  fetch() {
    // console.log("FETCH")
    let search = this.props.location.search
    let params = new URLSearchParams(search)
    let id = params.get('id')

    this.setState({ isLoadingPage: true, isLoadingSimilars: true })

    HttpService.request("getOneWithData", { filter: { _id: id } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingPage: false })
      this.setState({ isLoadingSimilars: true })

      if (fetchError) { this.setState({ notFound: true }); return }
      console.log(fetchResult.info)
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info, isModal: false }, () => {
          this.fetchSimilar()
        })
      }
    })
  }


  fetchSimilar() {
    // console.log("FETCH SIMILAR" )
    if (this.state.data._id) {
      HttpService.request("getAllWithData", { limit: 10, filter: { applet: 'resources', _id: { $ne: this.state.data._id }, 'values.status': 'Active' } }, (fetchResult, fetchError) => {
        this.setState({ isLoadingSimilars: false })
        if (fetchError) { return }
        this.setState({ resources: fetchResult.info })
      })
    }
  }


  closeModal() {
    this.setState({ modalOpacity: 0 }, () => {
      setTimeout(() => {
        this.setState({ showModal: false })
      }, 300);
    })
  }


  checkPremium() {

    if (this.state.data.values?.price && this.state.data.values?.price != 0 && this.state.data.values?.price != '') {
      return true
    }
    return false
  }

  showPaymentModal() {
    this.paymentModal.showModal()
  }

  downloadResource() {
    console.log(this.state.data)
    let address = siteConfig.assetsDomain
    if (this.state.data.values.zip) {
      address = address + 'zips/' + this.state.data.values.zip.address
    } else {
      address = address + 'images/' + this.state.data.values.file.address
    }
    window.open(address, "_blank")
  }


  spliter(data) {
    let string = data ? data : ''
    string = string.replace(/,/g, '-')
    var stringArray = string.split('-')

    return Array.isArray(stringArray) ? stringArray : []
  }


  shareLinkBuilder(link) {
    let newLink = link
    newLink = newLink.replace('{{url}}', window.location.href)
    newLink = newLink.replace('{{title}}', this.state.data.values?.title)

    return newLink
  }

  render() {
    // console.log(this.state.data?.creator?.image)
    if (!this.state.isLoadingPage) {
      if (!this.state.notFound) {
        return (
          <Section>
            <div className="container mb-5" ref={el => this.container = el}>
              <div style={{}}
                ref={(el) => { this.containerTop = el; }}>
              </div>
              <div style={{ padding: this.state.isModal ? '40px 0px 30px 0px' : '90px 0px 30px 0px' }}>

                <div className="row w-100 m-0 p-0">
                  <div className="col-12 col-lg-7 ">

                    <div className="row d-flex">
                      <div className="p-0 d-flex flex-column">
                        {this.state.data.values?.file && (
                          <img src={imageAddress(this.state.data.values?.file, 'resource', 'small')} width="100%" height="auto" style={{ maxHeight: '120vh', borderRadius: 4, objectFit: 'contain' }} alt={this.state.data.values?.title} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-5 pl-4 pr-4 pl-lg-5 pr-lg-5 mt-4 mt-lg-0" style={{ position: 'relative', flex: 1 }} >
                    <div className="" style={{ position: 'sticky', top: this.state.isModal ? 40 : 90 }}>

                      <div className="d-flex mb-3" style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
                        <h1 className="bm-0 mt-0">{this.state.data.values?.title}</h1>
                        <div>
                          <span style={{ fontSize: 12, color: '#789' }} >{translate('resource-price')}</span>
                          <h3 className="mb-0 mt-0" style={{ fontSize: 25 }}>{this.checkPremium() ? (this.state.data.values?.price + " " + translate('Chilik')) : translate('free')}</h3>
                        </div>
                      </div>


                      {this.checkPremium() ? (
                        <button onClick={() => this.showPaymentModal()} className="mb-2" style={{ backgroundColor: '#202020', padding: '10px 20px', color: '#fff', fontWeight: 'bold', textAlign: 'center', width: '100%', borderRadius: 6, fontSize: 20 }}>Download <span style={{ fontWeight: 'normal' }}>Premium</span></button>
                      ) : (
                          // <a href={siteConfig.assetsDomain + 'images/'+this.state.data?.values?.file?.address} download>
                          <button onClick={() => this.downloadResource()} className="mb-2" style={{ backgroundColor: '#007aff', padding: '10px 20px', color: '#fff', fontWeight: 'normal', textAlign: 'center', width: '100%', borderRadius: 6, fontSize: 20 }}><span style={{ fontWeight: 'bold' }}>{translate('free-download')}</span></button>
                          // </a>
                        )}

                      <button className="p-0 m-0 w-100 text-start" onClick={() => { if (this.state.isModal) { this.props.actions.setResource({}) } }}>
                        <TLink pathname={'/profile'} search={'?id=' + this.state.data?.creator?._id} className="mt-0 flexc p-2" style={{ backgroundColor: '#f2f6f8', borderRadius: 6 }}>
                          <img src={imageAddress(this.state.data?.creator?.image, "profile")} width="50px" height="50px" style={{ borderRadius: '10%', objectFit: 'cover' }} />
                          <div className="mld-4">
                            <p className="text-normal font-bold" style={{ color: '#789' }}>{translate('resource-owner')}</p>
                            <p className="text-semibig font-bold">{this.state.data?.creator?.name}</p>
                          </div>
                        </TLink>
                      </button>

                      <div className="flexcc mt-2">
                        {this.state.data?.values?.resourceType == 'photo' && this.state.data?.values?.photoInfo  && (
                          <button onClick={() => this.setState({ showInfo: !this.state.showInfo })} className="w-100 mrd-2 p-2" style={{ fontSize: 13, backgroundColor: '#f2f6f8', borderRadius: 4 }}>
                            <span>{translate('photo-info')}</span>
                          </button>
                        )}
                        <button onClick={() => this.setState({ showShare: !this.state.showShare })} className="w-100 mld-2 p-2" style={{ fontSize: 13, backgroundColor: '#f2f6f8', borderRadius: 4 }}>
                          <span>{translate('share')}</span>
                        </button>

                      </div>

                      <Collapsible open={this.state.showShare}>
                        <div className="flexcc mt-3 ">
                          {this.state.shares.map((prop, index) => {
                            return (
                              <a href={this.shareLinkBuilder(prop.url)} key={index} className="flexcc flex-column mx-2">
                                <img src={prop.icon} width="30px" />
                                <span className="mt-1" style={{ fontSize: 12, color: '#789' }}>{checkTextTranslation(prop.name)}</span>
                              </a>
                            )
                          })}
                        </div>
                      </Collapsible>

                      <Collapsible open={this.state.showInfo}>
                        <div className="flexcc mt-3" style={{ flexWrap: 'wrap' }}>
                          {this.state.data?.values?.photoInfo && this.state.photoInfo.map((prop, index) => {
                            let hasInfo = false
                            if (this.state.data.values.photoInfo[prop.key] && this.state.data.values.photoInfo[prop.key] != '') {
                              hasInfo = true
                              return (
                                <div className="text-center mb-1" key={index} style={{ width: '33%' }}>
                                  <p className="text-small" style={{ color: '#789' }}>{checkTextTranslation(prop.name)}</p>
                                  <p>{this.state.data.values.photoInfo[prop.key]}</p>
                                </div>
                              )
                            }

                            if (index === this.state.data?.values?.photoInfo[prop.key].length - 1 && !hasInfo) {
                              return (
                                <div>
                                  <p>اطلاعاتی برای تصویر ثبت نشده است</p>
                                </div>
                              )
                            }
                          })}

                        </div>
                      </Collapsible>


                      <p className="text-small mt-3" style={{ color: '#789' }}>{translate('resource-copyright')}</p>



                      <div className="mt-3 pl-1 pr-1">
                        {/* <p>{this.state.data.values?.description}</p> */}
                        {Array.isArray(this.state.data.values?.category) ? (
                          <div className="flexc" style={{ flexWrap: 'wrap' }} onClick={() => { if (this.state.isModal) { this.props.actions.setResource({}) } }}>
                            <p>{translate('category')} </p>
                            {this.state.data.values?.category.map((prop, index) => {
                              return (
                                <p key={index} className="mt-1 mb-1 mld-2" style={{ fontSize: 14 }}><TLink pathname={'/category/' + prop?.values?.slug} style={{ backgroundColor: '#f2f6f8', padding: '3px 5px', borderRadius: 4 }}>{prop?.values?.name}</TLink></p>
                              )
                            })}
                          </div>

                        ) : (
                            <p className="mt-2" style={{ fontSize: 14 }} onClick={() => { if (this.state.isModal) { this.props.actions.setResource({}) } }}>{translate('category')}  <TLink pathname={'/category/' + this.state.data.values?.category?.values?.slug} style={{ backgroundColor: '#f2f6f8', padding: '3px 8px', borderRadius: 4 }}>{this.state.data.values?.category?.values?.name}</TLink></p>
                          )}

                        <div className="flexc mt-2" style={{ flexWrap: 'wrap' }}>
                          {translate('tags')}
                          {this.spliter(this.state.data.values?.tags).map((prop, index) => {
                            if (prop != '')
                              return (
                                <p key={index} className="mt-1 mb-1 mld-2" style={{ backgroundColor: '#f2f6f8', padding: '3px 8px', borderRadius: 4, fontSize: 14 }} >{prop}</p>
                              )
                          })}
                        </div>



                      </div>
                      {/* <p>This is the attributes section which can include more info</p> */}

                    </div>
                  </div>

                </div>

              </div>

              <div className="row w-100 ml-0 mr-0 ">
                <div className="col-12 p-0 mb-5">
                  <h1 className="mb-4 mt-0">{translate("similar-resources")}</h1>

                  <DataGridEH isLoading={this.state.isLoadingSimilars} data={this.state.resources} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Resources} col="col-12 col-md-6 col-lg-4" />


                </div>
              </div>

            </div>


            {/* {this.state.showModal && (
              <div className='flexcc w-100 gallary' ref={el => this.modal = el} style={{ opacity: this.state.modalOpacity, transition: 'all 0.3s', position: 'fixed', top: 0, left: 0, zIndex: 10, backgroundColor: '#778899dd', height: '100vh', width: '100%', backdropFilter: 'blur(30px)', WebkitBackdropFilter: 'blur(30px)' }}>
                <button onClick={() => this.closeModal()} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}></button>
                <div className="w-100 text-center ">
                  <div className="flexcc w-100">
                    <button className="invert gallary-arrow" onClick={() => this.openGallary(this.state.currentImageIndex - 1)}><img src="https://image.flaticon.com/icons/svg/271/271228.svg" style={{ width: '30px', transform: "rotate(180deg)" }} /></button>
                    <div className="flexcc" style={{ height: '70vh', maxWidth: 'calc(100% - 70px)' }}>
                      <img src={this.state.images[this.state.currentImageIndex]} style={{ opacity: this.state.dontShowImage ? 0 : 1, transition: 'all 0.2s', maxHeight: '100%', borderRadius: 15, maxWidth: 'calc(100%)', objectFit: 'contain' }} />
                    </div>
                    <button className="invert gallary-arrow" onClick={() => this.openGallary(this.state.currentImageIndex + 1)}><img src="https://image.flaticon.com/icons/svg/271/271228.svg" style={{ width: '30px' }} /></button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div ref={el => this.gallary = el} className="d-flex mt-1 pl-3 pr-3 smooth-scroll no-scrollbar" style={{ overflow: 'auto' }}>
                      {this.state.images.map((prop, index) => {
                        return (
                          <button className="m-0 p-0" key={index} ref={el => this["gallary" + index] = el} onClick={() => this.openGallary(index)}>
                            <img className={"m-1 cursor-pointer gallary-image " + (index === this.state.currentImageIndex ? 'active' : '')} key={index} src={prop} style={{ maxHeight: '20vh', borderRadius: 8 }} />
                          </button>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>
            )} */}


            <Modal ref={el => this.paymentModal = el} maxWidth={400}>
              <div className="w-100 h-100 p-4 text-center">
                <h1 className="bm-0 mt-0">Premium Resource</h1>
                <p>This resource is preminum</p>

                <div className="flexcc mb-3 p-2 mt-3" style={{ backgroundColor: '#f2f6f8', borderRadius: 8 }}>
                  <h3 className="mb-0 mt-0" style={{ fontSize: 25 }}>{this.state.data?.values?.price} Credits</h3>
                </div>

                <button className="mb-3" style={{ backgroundColor: '#202020', padding: '10px 20px', color: '#fff', fontWeight: 'normal', textAlign: 'center', width: '100%', borderRadius: 6, fontSize: 20 }}>
                  Pay <span style={{ fontWeight: 'normal' }}>by credit</span>
                </button>
                <p className="text-small" style={{ color: '#789' }}>Consider website terms and policies while downloading resources</p>



              </div>
            </Modal>

          </Section>
        )
      } else {
        return (
          <div className="flexcc flex-column w-100 text-center" style={{ paddingTop: 90 }}>
            <h1 className=" text-mega-big ">404</h1>
            <h1 className=" text-ultra-big mb-2">Resource Not Found</h1>
            <TLink to={'/'}>Go to Home</TLink>
          </div>
        )
      }
    } else {
      return (
        <div className="flexcc w-100" style={{ paddingTop: 90 }}>
          <Loader
            type={this.props.type ?? "Oval"}
            color={this.props.color ?? "#202020"}
            height={this.props.width ?? 50}
            width={this.props.height ?? 50}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ card: state.card, user: state.user, resource: state.resource })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resource);
