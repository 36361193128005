import React from 'react';
import { translate, phoneStandardView, checkTextTranslation } from '../../utils/useful'
import { siteConfig } from '../../variables/config';
import TLink from '../../components/TLink'
import FormViewer from '../../components/FormViewer'
import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';

class AboutusLomanab extends React.Component {


  state = {
    stage: 0,
    headers: [

      { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
      { key: 'phone', type: 'TextInput', information: { label: '{{lang}}phone', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertPhone', required: true } },
      { key: 'message', type: 'TextAreaInput', information: { label: '{{lang}}message', placeholder: '{{lang}}insertMessage', rows: 4, required: true } },

      // { type: 'TextInput', key: 'name', information: { title: 'Name', required: true } },
      // { title: 'Phone', type: 'TextInput', key: 'phone', },
      // { title: 'Email', type: 'TextInput', key: 'email', },
      // { title: 'Message', type: 'TextAreaInput', key: 'body', settings: { required: true } },
      // { title: 'Section', key: 'section', type: 'select', options: { type: 'local', items: [{ title: "Sale", value: 'sale' }, { title: 'Managment', value: 'managment' }] }, settings: { required: true }, showMain: true },
    ],

    services: [
      { title: 'کیفیت بالا', image: 'https://image.flaticon.com/icons/svg/2166/2166853.svg', desc: 'محصولات تازه و با کیفیت را از ما بخواهید. تهیه کالا از با کیفیت ترین تامین کنندگان' },
      { title: 'تحویل فوری', image: 'https://image.flaticon.com/icons/svg/2166/2166885.svg', desc: 'لوماناب با همکاری با شرکت های پستی امکان ارسال کالا به سر تا سر کشور را برای مشتریان فراهم نموده' },
      { title: 'تنوع محصول', image: 'https://image.flaticon.com/icons/svg/2166/2166895.svg', desc: 'تنوع بالای محصولات از مهمترین نقاط قوت لوماناب می باشد که نتیجه آن رضایت مشتریان است' },
      { title: 'قیمت مناسب', image: 'https://image.flaticon.com/icons/svg/2166/2166955.svg', desc: 'قیمت مناسب محصولات لوماناب در کنار کیفیت بالای آن یک از افتخارات ما در ارائه خدمات است' }
    ],

    fastAccess: [{ title: '{{lang}}faq', link: '/faq', image: '/images/faq.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }, { title: '{{lang}}terms', link: '/pages/terms', image: '/images/blog.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }, { title: '{{lang}}products', link: '/categories', image: '/images/policies.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }],

  }

  sendContact = () => {
    let data = {}
    data.values = this.form.getForm()
    if (data.values) {
      this.setState({ isLoading: true, errors: {} })

      data.applet = 'contacts'
      console.log(data)
      HttpService.request('submitForm', data, (fetchResult, fetchError) => {
        console.log(fetchError)
        console.log(fetchResult)
        this.setState({ isLoading: false })
        if (fetchError) { this.setState({ errors: fetchError }); return }
        this.setState({ stage: 1 })
      })
    }

  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8' }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#f2f6f8' }}>
          {/* <h1 className=" text-ultra-big">{siteConfig.siteName}</h1> */}
          {/* <h1 className=" text-mega-big">{translate('aboutus')}</h1> */}

          <h1 className=" text-mega-big">درباره لوماناب</h1>
          <h1 className=" text-big" style={{ color: '#9ab', letterSpacing: 5 }}>Lomanab.ir</h1>

        </div>




        <div style={{ paddingTop: 10, marginTop: 0, paddingBottom: 30, backgroundColor: '#fff' }}>
          <div className="container mb-5 mediumiransansfont"  >


            <div className={' pr-0 mb-1 mt-5 d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <span className="font-bold text-center text-mega-big ">چرا لوماناب؟</span>
              </div>
            </div>

            <div className={'row text-center mt-5'}>
              {this.state.services.map((prop, index) => {
                return (
                  <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-3 mt-4">
                    <img src={prop.image} style={{ height: 100, marginBottom: 5 }} />
                    <p className={'text-big font-bold mt-2'}>{prop.title}</p>
                    <p className={'text-small mt-2'}>{prop.desc}</p>
                  </div>
                )
              })}

            </div>

          </div>
        </div>


        <section>
          <div className="container pt-5 px-5">
            <div className="row ">
              <div className="col-12 col-md-6 flexcc flex-column">
                <div className="">
                  <span className="text-ultra-big font-bold mb-3">درباره ما</span>

                  <div className="">
                    <p className="text-small">مجموعه لوماناب، با هدف افزایش کمیت و کیفیت محصولات داخلی، فعالیت های خود را در زمینه فروش و صادرات انواع خشکبار آجیل آغاز کرد. خدمات مشتری یکی از برنامه های اصلی کسب و کار ما است و بر این اساس تمام تلاش های ما برای ارائه رضایت مشتری و تجربه خرید خوب در ذهن مشتریان عزیز است.</p>
                    <p className="text-ultra-big font-bold mb-2 mt-3">مدیر مجموعه لوماناب</p>

                    <p className="text-small">سید مرتضی قاسمیان</p>
                    <p className="text-small mt-1">مدیر فروش: سرکار خانم کاظمی</p>
                    <p className="text-small mt-1">شماره تماس: ۰۹۱۲۹۳۶۸۰۳۵</p>
                  </div>

                </div>
              </div>

              <div className="col-12 col-md-6 position-relative mt-md-3 flexcc">

                <figure style={{ width: '100%', maxWidth: 400 }}>
                  <img src="/images/lomanab/profile.jpg" width="100%" alt="" style={{ borderRadius: 8 }} />
                </figure>

              </div>
            </div>
          </div>
        </section>


        <div className="container mt-5" >
          <div className="row pt-4 d-flex pb-4 mb-4">
            <div className="col-12 col-lg-6">
              <div className="w-100 h-100" style={{ backgroundColor: '#fff', padding: "30px 30px 10px 30px", borderRadius: 8, marginBottom: 30 }}>

                {this.state.stage == 0 && (
                  <div className="px-3">
                    <p className="text-center font-weight-bold mt-4 mb-5 text-big" >ارتباط با ما</p>
                    {/* <p className="text-center  mt-1 mb-5" style={{ fontSize: 15 }}>Please let us know your enquiry</p> */}
                    <FormViewer errors={this.state.errors} headers={this.state.headers} ref={el => this.form = el} inputClass={'modern-input'} />

                    <div className="text-center">
                      <LoaderButton
                        onClick={this.sendContact}
                        isLoading={this.state.isLoading}
                        text={translate('submit')}
                        type={"Oval"}
                        className="mt-4 mb-4"
                        buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                        width={40}
                        height={40}
                        color={'#202020'}
                      />
                    </div>

                  </div>
                )}

                {this.state.stage == 1 && (
                  <div className="text-center mt-2" style={{ padding: 30 }}>
                    <div className="mb-4" style={{ backgroundColor: '#2cb5e820', padding: 20, borderRadius: 8 }}>
                <p className=" " style={{ fontSize: 12, color: '#789' }}>{translate('contact-sent')}</p>
                <p onClick={() => this.setState({ stage: 0 })} className=" mt-2" style={{ cursor: 'pointer', fontSize: 12, color: '#007aff' }}>{translate('send-new-contact')}</p>

                    </div>
                  </div>
                )}

              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="w-100 h-100" style={{ backgroundColor: '#fff', padding: 30, borderRadius: 8, marginBottom: 30 }}>
                <p className="text-center font-weight-bold mt-4 mb-4 text-big" >تماس با ما</p>

                <div>
                  <div className="d-flex align-items-center "><img src="https://image.flaticon.com/icons/svg/2399/2399925.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">برند: </span><span>{siteConfig.name}</span></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/636/636199.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">آدرس: </span><span>{siteConfig.addressFa}</span></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/684/684912.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">شماره مدیریت: </span><span>{'09122241806'}</span></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/684/684912.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">شماره مغازه: </span><span>{'02144620523'}</span></p></div>

                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/726/726623.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">ایمیل: </span><a href="mailto://info@antbuildz.com">{siteConfig.email}</a></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/2111/2111463.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">اینستاگرام: </span><a href={siteConfig.facebook}>ما را دنبال کنید</a></p></div>
                  {/* <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/1409/1409943.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">فیسبوک: </span><a href={siteConfig.linkedin}>ما را دنبال کنید</a></p></div> */}

                </div>
              </div>
            </div>


          </div>
        </div>



        {/* <div style={{ marginTop: 0, paddingBottom: 10, }}>
          <div className="container mb-5 mediumiransansfont"  >
            <div className={' pr-0 mb-1 mt-5 d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <h2 className=' mb-1 ' style={{ fontSize: 26, marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', marginLeft: 20, marginRight: 20, fontWeight: 'bold' }}>{translate("learn-more-about-us")}</h2>
              </div>
            </div>

            <div className={'row text-center mt-3 ml-1 mr-1'}>
              {this.state.fastAccess.map((prop, index) => {
                return (
                  <TLink to={prop.link} className="col-12  col-md-4 col-lg-4 mt-4">
                    <div>
                      <img src={prop.image} style={{ boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', width: '100%', marginBottom: 5, borderRadius: 8 }} />
                      <p className={'text-big font-bold'}>{checkTextTranslation(prop.title)}</p>
                      <p className={'info-desc pr-1 pl-1 pr-md-0 pl-md-0'} style={{ fontSize: 13 }}>{prop.desc}</p>
                    </div>
                  </TLink>
                )
              })}

            </div>

          </div>
        </div> */}





      </section>
    );
  }
}

export default AboutusLomanab;
