import React from 'react';
import { phoneStandardView, translate, imageAddress, getToken } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import FormViewer from '../../components/FormViewer'
import Loader from 'react-loader-spinner'
import Modal from '../../components/Modal'
import LoaderButton from '../../components/LoaderButton';
import HttpService from '../../utils/Http.services';

class CheckoutInfo extends React.Component {


  state = {
    data: [],

    headers: [
      { type: 'TextInput', key: 'recipient.name', information: { label: '{{lang}}recipientName', hint: '{{lang}}recipientNameHint', placeholder: '{{lang}}insertName', required: true }, },
      { type: 'TextInput', key: 'recipient.phone', information: { label: '{{lang}}recipientPhone', hint: '{{lang}}recipientPhoneHint', placeholder: '{{lang}}insertPhone', required: true } },
      { type: 'SelectInput', key: 'recipient.time', information: { label: '{{lang}}preferredTime', hint: '{{lang}}preferredTimeHint', items: [{ value: '8am - 9am', title: '8am - 9am' }, { value: '9am - 12am', title: '9am - 12am' }, { value: '12pm - 2pm', title: '12pm - 2pm' }, { value: '2pm - 4pm', title: '2pm - 4pm' }, { value: '4pm - 6pm', title: '4pm - 6pm' }], type: 'local', placeholder: '', required: true } },
      { type: 'TextAreaInput', key: 'description', information: { label: '{{lang}}description', hint: '{{lang}}checkoutDescriptionHint', rows: 5, required: false }, },

    ]
  }

  componentDidMount() {
    this.getAddresses()
  }




  openModal(index) {

    let data = {}
    if (index != -1) {
      data = { ... this.state.data[index] }
    }
    console.log(data)
    this.setState({ initData: data }, () => {

      this.addAddress.showModal()
    })
  }


  postAddress = () => {
    let data = this.form.getForm()
    if (data) {
      this.setState({ isLoading: true, errors: {} })
      data.applet = 'addresses'
      HttpService.request('submitForm', data, (fetchResult, fetchError) => {
        if (fetchError) { this.setState({ errors: fetchError }); return }
        this.setState({ isLoading: false })
        this.addAddress.hideModal()
        this.getAddresses()
      })
    }
  }

  getAddresses = () => {
    this.setState({ isLoading: true, errors: {} })
    HttpService.request('getAllWithData', { applet: 'addresses', filter: { '**creator': true } }, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })

      if (fetchError) { this.setState({ errors: fetchError.message }); return }

      this.setState({ data: fetchResult.info })
      // this.getAddresses()
    })

  }

  getInfo = (cb) => {
    let data = this.form.getForm()
    if (data) {
      cb(data)
    } else {
      cb(null, '{{lang}}errors.insertRequiredData')
    }

  }

  render() {

    return (
      <section className="w-100">

        <div className="w-100" style={{ backgroundColor: '#fff', borderRadius: 8, }}>
          <div className="p-3" style={{ backgroundColor: '#e0e6e8', borderRadius: '8px 8px 0px 0px' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('CheckoutInfo')}</p>
          </div>


          <div className="flexcc flex-column">

            <div className="w-100" style={{ padding: '20px 35px' }}>

              <FormViewer ref={el => this.form = el} headers={this.state.headers} initData={this.props.data} errors={this.state.errors} inputClass={'modern-input'} />

              {/* <div className="text-center">
                <LoaderButton
                  onClick={this.postAddress}
                  isLoading={this.state.isLoading}
                  text={"SUBMIT"}
                  type={"Oval"}
                  className="mt-4 mb-4"
                  buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                  width={40}
                  height={40}
                  color={'#202020'}
                />
              </div> */}

            </div>
          </div>
        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(CheckoutInfo);


