import React from "react";
import Loader from 'react-loader-spinner'
import HttpService from '../../utils/Http.services';
import { checkTextTranslation } from "../../utils/useful";


class SelectWithParentInput extends React.Component {

    state = {
        isOpen: false,
        data: [],
        title: '',
        tempData: [],
        options: [],
        isLoading: false,
        choosen: [],
        lastNewId: 0

    }

    toggleOpen = (open) => {
        // console.log("HERE")
        let openState = open != null ? open : !this.state.isOpen

        if (openState) {
            document.addEventListener('mousedown', this.closeBox);
        } else {
            document.removeEventListener('mousedown', this.closeBox);
        }
        // this.refs.icon.classList.toggle("rotate-180")

        this.setState({ isOpen: openState, data: this.props.data })

    }

    // openBox = () => {

    //     if (!this.state.isOpen) {
    //         document.addEventListener('mousedown', this.closeBox);
    //     }
    //     // this.refs.icon.classList.toggle("rotate-180")

    //     this.setState({ isOpen: true, data: this.props.data })

    // }

    closeBox = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpen) {
            this.setState({ isOpen: false, data: this.props.data })
            document.removeEventListener('mousedown', this.closeBox);
            // this.refs.icon.classList.toggle("rotate-180")

        }
    }


    changeItem = (prop, selected, dontChangeValue) => {

        // console.log(prop)
        // var choosen = this.state.choosen
        this.setState({ title: '', search: '', choosen: prop }, () => {
            this.changeValue()
            if (this.state.tempData.length > 0)
                this.setState({ options: this.state.tempData })

            // if (this.refs.input)
            //     this.refs.input.focus()
        })


        // if (selected) {
        //     for (let i = 0; i < choosen.length; i++) {

        //         if (choosen[i].value == prop.value) {
        //             choosen.splice(i, 1)
        //         }
        //     }
        // } else {
        //     choosen.push(prop)
        // }

        // this.setState({ choosen }, () => {
        //     if (!dontChangeValue) 
        // })

        setTimeout(() => {
            this.toggleOpen(false)
        }, 1);

        // setTimeout(
        //     function () {
        //         this.toggleOpen()
        //     }
        //         .bind(this),
        //     1
        // );




        // this.changeFilter(prop.value)

    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }


    componentDidMount() {
        this.mounted = true
        // console.log(this.props.data)
        this.init()

    }

    componentDidUpdate(prevProps) {
        // console.log(this.props.data)

        if (prevProps.data != this.props.data && this.mounted) {
            // this.init()
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }


    init() {
        if (this.props.header.information) {
            if (this.props.header.information.type == "local") {

                let options = []
                let choosen = []

                if (this.props.header.information.items && this.props.header.information.items.length > 0) {
                    options = this.props.header.information.items
                }

                if (this.props.title && Array.isArray(this.props.title)) {
                    this.props.title.forEach(element => {
                        choosen.push({ title: element, value: element })
                    });
                }
                // console.log(this.props.title)
                this.setState({ options, choosen })
            } else if (this.props.header.information.type == "api") {
                // console.log(this.props.title)
                this.fetch()
            }
        }
    }


    fetch() {
        this.setState({ isLoading: true })
        let address = this.props.header.information.address
        let filter = typeof this.props.header.information.filter == "string" ? JSON.parse(this.props.header.information.filter) : this.props.header.information.filter
        // console.log(filter)


        HttpService.request(address, filter, (fetchResult, fetchError) => {
            if (fetchError) { return }

            // fetch(address, {
            //     method: 'POST',
            //     body: JSON.stringify(filter),
            //     headers: headers
            // }).then(response => response.json()).then(data => {
            // console.log(data)
            // if (data.status == 200 && this.mounted) {
            var newData = []
            var newParents = []

            fetchResult.info.sort((a, b) => { return a.values?.parent - b.values?.parent })

            // console.log(fetchResult)
            fetchResult.info.forEach(element => {

                var newTitle = this.spliter(element, this.props.header.information.fields.title)
                var newValue = this.spliter(element, this.props.header.information.fields.value)
                var parent = this.spliter(element, this.props.header.information.fields.parent)

                newData.push({ value: newValue, title: newTitle, parent: (typeof parent == 'object' ? parent._id : parent) })

                if (parent && parent != '') {
                } else {
                    newParents.push({ value: newValue, title: newTitle, parent: (typeof parent == 'object' ? parent._id : parent) })
                }
                // newData.push({ value: newValue, title: newTitle,parent:parent })
                // var newTitle = this.spliter(element, this.props.header.information.fields.title)
                // var newValue = this.spliter(element, this.props.header.information.fields.value)

                // newData.push({ value: newValue, title: newTitle })
            });


            // console.log(newParents)

            newParents.sort((a, b) => { return a.values?.name - b.values?.name })


            // console.log(this.props.data)

            if (this.props.data) {
                newData.forEach(oneNewData => {
                    if (oneNewData.value == this.props.data) {
                        this.setState({ choosen: oneNewData })
                        // choosen.push({ title: oneNewData.title, value: oneTitleId })
                    }
                });
            }

            // if (Array.isArray(this.props.data)) {
            //     let choosen = []
            //     this.props.data.forEach(oneTitle => {
            //         let oneTitleId = typeof oneTitle == 'object' ? oneTitle._id : oneTitle
            //         newData.forEach(oneNewData => {

            //             if (oneNewData.value == oneTitleId) {
            //                 choosen.push({ title: oneNewData.title, value: oneTitleId })
            //             }
            //         });

            //     })
            //     this.setState({ choosen })
            //     // console.log(choosen)


            // }

            this.setState({
                options: newData,
                parents: newParents,
                isLoading: false
            })
        })

        // }).catch(error => this.setState({ error, isLoading: false }));
    }


    spliter(source, string) {
        var stringArray = string.split('.')
        var finalString = source
        stringArray.forEach(element => {
            finalString = finalString[element]
        });
        return finalString
    }


    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] == condition) {
                return true
            }
        }
        return false
    }


    search = async (value) => {
        // console.log("SEARCH")
        // console.log(value)
        this.setState({ title: value })
        this.setState({ search: value })

        if (!this.state.isOpen) {
            this.toggleOpen()
        }

        if (value == "") {
            await this.setState({ options: this.state.tempData }, async () => {
                await this.setState({ tempData: [] })
            })
        } else {
            if (this.state.tempData.length == 0) {
                await this.setState({ tempData: this.state.options })
            }
            var values = []
            // var values = this.props.data.filter((e) => e.title === value)
            // console.log(this.state.tempData)
            this.state.tempData.forEach(element => {
                if (String(element.title).toLowerCase().includes(String(value).toLowerCase())) {
                    values.push(element)
                }
            })
            console.log(values)

            this.setState({ options: values })

            // }
        }
    }


    removeItem(index) {
        // let choosen = [...this.state.choosen]
        // choosen.splice(index, 1)
        this.setState({ choosen: '' }, () => {
            this.changeValue()
        })
    }



    changeValue() {
        // let choosen = []
        // this.state.choosen.forEach(element => {
        //     choosen.push((typeof element.value == 'object' ? element.value._id : element.value))
        //     // choosen.push((element.value))

        // });
        // console.log(choosen)
        // console.log(this.state.choosen)
        this.props.changeValue(this.props.header.key, (typeof this.state.choosen == 'object' ? this.state.choosen.value : this.state.choosen))
    }


    render() {
        // console.log(this.props.data)
        return (
            <div className='mt-0 w-100 h-100 flexc' style={{ position: 'relative' }} onClick={() => this.toggleOpen(true)} >
                <span style={{ opacity: 0 }}>0</span>

                {this.props.header.information.placeholder && (!this.props.data || this.props.data == '') && (
                    <div className="h-100 flexc" style={{ position: 'absolute', top: 0, cursor: 'pointer' }}>
                        <label className={' mr-1 ml-1 placeholder '} >{checkTextTranslation(this.props.header.information.placeholder)}</label>
                    </div>
                )}

                <div ref={ref => this.setWrapperRef(ref)} >
                    {/* <div style={{ flexWrap: 'wrap', borderRadius: 4, cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        {this.state.choosen.map((prop, index) => {
                            return (
                                <div key={index} className="" style={{ display: 'inline-flex', alignItems: 'center', paddingTop: 2, paddingBottom: 2 }}> */}
                    {(this.props.data && this.props.data != '') && (
                        <div className="flexcc" style={{ flexWrap: 'nowrap', cursor: 'pointer' }}>
                            <p style={{  }}>{this.state.choosen.title}</p>
                            {/* <img src="https://image.flaticon.com/icons/svg/1617/1617543.svg" onClick={() => this.removeItem(index)} style={{ padding: 2, paddingLeft: 0, color: '#78b2d0', width: 18, height: 18 }} /> */}
                        </div>
                    )}
                    {/* </div>
                            )
                        })} */}
                    {/* <div className={"position-relative mt-0 ml-1 "}> */}
                    {/* {!this.state.isFinal && (
                                <input ref={'input'} value={this.state.title} onFocus={() => { this.toggleOpen() }} onInput={event => this.search(event.target.value)} onChange={e => { this.props.changeValue(this.props.headerkey, e.target.value) }} className='nofocus mediumiransansfont' style={{ backgroundColor: 'transparent', minWidth: 100, width: this.state.title.length * 9, border: 'none' }} />
                            )} */}
                    {this.state.isLoading && (
                        <div style={{ position: 'absolute', top: 12, left: 5 }}>
                            <Loader
                                type="Oval"
                                color="#FF6C00"
                                height="20"
                                width="20"
                            />
                        </div>
                    )}



                    {this.state.isOpen && (
                        <div style={{ top: 35, display: 'flex', position: 'absolute', width: '100%', zIndex: 5 }}>
                            <div className={'search-drop-down blur-back'} style={{ paddingBottom: 20, display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#ffffffdd', borderColor: '#eee', borderRadius: 4, maxHeight: 280, overflow: 'auto', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                                {this.props.header.information.isSearchable && (
                                    <input value={this.state.search} onChange={event => this.search(event.target.value)} placeholder={"جست و جو ..."} style={{ zIndex: 1, fontSize: 13, textAlign: 'center', direction: 'rtl', alignSelf: 'stratch', padding: 10, margin: 5, backgroundColor: '#f7f7f7', borderRadius: 4, borderWidth: 1, borderStyle: 'solid', borderColor: '#eee', marginTop: 0, position: 'sticky', top: 5 }} className={'mediumiransansfont'} />
                                )}

                                <div className="mt-2" onClick={() => this.changeItem({ value: '' })} style={{ cursor: 'pointer', backgroundColor: 'transparent', opacity: 0.5, padding: '5px 20px' }}>
                                    <p style={{ margin: 0, color: '#456', fontSize: 13 }}>{this.props.header?.information?.defaultPlaceholder ?? "انتخاب کنید ..."}</p>
                                </div>

                                {this.state.parents?.map((prop, index) => {
                                    let selectableParent = this.props.header.information?.selectableParent
                                    let selected = false
                                    return (
                                        <div key={index}>
                                            {(!this.state.search || this.state.search == '') && (prop.parent == '' || !prop.parent) && (
                                                <div onClick={() => { if (selectableParent) this.changeItem(prop, selected) }} style={{ padding: '10px 20px', borderBottom: '1px solid #eee', marginBottom: 5, cursor: selectableParent ? 'pointer' : '' }}>
                                                    <p style={{ margin: 0, color: '#789', fontSize: 13 }}>{prop.title}</p>
                                                </div>
                                            )}
                                            { this.state.options?.map((cprop, cindex) => {
                                                let cselected = false

                                                if ((!this.props.header?.information?.dontShowChildren && cprop.parent == prop.value) || ((this.state.search && this.state.search != '') && (cprop.value == prop.value))) {
                                                    return (
                                                        <div key={cindex} onClick={() => this.changeItem(cprop, cselected)} style={{ cursor: 'pointer', backgroundColor: cselected ? '#f7f7f7' : '#transparent', padding: '5px 20px' }}>
                                                            <p style={{ margin: 0, color: '#000', fontSize: 14 }}>{cprop.title}</p>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )
                                })}

                                {this.state.options.length == 0 && (
                                    <div style={{ cursor: 'pointer', padding: 5, paddingTop: 10, paddingBottom: 10, borderBottomColor: '#eee', borderBottomStyle: 'solid', borderBottomWidth: 1 }}>
                                        <p style={{ textAlign: 'center', margin: 0, color: '#000', opacity: 0.5, fontSize: 13 }}>Found Nothing</p>
                                        {/* {this.props.header.information.tag && this.state.title != '' && (<p onClick={() => this.addTag()} style={{ textAlign: 'center', margin: 0, color: '#007aff', fontSize: 13, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}>+ ADD THIS</p>)} */}

                                    </div>
                                )}

                            </div>
                        </div>
                    )}

                </div>



            </div>
        );
    }
}


export default SelectWithParentInput;
