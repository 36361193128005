import React from 'react';
import Hero from '../components/Hero'
import { translate, imageAddress } from '../utils/useful'
import DataGrid from '../components/DataGrid'
import Product from '../components/Product'
import HttpService from '../utils/Http.services'
import { chooseWallpaper } from '../utils/useful'
import FastHelmet from '../components/FastHelmet';
import { siteConfig } from '../variables/config';
import TextInput from '../components/inputs/TextInput';
import SelectInput from '../components/inputs/SelectInput';
import LoaderButton from '../components/LoaderButton';
import SelectWithParentInput from '../components/inputs/SelectWithParentInput';
import TLink from '../components/TLink';
import Loader from 'react-loader-spinner';
import SortingButton from '../components/SortingButton';
import AdvertisementW from '../components/AdvertisementW';

class HomeOpia extends React.Component {

  state = {
    products: [],
    gallery: [],
    selectedGallary: 0,
    totalCount: null,
    limit: 16,
    currentPage: 0,
    // categories1: [
    //   { name: 'میکروکنترلر و پروسسور', icon: '/images/icons/proccessor.svg', link: '' },
    //   { name: 'پاور و تغذیه', icon: '/images/icons/power.svg', link: '' },
    //   { name: 'قطعات الکترونیکی', icon: '/images/icons/devices.svg', link: '' },
    //   { name: 'میکرو کامپیوتر', icon: '/images/icons/micro.svg', link: '' },
    //   { name: 'سنسور', icon: '/images/icons/sensors.svg', link: '' },
    //   { name: 'ماژول', icon: '/images/icons/modules.svg', link: '' },
    //   { name: 'ابزار و تجهیزات', icon: '/images/icons/proccessor.svg', link: '' },
    //   { name: 'نمایشگر', icon: '/images/icons/display.svg', link: '' },
    // ],

    categories: [],

    searchHeaders: [
      { key: 'title', type: 'TextInput', information: { label: '{{lang}}title', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: 'جست و جو ...', required: true } },
      // { key: 'category', type: 'SelectWithParentInput', information: { isSearchable: true, label: '{{lang}}category', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', address: 'getAllWithData', filter: { applet: 'categories' }, fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: 'دسته بندی ...', required: true } },
      { key: 'categories', type: 'SelectWithParentInput', information: { label: 'دسته بندی', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', address: 'getAllWithData', filter: { applet: 'categories' }, isSearchable: true, selectableParent: true, sort: "values.name", fields: { title: 'values.name', value: '_id', parent: 'values.parent' }, type: 'api', placeholder: 'دسته بندی', defaultPlaceholder: 'بدون دسته بندی', required: true } },

      { key: 'city', type: 'SelectWithParentInput', information: { label: 'استان', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', address: 'getAllWithData', filter: { applet: 'cities' }, isSearchable: true, sort: "values.name", dontShowChildren: false, selectableParent: true, fields: { title: 'values.name', value: '_id', parent: 'values.parent' }, type: 'api', placeholder: 'انتخاب شهر', required: true } },
    ],

    searchInfo: {},


    ads: []

  }


  componentDidMount() {
    this.fetch()
    this.fetchCategories()
    window.addEventListener("keypress", this.keyPress)
    // this.fetchWallpapers()
    // this.fetchGallery()
    // this.fetchVideo()

  }

  componentDidUpdate(e) {

    if (this.currentLocationKey !== e.history.location.key) {
      // console.log("HERE")
      this.currentLocationKey = e.history.location.key
      this.setState({ searchInfo: {} }, () => {
        this.doSearch()
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyPress)
  }

  keyPress = (e) => {
    // console.log(this.state.searchInfo)
    // console.log(Object.values(this.state.searchInfo).length)
    if (e.keyCode == 13 && Object.values(this.state.searchInfo).length > 0) {
      this.doSearch()
    }
  }

  fetch(search) {
    let filter = {}
    this.setState({ isLoadingProducts: true, data: [] })
    if (search) {
      this.setState({ isLoadingSearch: true })

      filter = { ...this.state.searchInfo }
      if (filter.title) {
        filter.title = { $regex: filter.title }

      }
    }

    let body = { filter, sort: this.state.currentSort }
    body.limit = this.state.limit
    body.skip = this.state.currentPage
    if (this.state.totalCount == null) {
      body.getCount = true
    }

    // console.log(body)

    HttpService.request("getAllAds", body, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      // console.log(fetchError)
      this.setState({ isLoadingProducts: false, isLoadingSearch: false })

      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ products: [] }, () => {

        this.setState({ products: fetchResult.info })
        if (fetchResult.count != null) {
          this.setState({ totalCount: fetchResult.count })
        } else {
          window.scrollTo(0, 600)
        }
      })
    })
  }



  fetchCategories() {

    this.setState({ isLoadingCategories: true })
    HttpService.request("getAllWithData", { filter: { applet: 'categories' }, sort: { 'values.priority': -1 } }, (fetchResult, fetchError) => {

      this.setState({ isLoadingCategories: false, })

      if (fetchError) { return }
      let categories = [...fetchResult.info]

      console.log(categories[0].values.name)

      categories.sort((a, b) => a.values.priority - b.values.priority)
      console.log(categories[0].values.name)
      console.log(categories)
      this.setState({ categories: categories })
    })
  }



  changeSearchValue = (key, value) => {
    // console.log(value)
    let searchInfo = this.state.searchInfo

    if (key == 'used') {
      if (value) {
        searchInfo.used = { $ne: true }
      } else {
        delete searchInfo.used
      }
    } else {

      if (!value || value == '') {
        delete searchInfo[key]
      } else {
        searchInfo[key] = value

      }
    }
    // console.log(searchInfo)
    this.setState({ searchInfo })
    // console.log(searchInfo)
  }


  doSearch = (sort) => {
    let currentSort = sort ?? this.state.currentSort
    this.setState({ currentSort }, () => {
      this.fetch(true)
    })
  }

  openCategory = (prop) => {
    // console.log(id)
    window.removeEventListener("mousedown", this.closeCategories)
    this.setState({ currentCategory: prop })
    window.addEventListener("mousedown", this.closeCategories)

  }

  closeCategories = (event) => {
    window.removeEventListener("mousedown", this.closeCategories)

    if (this.wrapperRef && this.wrapperRef.contains(event.target)) {
      // setTimeout(() => {
      //   this.setState({ currentCategory: null })
      // }, 500);
    } else {
      this.setState({ currentCategory: null })
    }


  }




  showPage = () => {
    if (this.state.totalCount) {
      let pages = Math.ceil(this.state.totalCount / this.state.limit)
      // console.log(pages)
      if (pages > 1) {
        let array = []
        for (let i = 0; i < pages; i++) {
          array.push(i + 1)
        }

        let dotDone = false
        return (
          <div>
            <div className="flexcc w-100" style={{ flexDirection: 'revert', overflow: 'auto' }}>{array.map((prop, index) => {
              if (index == 0 || index == array.length - 1 || (index > this.state.currentPage - 2 && index < this.state.currentPage + 3)) {
                dotDone = false

                return (
                  <p onClick={() => this.changePage(index)} style={{ margin: 2, backgroundColor: this.state.currentPage == index ? "rgb(253, 118, 42)" : '#d2d8e299', color: this.state.currentPage == index ? "#fff" : '#789', borderRadius: 2, padding: '2px 10px', cursor: 'pointer' }}>{prop}</p>
                )

              } else {
                if (!dotDone) {
                  dotDone = true
                  return (
                    <p style={{ margin: 2, borderRadius: 2, padding: '2px 6px', }}>...</p>
                  )
                }
              }
            })}

            </div>
            <div className="flexcc mt-3">
              <input className="" onChange={(e) => this.setState({ selectedPage: e.target.value })} style={{ height: 35, border: '1px solid #eee', borderRadius: 4, width: 80, padding: '4px 6px' }} placeholder={"صفحه"} />
              <button onClick={() => this.changePage(this.state.selectedPage - 1)} className="" style={{ backgroundColor: 'rgb(253, 118, 42)', borderRadius: 4, padding: '5px 15px', color: '#fff' }}>برو</button>
            </div>
          </div>
        )
      }
    }
  }



  changePage = (index) => {
    this.setState({ currentPage: index }, () => {
      this.fetch()
    })
  }



  render() {




    return (
      <div className={"Page container-fluid p-0"} style={{ backgroundColor: '#fafafa' }}>

        <FastHelmet title={'خانه'} description={'انبار مازاد'} />

        <div className="container pt-3">



          <div className="row mt-4">


            <div className="col-12 flexcc  pb-3 px-4" style={{ borderBottom: '2px solid #FF6C0026', position: 'relative' }}>
              <div className="d-flex no-scrollbar" style={{ overflow: 'auto' }}>
                {this.state.categories.map((prop, index) => {
                  if (!prop.values.parent || prop.values.parent == '') {
                    return (
                      <button onClick={() => this.openCategory(prop)} key={index} className="text-center mx-2 p-0 d-flex flex-column align-items-center" style={{ maxWidth: 70 }}>
                        <img src={imageAddress(prop.values?.image, null, 'thumb')} alt="" width="40px" />
                        <p className="text-smallest mt-1">{prop.values?.name}</p>
                      </button>
                    )
                  }
                })}

                {this.state.isLoadingCategories && (
                  <div className="flexcc ">
                    <Loader
                      type={"Oval"}
                      color={"#FF6C00"}
                      height={50}
                      width={50}
                    />
                  </div>

                )}
              </div>

              {this.state.currentCategory && (
                <div ref={el => this.wrapperRef = el} className="p-3" style={{ boxShadow: '0px 0px 15px #00000010', zIndex: 8, position: 'absolute', width: '100%', left: 0, top: 90, backgroundColor: '#fff', borderRadius: 10 }}>

                  <div className="p-3" style={{}}>
                    <p className="text-semibig pb-3" style={{ borderBottom: '2px solid #ddd' }}>{this.state.currentCategory?.values?.name}</p>
                    <div className="flexc">
                      <div style={{ width: 100, height: 2, backgroundColor: '#FF6C00', marginTop: -2 }}></div>
                      <div style={{ width: 50, height: 2, backgroundColor: '#FF6C00', marginTop: -2, marginRight: 10 }}></div>
                    </div>
                  </div>

                  <div className="row mb-2">

                    {this.state.categories.map((prop, index) => {
                      // console.log(prop)
                      if (prop.values?.parent?._id == this.state.currentCategory?._id) {
                        return (
                          <TLink to={'/search'} search={'?cat=' + prop._id} key={index} className="col-4 col-md-4 col-lg-2 mx-2">
                            {/* <img src={imageAddress(prop.values?.image, null, 'thumb')} alt="" width="40px" /> */}
                            <p className="text-small">{prop.values?.name}</p>
                          </TLink>
                        )
                      }
                    })}

                  </div>
                </div>
              )}


            </div>

            {/* <div className="col-12 flexcc  pb-3 px-4" style={{ borderBottom: '2px solid #FF6C0026',position:'relative' }}>
              <div className="d-flex no-scrollbar" style={{overflow:'auto'}}>
              {this.state.categories1.map((prop, index) => {
                  return (
                    <div key={index} className="text-center mx-2" style={{ maxWidth: 90 }}>
                      <img src={prop.icon} alt="" width="40px" />
                      <p className="text-smallest">{prop.name}</p>
                    </div>
                  )
                
              })}
              </div>
            </div> */}


            {/* <hr style={{borderColor:'#FF6C0026'}}/> */}


            <div className="col-12 mb-3 mt-4 flexcc flex-column w-100">
              <h1 className=" text-center text-ultra-big pb-2 px-3" style={{ borderBottom: '1px solid rgb(245,105,33)' }}>انبار مازاد</h1>
              <div className="flexcc flex-column mt-3">
                <p className="text-small text-center" style={{ maxWidth: 900, color: '#456' }}>
                  قطعات و ادوات مازادی دارید  که می خواهید آن ها را به نقدینگی تبدیل کرده و از هزینه های نگهداری خلاص شوید؟
                  یا به دنبال خرید اقلام نو، دست دوم و نایاب در حوزه قطعات و دستگاه های الکترونیکی، مخابراتی و الکتریکی با هزینه ی کمتر هستید؟
                  انبار مازاد راه حل شماست! <TLink to={'/aboutus'} style={{ color: 'rgb(255, 108, 0)' }}>اطلاعات بیشتر</TLink>
                </p>


                <div className="w-100 row py-1 px-2 mt-5 mb-3 w-100" style={{ backgroundColor: '#fff', borderRadius: 40, boxShadow: '0px 0px 15px #00000010' }}>
                  <div className="col-12 search-field col-md-3 flexcc" style={{ borderLeft: '1px solid #FF6C0030' }}>
                    <TextInput header={this.state.searchHeaders[0]} changeValue={this.changeSearchValue} data={this.state.searchInfo["title"]} />
                  </div>
                  <div className="col-12 search-field col-md-3 flexcc" style={{ borderLeft: '1px solid #FF6C0030' }}>
                    <SelectWithParentInput header={this.state.searchHeaders[1]} changeValue={this.changeSearchValue} data={this.state.searchInfo["categories"]} />
                  </div>
                  <div className="col-12 search-field col-md-3 flexcc">
                    <SelectWithParentInput header={this.state.searchHeaders[2]} changeValue={this.changeSearchValue} data={this.state.searchInfo["city"]} />
                  </div>

                  {/* <div className="col-12 search-field col-md-3 flexcc">
                    <SelectWithParentInput header={this.state.searchHeaders[2]} changeValue={this.changeSearchValue} data={this.state.searchInfo["city"]}  />
                  </div> */}

                  <div className="col-12 col-md-3 d-flex justify-content-end m-0 p-0">
                    <LoaderButton
                      onClick={this.doSearch}
                      isLoading={this.state.isLoadingSearch}
                      text={'جست و جو'}
                      type={"Oval"}
                      className="w-100  "
                      buttonStyle={{ flex: 1, whiteSpace: 'nowrap', outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 20px', fontSize: 15, color: '#fff', border: 'none', width: '100%' }}
                      buttomClassName={'search-but'}
                      width={40}
                      height={40}
                      color={'#FF6C00'}
                    />
                  </div>



                </div>


                <div className='w-100 px-3'>
                  <input id="new" name="new" type="checkbox" onChange={e => this.changeSearchValue('used', e.target.checked)} />
                  <label className="px-1" style={{ fontSize: 14, color: '#789', userSelect: 'none' }} for="new" >تنها کالای نو</label>
                </div>



              </div>
            </div>

            <div className="col-12  mt-2 mb-2">
              <AdvertisementW />
            </div>

            <div className="col-12 mb-5 mt-4">

              <div className="flexcb mb-4 pb-2" style={{ borderBottom: '1px solid #DBDBDB' }}>
                <div>
                  <span className="text-semibig " style={{ color: '#585858' }}>آگهی ها</span>
                </div>

                <SortingButton doSearch={this.doSearch} />

              </div>

              <DataGrid showAd={true} isLoading={this.state.isLoadingProducts} data={this.state.products} notFoundText="موردی یافت نشد" schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Product} col="col-12 col-md-6 col-lg-3 flexcc align-items-stretch " />
              <div className="text-center">
                {this.state.products?.length === 0 && !this.state.isLoadingProducts && (
                  <p style={{ fontSize: 13, color: '#789', marginTop: 20 }}>جست و جوی دیگری را برای یافتن کالا امتحان کنید </p>
                )}
              </div>



              <div className="w-100" style={{ overflow: 'hidden' }}>
                {this.showPage()}
              </div>



            </div>
          </div>
        </div>





      </div>

    );
  }
}

export default HomeOpia;
