import React from 'react';
import { translate, checkTextTranslation, chooseWallpaper } from '../../utils/useful'
// import Section from '../../components/Section'
// import TLink from '../../components/TLink';
import HttpService from '../../utils/Http.services';
// import { siteConfig } from '../../variables/config';
// import stripHtml from "string-strip-html";
// import { imageAddress } from '../../utils/useful'
import Loader from 'react-loader-spinner'
import FastHelmet from '../../components/FastHelmet';
import DataGrid from '../../components/DataGrid'
import Estate from '../../components/addestate/Estate'


class Categories extends React.Component {


  state = {
    data: [],
    pages: [
      { pathname: 'rent', title: 'رهن و اجاره', image: 'اجاره' },
      { pathname: 'sale', title: 'خرید و فروش', image: 'فروش' },
      { pathname: 'presale', title: 'پیش فروش', image: 'پیش فروش' },
      { pathname: 'barter', title: 'تهاتر', image: 'تهاتر' },

    ]
  }


  componentDidMount() {

    let pathname = window.location.pathname.split('/')[1]
    let page = ''

    // this.state.pages.forEach(page => {

    for (let i = 0; i < this.state.pages.length; i++) {
      const onePage = this.state.pages[i];
      if (onePage.pathname == pathname) {
        // console.log(onePage)
        page = onePage
      }
    }

    this.setState({ page }, () => {
      this.fetch()
      this.fetchWallpapers(page.image)
    })

    // });
    // console.log(page)



    // this.setWidth()
    // window.addEventListener('resize', this.setWidth)
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  fetch() {
    this.setState({ isLoadingPge: true, estates: [] })

    HttpService.request("getAllWithData", { applet: 'estates', filter: { 'values.transaction': this.state.page.image } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingPge: false })

      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ estates: fetchResult.info })
    })
  }



  fetchWallpapers(page) {

    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': page } }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  render() {

    return (
      <section style={{ paddingBottom: 80 }}>

        <FastHelmet title={this.state.page?.title} description={this.state.page?.title} />


        <div className="text-center flexcc" style={{ padding: '40px 20px 40px 20px', position: 'relative', backgroundColor: '#bbccdd', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", height: '60vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="position-absolute w-100 h-100" style={{ background: '#00000070', top: 0, left: 0 }}>
          </div>

          {/* <h1 className=" text-ultra-big white">{checkTextTranslation('{{lang}}welcome-to')}</h1> */}
          <div style={{ position: 'relative' }}>
            <h1 className=" text-mega-big white" >{this.state.page?.title}</h1>
            <p className=" text-normal white" >برای اطلاعات بیشتر با ما تماس بگیرید</p>
            <p className=" text-small white mt-2 opacity-5" style={{ letterSpacing: 2 }} >RAEESMELK.COM</p>

          </div>
        </div>


        <div className="container " style={{}}>
          <div className="col-12 mb-5 pt-5">
            <DataGrid isLoading={this.state.isLoadingProducts} data={this.state.estates} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Estate} col="col-12 col-md-6 col-lg-4" />
          </div>
        </div>



      </section>
    );
  }
}

export default Categories;
