import React from 'react';
import { translate, checkTextTranslation } from '../../utils/useful'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import HttpService from '../../utils/Http.services';
import PriceInput from '../../components/inputs/PriceInput';

class MeWallet extends React.Component {

  state = {
    headers: [
      { key: 'currentPassword', type: 'PasswordInput', col: '6', information: { label: '{{lang}}currentPassword', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertPassword', required: true } },
      { key: 'newPassword', type: 'PasswordInput', col: '6', information: { label: '{{lang}}newPassword', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertPassword', required: true } },
      // { key: 'password', type: 'PasswordInput', information: { label: '{{lang}}password', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertPassword', required: true } }
    ]
  }


  increaseBalance = () => {
    // let data = this.form.getForm()
    if (this.state.price) {
      let price = this.state.price*2000
      // console.log(price)
      this.setState({ isLoading: true, errors: {}, message: null })
      HttpService.request("increaseBalance", { price }, (fetchResult, fetchError) => {
        // console.log(fetchError)
        this.setState({ isLoading: false })
        if (fetchError) { this.setState({ errors: fetchError.message }); return }
        // this.setState({ message: '{{lang}}profileUpdated' })
        console.log(fetchResult.info)
        if (fetchResult.url && fetchResult.url !== '') {
          window.location = fetchResult.url
        }
        // this.props.setUserNewInfo(fetchResult.info)
      })
    }
  }


  changeValue = (key, value) => {
    this.setState({ price: value })
  }

  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>

          <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('wallet')}</p>
          </div>



          <div className="flexcc flex-column">




            {/* <h2 className="mt-4 " style={{ fontSize: 27 }}>Sign in | Sign up</h2> */}

            <div className="w-100" style={{ padding: '30px 35px' }}>

              {/* <FormViewer ref={el => this.form = el} headers={this.state.headers} initData={this.props.user } errors={this.state.errors} inputClass={'modern-input'} /> */}

              <div className="text-center p-5" style={{ backgroundColor: '#f2f6f8' }}>
                <p className="text-big font-bold">{this.props.user.balance ?? 0} چیلیک</p>
                <p className="mt-2">موجودی کیف پول</p>
                <p className="mt-2" style={{ fontSize: 12 }}>هر چلیک معادل ۲۰۰۰ تومان می‌باشد</p>

              </div>




              <div className="text-center mt-3">

                <p className="mb-3">افزایش موجودی</p>
                <div className="flexcc">
                  <div style={{ maxWidth: 250, backgroundColor: '#f2f6f8', borderRadius: 4, padding: 10 }}>
                    <PriceInput header={{ information: { placeholder: 'مبلغ به چیلیک' } }} changeValue={this.changeValue} />
                  </div>
                </div>
                {this.state.price && (<p className="mt-2" style={{ fontSize: 12 }}>معادل با {this.state.price * 2000} تومان</p>)}



                <LoaderButton
                  onClick={this.increaseBalance}
                  isLoading={this.state.isLoading}
                  text={"افزایش موجودی"}
                  type={"Oval"}
                  className="mt-4 mb-4"
                  buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                  width={40}
                  height={40}
                  color={'#202020'}
                />
                {/* <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>This website uses cookies to deliver the best possible user experience. With signning up you are accepting this fact</p> */}
              </div>

              {this.state.message && (
                <div className="w-100 mt-2 text-center" style={{ backgroundColor: '#007aff10', borderRadius: 4, color: '#007aff', padding: '20px 20px' }}>
                  <p className="text-small">{checkTextTranslation(this.state.message)}</p>
                </div>
              )}

            </div>
          </div>



        </div>

      </section>
    );
  }
}

export default MeWallet

