import React from 'react';
import Loader from 'react-loader-spinner'
import {checkTextTranslation} from '../utils/useful'

export default class LoaderButton extends React.Component {

    state = {
        isLoading: false,
        opacity: 1
    }

    onClick = () => {
        if (!this.props.isLoading) {
            this.props.onClick()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isLoading !== prevProps.isLoading) {
            this.setState({ opacity: 0 })
            setTimeout(() => {
                this.setState({ isLoading: this.props.isLoading })
                this.setState({ opacity: 1 })
            }, 300);
        }
    }

    render() {
        return (
            <div className={"flexcc "+this.props.className} style={{ opacity: this.state.opacity, transition: 'all 0.3s' }}>
                {!this.state.isLoading ? (
                    <button className={this.props.buttomClassName} onClick={() => this.onClick()} style={this.props.buttonStyle}>
                        {checkTextTranslation(this.props.text)}
                    </button>
                ) : (
                        <Loader
                            type={this.props.type ?? "Oval"}
                            color={this.props.color ?? "#202020"}
                            height={this.props.width ?? 50}
                            width={this.props.height ?? 50}
                        />
                    )}
            </div>

        )
    }
}