import React from 'react';
import DataGrid from '../../components/DataGrid'
import BlogBox from '../../components/BlogBox'
import HttpService from '../../utils/Http.services';
import { translate, chooseWallpaper, imageAddress } from '../../utils/useful';
import FastHelmet from '../../components/FastHelmet';


class Blogs extends React.Component {

  state = {
    data: []
  }

  componentDidMount() {
    this.fetch()
    this.fetchCategories()
  }


  fetch(category) {
    this.setState({ isLoading: true, data: [] })

    let filter = { applet: 'blogs' }
    if (category) {
      filter.filter = { 'values.category': category }
    }
    console.log(filter)
    HttpService.request("getAllWithData", filter, (fetchResult, fetchError) => {
      this.setState({ isLoading: false })

      if (fetchError) { return }
      this.setState({ data: fetchResult.info })
    })
  }


  fetchCategories() {
    // this.setState({ isLoading: true })
    HttpService.request("getAllWithData", { applet: 'blogCategories' }, (fetchResult, fetchError) => {
      // this.setState({ isLoading: false })

      if (fetchError) { return }
      let categories = fetchResult.info
      if (Array.isArray(categories)) {
        categories.splice(0, 0, { values: { title: 'همه', image: { address: 'mini/8a5ae8b116c5d30d--f1130054--1412e9a5--aae63f82--1d74e2933d87debbefac77e09660211.jpg' } } })
      }
      this.setState({ categories })
    })
  }


  changeCategory(index) {
    let category = this.state.categories[index]._id
    this.setState({ selectedCategory: category })
    this.fetch(category)
  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8', minHeight: '100vh' }}>

        <FastHelmet title={translate('blogs')} description={translate('blogs')} />


        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h1 className=" text-mega-big white">{translate('blogs')}</h1>
        </div>




        <div className="container mt-4">
          <div className="row">

            <div className="col-12 mb-5 mt-1 text-center">
              {/* <p className="mb-3 font-bold text-big">{translate('categories')}</p> */}
              <div className="flexcc" style={{ overflow: 'auto' }}>
                {this.state.categories?.map((prop, index) => {
                  // console.log(prop.values.image)
                  return (
                    <button onClick={() => this.changeCategory(index)} className="mrd-2 px-3 py-2 flexcc" key={index} style={{ backgroundColor: this.state.selectedCategory == prop._id ? '#000' : '#fff', borderRadius: 8 }}>
                      <img className="mrd-2" style={{ borderRadius: 20, height: 30, width: 30, objectFit: 'cover' }} src={imageAddress(prop.values.image, null, 'mini')} />
                      <span style={{ color: this.state.selectedCategory == prop._id ? '#fff' : '#000' }}>{prop.values.title}</span>
                    </button>
                  )
                })}
              </div>
            </div>

            <div className="col-12 mb-5 mt-2">
              <DataGrid isLoading={this.state.isLoading} data={this.state.data} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={BlogBox} col="col-12 col-md-6 col-lg-4" />
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default Blogs;
