import React from 'react'

class SortingButton extends React.Component {

    state = {
        currentSort: 0,
        sortList: [
            { title: 'جدیدترین ها', sort: { 'cDate': -1 } },
            { title: 'کمترین قیمت', sort: { 'price': 1 ,'cDate': -1} },
            { title: 'بیشترین قیمت', sort: { 'price': -1,'cDate': -1 } },

        ]
    }


    componentWillUnmount(){
        window.removeEventListener('mouseup',this.hideList)
    }
    
    showList=()=>{
        this.setState({showList:true})
        window.addEventListener('mouseup',this.hideList)
    }

    hideList=()=>{
        window.removeEventListener('mouseup',this.hideList)
        setTimeout(() => {
            this.setState({showList:false}) 
        }, 300);
    }

    changeSort(index) {
        let sortItem = this.state.sortList[index]
        this.props.doSearch(sortItem.sort)
        this.setState({ currentSort: index })
    }



    render() {

        return (
            <div className="text-center  flexcc">
                <button onClick={()=>this.showList()}>
                    <span className="text-small" style={{ color: '#FF6C00' }}>به ترتیب {this.state.sortList[this.state.currentSort].title}</span>
                </button>
                <div className={"flexcc flex-column " + (this.state.showList ? "" : 'd-none')} style={{ position: 'absolute', backgroundColor: '#fff', borderRadius: 4, padding: '7px 16px', top: 30, zIndex: 1, boxShadow: '0px 0px 15px #00000010' }}>
                    {this.state.sortList.map((prop, index) => {
                        return (
                            <button className="mb-1" key={index} onClick={() => this.changeSort(index)}>
                                <span className="text-smaller" style={{ color: '#FF6C00' }}>{prop.title}</span>
                            </button>
                        )
                    })}

                </div>
            </div>
        )
    }
}

export default SortingButton