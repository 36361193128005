import React from "react";
import HttpService from '../../utils/Http.services';
import { siteConfig } from "../../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import TLink from "../../components/TLink";
import StagesManager from "../../components/StagesManager";
import ResourceFirstStep from "../../components/sendResource/ResourceFirstStep";
import ResourceTypeStep from "../../components/sendResource/ResourceTypeStep";
import UploadResourceStep from "../../components/sendResource/UploadResourceStep";

import ResourceInfoStep from "../../components/sendResource/ResourceInfoStep";
import ResourceThanksStep from "../../components/sendResource/ResourceThanksStep";
import { setObject, chooseWallpaper, translate, imageAddress, pathMaker } from "../../utils/useful";
import ResourceFilesStep from "../../components/sendResource/ResourceFilesStep";
import ResourcePhotoInfoStep from "../../components/sendResource/ResourcePhotoInfoStep";
import ResourcePriceTypeStep from "../../components/sendResource/ResourcePriceTypeStep";
import ResourcePriceStep from "../../components/sendResource/ResourcePriceStep";
import FormViewer from "../../components/FormViewer";
import LoaderButton from "../../components/LoaderButton";
import Loader from "react-loader-spinner";

class EditResource extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: ['d7e2f7', 'eee1ff', 'fedfe7', 'dad6e4', 'f7dbd7', 'fff2e1'],
      wallpapers: [],
      isLoadingPage: true,
      // loginInfo: {},
      stage: 0,
      canResend: true,
      errors: [],
      data: { ["applet"]: 'resources' },
      phone: '',
      settings: { indicatorType: 'phone', mode: 'login', loginWithCode: false, passwordRequired: true },
      formHeaders: [
        { key: 'values.price', type: 'PriceInput', information: { label: '{{lang}}price', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertPrice', required: true } },

        { key: 'values.title', type: 'TextInput', information: { label: '{{lang}}title', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertTitle', required: true } },
        { key: 'values.category', type: 'MultiSelectInput', information: { isSearchable: true, label: '{{lang}}category', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', address: 'getAllWithData', filter: { applet: 'categories' }, fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: '{{lang}}category', required: true } },
        // { key: 'values.description', type: 'TextInput', information: { label: '{{lang}}Description', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertDecription', required: true } },
        { key: 'values.tags', type: 'TagInput', information: { hint: '{{lang}}tag-hint', label: '{{lang}}tags', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertTags', required: false } },
      ]
    }
  }


  componentDidMount() {
    // let settings = this.state.settings
    // let pathname = this.props.location.pathname.split('/src/')
    // pathname = pathname[pathname.length - 1]
    // if (pathname.toLowerCase() === 'forgotpassword') {
    //   settings.mode = 'forgotPassword'
    // }
    this.fetchWallpapers()

    this.fetch()

    // this.changeStage("priceType")
  }


  fetch() {
    // console.log("FETCH")
    let search = this.props.location.search
    let params = new URLSearchParams(search)
    let id = params.get('id')

    this.setState({ isLoadingPage: true, isLoadingSimilars: true })

    HttpService.request("getOneWithData", { filter: { _id: id } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingPage: false })
      this.setState({ isLoadingSimilars: true })

      if (fetchError) { this.setState({ notFound: true }); return }
      // console.log(fetchResult.info)
      if (fetchResult.info) {

        if (fetchResult.info.values.resourceType == 'photo') {
          let headers = this.state.formHeaders
          let photoInfo = [
            { key: 'values.photoInfo.cameraMake', type: 'TextInput', information: { label: '{{lang}}photoInfo.cameraMake', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertInfo', required: false } },
            { key: 'values.photoInfo.cameraModel', type: 'TextInput', information: { label: '{{lang}}photoInfo.cameraModel', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertInfo', required: false } },
            { key: 'values.photoInfo.focalLength', type: 'TextInput', information: { label: '{{lang}}photoInfo.focalLength', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertInfo', required: false } },
            { key: 'values.photoInfo.aperture', type: 'TextInput', information: { label: '{{lang}}photoInfo.aperture', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertInfo', required: false } },
            { key: 'values.photoInfo.shutterSpeed', type: 'TextInput', information: { label: '{{lang}}photoInfo.shutterSpeed', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertInfo', required: false } },
            { key: 'values.photoInfo.ISO', type: 'TextInput', information: { label: '{{lang}}photoInfo.ISO', icon: 'https://image.flaticon.com/icons/svg/3037/3037852.svg', placeholder: '{{lang}}insertInfo', required: false } },
          ]
          headers = [...headers, ...photoInfo]
          // console.log(headers)
          this.setState({ formHeaders: headers })
        }

        console.log(fetchResult.info)
        this.setData(fetchResult.info, (newData) => {
          // console.log(newData)
        })

        this.setState({ data: fetchResult.info }, () => {
          // this.fetchSimilar()
        })
      }
    })
  }


  fetch1() {






  }



  setData = (data, cb, ref) => {
    let newData
    if (!ref) {
      newData = this.state.data
    } else {
      newData = ref
    }
    // console.log(data)
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (key == '**files') {
          if (!newData["**files"]) {
            newData["**files"] = {}
          }
          newData["**files"] = Object.assign(newData["**files"], value)
        } else {
          if (typeof value == "object") {
            this.setData(value, (newValue) => {
              // console.log(key)
              // console.log(newData[key])
              if (!newData[key]) {
                newData[key] = {}
              }
              newData[key] = Object.assign(newData[key], newValue)

            }, data[key])
          } else {
            // console.log(newData)
            setObject(newData, key, value)
            // console.log(newData)

          }
          // console.log(newData)
          // newData[key] = value
        }
      }
      if (ref) {
        // console.log(ref)
        cb(newData)
        // return 
      } else {
        // console.log(newData)
        this.setState({ data: newData }, () => {
          if (cb) { cb(newData) }
        })
      }
    }
  }



  changeStage = (newStage, cb) => {
    if (this.stageManager) {
      this.stageManager.changeStage(newStage, cb)
    }
  }

  lastStage = () => {
    if (this.stageManager) {
      this.stageManager.lastStage()
    }
  }


  uploadResource = (cb) => {

    let data = this.form.getForm()

    this.setData(data, (newData) => {
      this.setState({isLoading:true})
      // let data = this.state.data
      newData.values.status = 'Not Active'
      // console.log(newData)
      // if (data) {
        HttpService.request('updateResource', data, (fetchResult, fetchError) => {
          this.setState({isLoading:false})

          if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }

          // this.changeStage('thanks')
          this.props.history.push(pathMaker('/me/resources'))
          if (cb) { cb() }
        })
      // }
    })
  }

  fetchWallpapers() {
    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'send resource' } }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  // infoDone = () => {

  // }

  render() {
    return (
      <section className="d-flex w-100 position-relative" style={{ minHeight: '100vh', padding: '0px' }}>

        <section className="row p-0 w-100">

          <section className="d-none d-md-block col-12 col-md-5 col-lg-4" style={{ height: '100vh', position: 'sticky', top: 0, padding: '100px 30px 20px 30px', textAlign: 'center', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="h-100 w-100" style={{ background: '#00000050', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ position: 'relative' }}>
              <h1 className="text-mega-big white">Chilik.net</h1>
              <h1 className="text-big white">{translate('editResource')}</h1>
              <TLink to={'/pages/terms'}><p className="white opacity-7" style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('resource-accept-terms')}</p></TLink>
            </div>
          </section>

          <section className="col-12 col-md-7 col-lg-8 flexc flex-column" style={{ paddingTop: 100, opacity: this.state.mainOpacity, transition: 'all 0.5s', flex: '1 1 auto' }}>

            {this.state.isLoadingPage && (
              <div className="flexcc mt-4">
                <Loader
                  type={this.props.type ?? "Oval"}
                  color={this.props.color ?? "#202020"}
                  height={this.props.width ?? 50}
                  width={this.props.height ?? 50}
                />
              </div>

            )}

            {!this.state.isLoadingPage && (
              <div className="w-100 " style={{ maxWidth: 400 }}>
                <div className="flexcc mb-4">
                  <img className="" src={imageAddress(this.state.data?.values?.file)} style={{ maxWidth: '100%', borderRadius: 8, maxHeight: 400 }} />
                </div>
                <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.state.data} errors={this.state.errors} inputClass={'modern-input'} />

                <div className="text-center">
                  <LoaderButton
                    // onClick={this.uploadResource}
                    onClick={this.uploadResource}

                    isLoading={this.state.isLoading}
                    // text={"Upload Resource"}
                    text={translate('update')}

                    type={"Oval"}
                    className="mt-4 mb-4"
                    buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                    width={40}
                    height={40}
                    color={'#202020'}
                  />
                </div>

              </div>
            )}

          </section>
        </section>



      </section>
    )
  }
}


const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditResource);
