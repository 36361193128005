const siteConfig = {
    languages: ["fa"],
    // languages: ["fa"],

    defaultLng: 'fa',
    dontShowUrlForDefaultLng: true,
    forceUrl: true,

    // name: 'لوماناب',
    // siteName: 'lomanab.ir',

    // name: 'دکتر ادیب',
    // siteName: 'doctoradib.com',





    // showCart: true,
    showProfile: true,
    // showLanguage: true,
    // showDeliveryCalcText: false,



    // RAEESMELK
    // rootDomain: 'http://raeesmelk.com/',
    // domain: 'http://raeesmelk.com/raeesmelk/',
    // assetsDomain: 'http://raeesmelk.com/assets/',
    // name: 'رئیس ملک',
    // siteName: 'raeesmelk.com',
    // showCart: false,
    // showProfile: false,
    // showLanguage: false,
    // addEstate: true,
    // phone: '02634216962',
    // mobile: '09353144431',
    // instagram: 'https://instagram.com/raees.melk',
    // email: 'info@raeesmelk.com',
    // addressFa: 'کرج-خیابان رستاخیز-نبش نهم شرقی-گروه املاک رئیس',





    // rootDomain: 'http://lomanab.ir/',
    // domain: 'http://lomanab.ir/lomanab/',
    // assetsDomain: 'http://lomanab.ir/assets/',



    // rootDomain: 'http://localhost:3001/',
    // domain: 'http://localhost/shopera/',
    // domain: 'http://localhost/lomanab/',

    // assetsDomain: 'http://localhost/assets/',


    // CHILIK
    // domain: 'http://chilik.net/chilik/',
    // rootDomain: 'http://www.chilik.net/assets',
    // assetsDomain: 'http://chilik.net/assets/',
    // name: 'Chilik.net',
    // siteName: 'Chilik.net',
    // instagram: 'chilik.net',
    // email: 'info@chilik.net',
    // phone: '09023939117',
    // wallet: true


    // OPIA
    rootDomain: 'https://anbarmazad.com/',
    domain: 'https://anbarmazad.com/anbarmazad/',
    assetsDomain: 'https://anbarmazad.com/assets/',
    name: 'انبار مازاد',
    siteName: 'anbarmazad.com',
    phone: '02166174709',
    instagram: 'https://instagram.com/Anbarmazad',
    email: 'info@anbarmazad.com',
    // linkedin: 'https://www.linkedin.com/in/opia-home-5175821ab/',
    // youtube: "https://www.youtube.com/channel/UCtUkxIT-wZgrSjuYzJQjQjA",
    // aparat: "https://www.aparat.com/opiahome",
    facebook: 'https://www.facebook.com/Anbar mazad',
    telegram: 'https://t.me/anbarmazad',






    // phone: '02166400483 – 02166400484',
    // phone: "09129368035",

    // addressFa: 'تهران - خیابان - پلاک',
    // addressEn: 'no, Street, Tehran, Iran',

    addressFa: "تهران،  خیابان انقلاب، خیابان فخر رازی، خیابان نظری، پ61، واحد a3",
    // addressFa: "کرج ، خیابان آبان ، خیابان شبیری ، کوچه ۱۴ پلاک ۲",
    // addressEn: "2, 14 Av, Beheshti St, Karaj, Alborz, Iran",
    // addressFa: "ایران - البرز - کرج - خیابان بهشتی - کوچه ۱۴ - پلاک ۲",

    // slangFa: 'شعار شرکت در اینجا آورده میشود',
    // slangEn: 'Company slang would be here',

    // linkedin: '',
    // facebook: '',






}

const siteTheme = {

    // logo: '/images/lomanab.png',
    // logo_s: '/images/lomanab.png',
    // invertLogo: false,

    logo: '/images/logo.png',
    logo_s: '/images/logo-s.png',

    // logo: '/images/raeesMelk.png',
    // logo_s: '/images/raeesMelk.png',


    // logo: '/images/logo-c.png',
    // logo_s: '/images/logo-c.png',

    // logo: '/images/drAdib.png',
    // logo_s: '/images/drAdib.png',

    invertLogo: false,



    // themeColors: { headerBack: '#f2f7f8', headerFont: '#000000' },
    // themeColors: { headerBack: '#46196f', headerFont: '#ffffff' },
    themeColors: { headerBack: '#ffffff', headerFont: '#585858' },



    backgroundColors: { normal: '#e2e8ea', plus: '#ee5050', luxury: '#101010', custom: '#d3b564' },
    colors: { normal: '#202020', plus: '#fff', luxury: '#fff', custom: '#101010' },
}

const languagesInfo = {
    en: {
        shortName: 'en',
        direction: 'ltr',
        name: 'English',
        icon: 'https://image.flaticon.com/icons/svg/197/197484.svg'
    },
    fa: {
        shortName: 'fa',
        direction: 'rtl',
        name: 'فارسی',
        icon: 'https://image.flaticon.com/icons/svg/197/197574.svg'
    }
}


let apisList = {
    postUserIndicator: { route: 'users/signup/postUserIndicator', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    signupActicationCode: { route: 'users/signup/postActicationCode', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    signinActicationCode: { route: 'users/signin/postActicationCode', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postUserInfo: { route: 'users/signup/postUserInfo', server: 'yc', hashMethod: '', flexibleParams: true, params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postForgotPassword: { route: 'users/signup/postForgotPassword', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postPassword: { route: 'users/signup/postPassword', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "password", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postNewPassword: { route: 'users/signin/postNewPassword', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "password", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    getUserInfo: { route: 'users/getUserInfo', server: 'yc', hashMethod: '', auth: 'token', params: [] },
    getOneUser: { route: 'users/getOneUser', server: 'yc', hashMethod: '', auth: 'token', params: [{ key: "id", type: 'string', required: true }] },
    updateUserInfo: { route: 'users/updateUserInfo', server: 'yc', hashMethod: '', flexibleParams: true, auth: 'token', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    updateUserPassword: { route: 'users/updateUserPassword', server: 'yc', hashMethod: '', flexibleParams: true, auth: 'token', params: [{ key: "currentPassword", type: 'string', required: true }, { key: "newPassword", type: 'string', required: true }] },

    getCategories: { route: 'values/getCategories', server: 'yc', hashMethod: '', params: [], flexibleParams: true },
    getAllWithData: { route: 'values/getAllWithData', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    getOneWithData: { route: 'values/getOneWithData', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    getOneFilter: { route: 'values/getOneWithData', server: 'yc', hashMethod: '', params: [], flexibleParams: true },
    getOneAdvertisement: { route: 'values/getOneAdvertisement', server: 'yc', hashMethod: '', params: [], flexibleParams: true },


    // partnerRequest:{ route: 'values/getOneWithData', server: 'yc', hashMethod: '', params: [], flexibleParams: true },
    postPartnerInfo: { route: 'users/postPartnerInfo', server: 'yc', auth: 'token', hashMethod: '', flexibleParams: true, params: [] },

    postGuaranteeCode: { route: 'guarantee/postGuaranteeCode', server: 'yc', auth: 'token', hashMethod: '', flexibleParams: true, params: [{ key: "code", type: 'string', required: true }] },
    postGuaranteeInfo: { route: 'guarantee/postGuaranteeInfo', server: 'yc', auth: 'token', hashMethod: '', flexibleParams: true, params: [] },

    submitForm: { route: 'values/submitForm', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    updateResource: { route: 'values/updateResource', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    addSubscribe: { route: 'values/addSubscribe', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },


    submitOrder: { route: 'orders/submitOrder', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    getOrders: { route: 'orders/getAllWithData', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    submitPreorder: { route: 'orders/submitPreorder', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    postOffCode: { route: 'offCodes/postOffCode', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    verifyPayment: { route: 'orders/verifyPayment', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    verifyBalancePayment: { route: 'orders/verifyBalancePayment', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },



    increaseBalance: { route: 'orders/increaseBalance', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },



    postAdInfo: { route: 'ads/submitform', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    getAllAds: { route: 'ads/get', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    getOneAd: { route: 'ads/getOne', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    getMyAds: { route: 'ads/getMyAds', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    editAd: { route: 'ads/edit', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    removeAd: { route: 'ads/remove', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    inactivateAd: { route: 'ads/inactivate', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },



    submitWish: { route: 'filter/submitWish', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    getWishes: { route: 'filter/getWishes', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },
    getMyWishes: { route: 'filter/getMyWishes', server: 'yc', hashMethod: '', auth: 'token', flexibleParams: true, params: [] },


}


export { siteConfig, siteTheme, languagesInfo, apisList }