import React from "react";
import HttpService from '../../utils/Http.services';
import { siteConfig } from "../../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import TLink from "../../components/TLink";
import StagesManager from "../../components/StagesManager";
import PartnerFirstStep from "../../components/becomePartner/PartnerFirstStep";
import PartnerInfoStep from "../../components/becomePartner/PartnerInfoStep";
import PartnerWelcomeStep from "../../components/becomePartner/PartnerWelcomeStep";
import { chooseWallpaper } from "../../utils/useful";

class BecomePartner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colors: ['d7e2f7', 'eee1ff', 'fedfe7', 'dad6e4', 'f7dbd7', 'fff2e1'],
      loginInfo: {},
      wallpapers: [],
      stage: 0,
      canResend: true,
      errors: [],
      data: {},
      
    }
  }


  componentDidMount() {
   
    this.fetchWallpapers()
  }


  letsStart = (cb) => {
    this.changeStage('info')
  }


  postUserInfo = (cb) => {
    let data = this.state.data
    console.log(data)
    if (data) {
      HttpService.request("postPartnerInfo", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        this.changeStage('welcome')
        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.partnerInfo" })
    }
  }





  setData = (data, cb) => {
    let newData = this.state.data
    for (const [key, value] of Object.entries(data)) {
      newData[key] = value
    }
    this.setState({ data: newData }, () => {
      if (cb) { cb() }
    })
  }



  changeStage = (newStage, cb) => {
    if (this.stageManager) {
      this.stageManager.changeStage(newStage, cb)
    }
  }

  lastStage = () => {
    if (this.stageManager) {
      this.stageManager.lastStage()
    }
  }

  fetchWallpapers() {
    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'become partner' } }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }


  render() {
    return (
      <section className="d-flex w-100 position-relative" style={{ minHeight: '100vh', padding: '0px' }}>

        <section className="row p-0 w-100">

          <section className="d-none d-md-block col-12 col-md-5 col-lg-4" style={{ height: '100vh', position: 'sticky', top: 0, padding: '100px 30px 20px 30px', textAlign: 'center', backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="h-100 w-100" style={{ background: '#00000050', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ position: 'relative' }}>
              <h1 className="text-mega-big white">Chilik.net</h1>
              <h1 className="text-big white">Become a partner</h1>
              <p className="white opacity-7" style={{ fontSize: 17, marginTop: 10, }}>We respects your <TLink pathname="/pages/policy" style={{ textDecoration: 'underline', cursor: 'pointer' }}>privacy</TLink> and we expect you respect the <TLink pathname="/pages/terms" style={{ textDecoration: 'underline', cursor: 'pointer' }}>terms</TLink></p>
            </div>
          </section>

          <section className="col-12 col-md-7 col-lg-8" style={{ paddingTop: 100, opacity: this.state.mainOpacity, transition: 'all 0.5s', flex: '1 1 auto' }}>

            <StagesManager ref={el => this.stageManager = el} >
              <PartnerFirstStep initData={this.state.data} setData={this.setData} letsStart={this.letsStart} stage={0} stageName={'init'} />
              <PartnerInfoStep initData={this.state.data} setData={this.setData} postUserInfo={this.postUserInfo} stage={1} stageName={'info'} />
              <PartnerWelcomeStep initData={this.state.data} stage={2} stageName={'welcome'} />
            </StagesManager>

          </section>
        </section>



      </section>
    )
  }
}


const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BecomePartner);
