import React from 'react'
import { translate, currentLng, converNumberToPersian, priceStandardView } from '../utils/useful'
import { siteTheme, siteConfig } from '../variables/config'
import TLink from './TLink'
import stripHtml from "string-strip-html"
import { imageAddress } from '../utils/useful'
import moment from 'jalali-moment'
import HttpServices from '../utils/Http.services'
import Loader from "react-loader-spinner";

class AdvertisementW extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    componentDidMount() {
        this.fetch()
    }


    fetch() {
        //   console.log("HERE")
        this.setState({ isLoading: true })
        HttpServices.request('getOneAdvertisement', { place: 'mainTop' }, (fetchResult, fetchError) => {
            this.setState({ isLoading: false })
            // console.log(fetchResult)
            if (fetchError) { return }
            if (fetchResult.info) {
                this.setState({ data: fetchResult.info })
            }
        })
    }


    render() {
        let prop = this.state.data//this.dataBuilder(this.props.data, this.props.schema)
        let link = "/pages/advertisements"
        if(prop?.values?.link && prop?.values?.link!="" ){
            link = prop?.values?.link
        }
        // console.log(link)
        // if (this.props.mainKey) {
        //     prop = prop[this.props.mainKey]
        // }
        // console.log(prop)
        if (!this.state.isLoading) {
            return (
                <div className="w-100 d-flex flex-column product-box" style={{ alignItems: 'stretch', flex: 1 }}>
                    <a href={link} target="_blank" className="flexcc w-100 flex-column" style={{ alignItems: 'stretch', flex: 1, backgroundColor: '#fff', borderRadius: 8 }}>
                        <div className="flexcc w-100 flex-column" style={{ flex: 1 }}>
                            <img src={imageAddress(prop?.values?.image, 'advertisementW',window.innerWidth<720?'small':'large')} alt="" style={{ borderRadius: 8, width: '100%', objectFit: 'contain' }} />

                        </div>
                    </a>

                </div>
            )
        } else {
            return (
                <div className={"flexcc"} style={{ flex: 1, backgroundColor: '#fff', borderRadius: 8 }}>
                    
                </div>
            )
        }
    }
}

export default (AdvertisementW);