import React from 'react'
import { translate, checkTextTranslation, imageAddress } from '../utils/useful'
import TLink from './TLink'
import { siteConfig } from '../variables/config'

class Hero extends React.Component {
    state = {
        key: ''
    }


    render() {
        console.log(this.props.source)
        console.log(this.props.type)
        return (
            <div className="row flexcc w-100 p-0 m-0" style={{ background: this.props.background, height: this.props.height ? this.props.height : '100vh', position: 'relative' }}>

                {this.props.type === 'image' && (
                    <div className="position-absolute w-100 h-100" style={{ backgroundOrigin: 'center', top: 0, left: 0, backgroundImage: "url(" + imageAddress(this.props.source) + ")", backgroundPosition: 'center', backgroundSize: 'cover', }}></div>
                )}

                {this.props.type === 'video' && (
                    <video className="position-absolute w-100" width="100%" autoPlay loop muted style={{ maxWidth: '100%', height: '100%', objectFit: "cover" }}>
                        <source src={siteConfig.assetsDomain + 'videos/' + this.props.source} type="video/mp4" />
                    </video>
                )}


                <div className="position-absolute w-100 h-100" style={{ background: '#10152030' }}>
                </div>

                <div className="text-center w-100" style={{ position: 'relative' }}>
                    {/* <h1 className="text-mega-big" style={{ margin: 0, color: '#fff', fontWeight: '600' }}>{translate('Opia Home')}</h1> */}
                    <img className="invert" src="/images/logo1.png" width="300px" />
                    {/* <p className=" white text-normal mt-2">{translate('hero-desc1')}</p>
                    <p className=" white text-normal mt-2">{translate('hero-desc2')}</p> */}

                    {/* <div className="flexcc w-100">
                        <div className="flexcc w-100" style={{ padding: '0px 10px', height: 50, width: '80%', maxWidth: 500, backgroundColor: '#ffffffee', borderRadius: 8, border: '1px solid #fff', backdropFilter: 'blur(10px) saturate(180%)', }}>
                            <div>
                                <button className="p-2 search-border">{checkTextTranslation('{{lang}}photos')}</button>
                            </div>
                            <input className="w-100 h-100" placeholder="Search all recources ..." style={{ padding: '5px 20px', fontSize: 18, backgroundColor: 'transparent', border: 'none' }} />
                            <button className="p-0 mt-1"><img src="https://image.flaticon.com/icons/svg/483/483356.svg" height="30px" alt="" /></button>
                        </div>
                    </div> */}
                    <div className="flexcc mt-4">
                        <TLink to={'/aboutus'} className="mrd-2" style={{ borderRadius: 30, color: '#fff', background: '#ffffff10', border: '#fff 2px solid', padding: '8px 30px', fontSize: 14, fontWeight: 'bold' }}>{translate('ABOUTUS')}</TLink>
                        <TLink to={'/categories'} className="mld-2" style={{ borderRadius: 30, color: '#202020', background: '#ffffff', border: '#fff 1px solid', padding: '8px 30px', fontSize: 14, fontWeight: 'bold' }}>{translate('products')}</TLink>
                    </div>
                    {/* <div className="mt-4 ph-3">
                        <span className=" white text-normal">{translate('Chilik brought revoloution for photography inductry')}</span>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default Hero