import React from 'react';
import AdibHero from '../../components/AdibHero'
import { translate } from '../../utils/useful'
import DataGrid from '../../components/DataGrid'
import Product from '../../components/Product'
import HttpService from '../../utils/Http.services'
import { chooseWallpaper } from '../../utils/useful'
import TLink from '../../components/TLink';

class HomeAdib extends React.Component {

  state = {

    services: [
      { title: 'دندانپزشکی زیبایی', image: 'https://image.flaticon.com/icons/svg/2035/2035135.svg', desc: 'انجام کلیه خدمات زیبایی دندان ازجمله خط لبخند و لبخند هالیویدی' },
      { title: 'ایمپلنت', image: 'https://image.flaticon.com/icons/svg/662/662793.svg', desc: 'انجام تخصصی امپلینت های فوری و یک روزه با استفاده از دستگاه اسکن' },
      { title: 'تکنولوژی لیزر', image: 'https://image.flaticon.com/icons/svg/893/893872.svg', desc: 'استفاده از لیزر برای برش بجای استفاده از  چاقو برای بالا بردن دقت اجرا' },
      { title: 'کد / کم', image: 'https://image.flaticon.com/icons/svg/2035/2035123.svg', desc: 'جراحی با استفاده از فایل قالب دیجیتال یا همان اسکن دهان متقاضی' }
    ],

    portfolio: [
      { image: '/images/adib/1.jpg', desc: 'ونیرکامپوزیت و مرتب کردن دندانها در یک جلسه' },
      { image: '/images/adib/2.jpg', desc: 'بستن فاصله های بین دندانی' },
      { image: 'http://www.dr-adib.com/uploads/posts/2015-05/1432466596_aa8.jpg', desc: 'روکش های تمام سرامیک E - MAX' },
      { image: 'http://www.dr-adib.com/uploads/posts/2015-05/1432466545_aa12.jpg', desc: 'اصلاح خط لبخند (Hollywood Smile)' },
      { image: 'http://www.dr-adib.com/uploads/posts/2015-05/1432466596_aa8.jpg', desc: 'روکش های تمام سرامیک E - MAX' },
      { image: '/images/adib/1.jpg', desc: 'ونیرکامپوزیت و مرتب کردن دندانها در یک جلسه' },
    ]
  }


  componentDidMount() {
    this.fetch()
    this.fetchWallpapers()
  }

  fetch() {
    this.setState({ isLoadingProducts: true })
    HttpService.request("getAllWithData", { applet: 'categories' }, (fetchResult, fetchError) => {
      this.setState({ isLoadingProducts: false })

      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ products: fetchResult.info })
    })
  }



  fetchWallpapers() {

    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'sign in' } }, (fetchResult, fetchError) => {
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }



  render() {

    return (
      <div className={"Page container-fluid p-0"}>
        <AdibHero type={"image"} background={'#f2f4f6'} height="80vh" source={'/images/adib/cover.jpeg'} />
        <section className="container">
          <div className="row mt-4 mb-3 text-center">


            <div className="col-12 mb-3 mt-2">
              <p className="font-bold text-center text-mega-big mb-0">خدمات ما</p>
              <p className=" text-center text-normal mt-0">OUR DENTAL SERVICES</p>
            </div>

            {this.state.services.map((prop, index) => {
              return (
                <div key={index} className="col-6 col-md-3 m-0 mt-3 ">
                  <TLink to={'/services'} className="p-4 text-center h-100" style={{ background: '#fff', borderRadius: 8 }}>
                    <div className="h-100 w-100">
                      <img className="mb-3" src={prop.image} height="120px" />
                      <p className="font-bold text-ultra-big text-center" style={{ fontSize: 20 }}>{prop.title}</p>
                      <div className="text-small mb-2 mt-2">{prop.desc}</div>
                      {/* <p className="text-small" style={{ color: '#007aff' }}>اطلاعات بیشتر</p> */}
                    </div>
                  </TLink>
                </div>
              )
            })}

          </div>



          <div className="col-12 mb-3 mt-4 p-5 text-center" style={{ backgroundColor: '#f2f6f8', borderRadius: 8 }} >
            <p className="font-bold text-center text-ultra-big mb-0">درمان های یک جلسه ای</p>
            <p className=" text-center text-normal mt-2">مفتخریم که به عنوان اولین مرکز ارائه ی ایمپلنت دیجیتال ، آماده ی خدمت به شما هستیم.

            از جمله خدمات تخصصی مطب دکتر ادیب، روش مدرن ایمپلنت گذاری دیجیتال است که به کمک ارتباط با مراکز خاص میسر میشود .

در این روش ، ابتدا دهان شما اسکن شده و CBCT نیز تهیه میشود و تمام اطلاعات به صورت دقیق در کامپیوتر ادغام و بازسازی میشوند و ما احاطه ی کاملی به وضعیت لثه ، استخوان و دندان های شما خواهیم داشت و کیفیت استخوان و ابعاد آن ، موقعیت اعصاب و سینوس ها به دقیق ترین صورت در اختیار ما قرار میگیرند تا کم خطر ترین و بهترین ایمپلنت حاصل شود.</p>

            <div className="mt-3 flexcc">
              <TLink to={'/onesession'}>
                <p className="px-3 py-2" style={{ backgroundColor: '#d2d8e2', borderRadius: 4 }}>اطلاعات بیشتر</p>
              </TLink>
            </div>
          </div>




          <div className="col-12 mb-4 mt-5 flexcc flex-column">
            <span className="font-bold text-center text-ultra-big ">{translate('نمونه کارها')}</span>

            {/* <div className="flexcc w-100">
              <hr className="w-100 mrd-3" />
              <span className="font-bold text-center text-ultra-big mt-4">{translate('opia-products')}</span>
              <hr className="w-100  mld-3" />
            </div> */}
            <div className="text-center text-normal flexcc mt-2 " style={{ maxWidth: 650 }}>SERVICES SAMPLES</div>
          </div>

          <div className="row">
            {this.state.portfolio.map((prop, index) => {
              return (
                <div className="col-12 col-md-4 mb-5 flexcc flex-column" key={index}>
                  <img src={prop.image} style={{ height: 100, maxWidth: '90%', objectFit: 'contain' }} />
                  <TLink className="w-100 mt-2" style={{ display: 'inline-flex', justifyContent: "center", alignItems: 'center' }}>
                    <p className={' text-center text-small'} style={{  padding: '8px 25px', borderRadius: 20 }}>{prop.desc}</p>
                  </TLink>
                </div>
              )
            })}
          </div>

          <div className="col-12" className="flexcc">
            <TLink to={'/portfolio'} >

              <div className="text-center" style={{ backgroundColor: '#000', borderRadius: 30, padding: '10px 30px' }}>
                <p className="white text-small font-bold">نمونه های بیشتر</p>
              </div>
            </TLink>

          </div>

          {/* <div className="col-12 mb-5 mt-4">
            <DataGrid isLoading={this.state.isLoadingProducts} data={this.state.products} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Product} col="col-12 col-md-6 col-lg-4" />
          </div> */}

        </section>


        <section className='w-100 pt-2 pb-3' style={{ backgroundColor: '#000', marginTop: 80 }}>
          <div className="container">

            <div className="col-12 mb-5 mt-5 flexcc flex-column text-center">
              <span className="font-bold text-center text-ultra-big white">دندانپزشکی دیجیتال</span>
              <div className="text-center text-small flexcc mt-2 white" style={{ maxWidth: 650 }}>
                <p>شما تمام لندمارک های انانومیک را ازجمله طول و عرض استخوان تراکم استخوان ، عصب ، عروق خونی ، وضعیت سینوس و غیره را  رصد میکنید و پلان درمانی شما با دقت بسیار بالا طراحی میشود</p>
              </div>

            </div>

            <div className="col-12 mb-5 mt-2">
              <div className="row flexcc" style={{ position: 'relative' }}>

                <video width="100%" controls style={{ maxWidth: 700 }}>
                  <source src="/adib/implant.mp4" type="video/mp4" />
                  Your browser does not support HTML5 video.
              </video>
              </div>
            </div>
          </div>
        </section>


        <section>
          <div className="container pt-5 pb-5">
            <div className="row pt-3 pb-5">
              <div className="col-12 col-md-6 flexcc flex-column">
                <div className="mt-3">
                  <span className="text-ultra-big font-bold ">درباره دکتر دکتر محمود ادیب</span>

                  <div className="mt-2">
                    <p className="text-small">دكتر اديب جراح دندانپزشك ، ايمپلنتولوژيست ، ايمپلنت فوري ،All on 4 ، درمان لقي دست دندان ، دندانپزشكي زيبايي ، لبخند هاليوودي ، مرتب كردن دندان ها بدون ارتودنسي ، كامپوزيت ونير لامينيت ونير ، روكش هاي زيركونيا ، فول سرام ، سفيد كردن دندان ها ، بليچينگ ، (Bleaching) ، روكش هاي IPS ، ارتودنسي ، ارتودنسي نامرئي ، </p>
                    <p className="mt-3 font-bold mb-2">دارای گواهینامه رسمی :</p>
                    <p className="text-small">-    ایمپلنت از آکادمی ایمپیلنتولوژی فرانسه</p>
                    <p className="text-small">-    پیوند استخوان (Bone Graft)   از دانشگاه NYU آمریکا (نیویورک)</p>
                    <p className="text-small">-    عضو کنگره بین المللی ایمپلنتولوژی ICOI</p>
                  </div>

                </div>
              </div>

              <div className="col-12 col-md-6 position-relative" style={{ height: 300 }}>





                <figure style={{ position: 'absolute', top: '0%', right: '45%', width: '30%' }}>
                  <img src="/images/adib/cover2.jpeg" width="100%" alt="" style={{ borderRadius: 8 }} />
                </figure>
                <figure style={{ position: 'absolute', top: '50%', right: '30%', width: '40%', }}>
                  <img src="/images/adib/cover1.jpeg" width="180px" alt="" style={{ borderRadius: 8 }} />
                </figure>
                <figure style={{ position: 'absolute', top: '15%', right: '15%', width: '20%' }}>
                  <img src="/images/adib/cover3.jpeg" width="180px" alt="" style={{ borderRadius: 8 }} />
                </figure>


              </div>
            </div>
          </div>
        </section>




      </div>

    );
  }
}

export default HomeAdib;
