import React from 'react';
import { translate, phoneStandardView, checkTextTranslation } from '../../utils/useful'
import { siteConfig } from '../../variables/config';
import TLink from '../../components/TLink'
import FormViewer from '../../components/FormViewer'
import LoaderButton from '../../components/LoaderButton';

class AdibAboutus extends React.Component {


  state = {
    stage: 0,
    headers: [

      { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
      { key: 'phone', type: 'TextInput', information: { label: '{{lang}}phone', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertPhone', required: true } },
      { key: 'message', type: 'TextAreaInput', information: { label: '{{lang}}Message', placeholder: '{{lang}}insertMessage', rows: 4, required: true } },

      // { type: 'TextInput', key: 'name', information: { title: 'Name', required: true } },
      // { title: 'Phone', type: 'TextInput', key: 'phone', },
      // { title: 'Email', type: 'TextInput', key: 'email', },
      // { title: 'Message', type: 'TextAreaInput', key: 'body', settings: { required: true } },
      // { title: 'Section', key: 'section', type: 'select', options: { type: 'local', items: [{ title: "Sale", value: 'sale' }, { title: 'Managment', value: 'managment' }] }, settings: { required: true }, showMain: true },
    ],
    info: [
      { title: 'سریع و بدون انتظار', image: 'https://image.flaticon.com/icons/svg/1466/1466989.svg', desc: 'هیچ فردی دوست ندارد روزها بدون دندان منتظر بماند تا جایگزین دندانش در لابراتوار آماده شود. در درمان ایمپلنت فوری کشیدن دندان بیمار و آسیب‌دیده و  جایگزینی آن در یک روز انجام می‌شود. به این ترتیب دندان مصنوعی متصل به ایمپلنت دائمی در یک روز آماده می‌شود' },
      { title: 'دوام', image: 'https://image.flaticon.com/icons/svg/1467/1467011.svg', desc: 'رخلاف دندان مصنوعی متحرک که مرتب به ترمیم یا جایگزینی نیاز پیدا می‌کند، پروتزهای با پایه ایمپلنت دائمی هستند و به ندرت نیاز به ترمیم می‌یابند. اگر از ایمپلنت مطابق دستورات دندانپزشک استفاده شود، انتظار می‌رود که تا پایان عمر دوام بیاورد' },
      { title: 'حس طبیعی', image: 'https://image.flaticon.com/icons/svg/1466/1466985.svg', desc: 'ایمپلنت کاشته شده در استخوان فک تا حد زیادی شبیه دندان طبیعی است و پس از کاشت و جوش خوردن به استخوان عملکرد و حسی مانند دندان خود بیمار دارد' },
      { title: 'حفظ حجم استخوان فک', image: 'https://image.flaticon.com/icons/svg/1467/1467000.svg', desc: 'ایمپلنت از تحلیل رفتن استخوان فک جلوگیری می‌کند و در واقع باعث می‌شود که استخوان در اطراف آن تشکیل شود. به این ترتیب یکپارچگی، حجم و کیفیت استخوان‌های باقیمانده فک حفظ می‌شود' },
      { title: 'بهداشت دهان و دندان', image: 'https://image.flaticon.com/icons/svg/1467/1467020.svg', desc: 'دندان‌های با پایه ایمپلنت ثابت هستند و نیازی به درآوردن آنها نیست. از این دندان‌های مصنوعی با پایه ایمپلنت باید مانند دندان‌های طبیعی مراقبت کرد. کافی است آنها را به طور منظم مسواک بزنید و نخ یکشید تا با هیچ مشکلی مواجه نشوید' },
      { title: 'لذت بردن از غذاهای مورد علاقه', image: 'https://image.flaticon.com/icons/svg/1466/1466983.svg', desc: 'می‌توانید با خیال راحت هر غذایی که دوست دارید بخورید، بدون آن که نگران درآمدن دندان مصنوعی باشید' }

    ],


    services: [
      { title: 'دندانپزشکی زیبایی', image: 'https://image.flaticon.com/icons/svg/2035/2035135.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد', slug: 'beauty' },
      { title: 'ایمپلنت', image: 'https://image.flaticon.com/icons/svg/662/662793.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد' },
      { title: 'تکنولوژی لیزر', image: 'https://image.flaticon.com/icons/svg/893/893872.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد' },
      { title: 'کد / کم', image: 'https://image.flaticon.com/icons/svg/2035/2035123.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد' }
    ],

    fastAccess: [{ title: '{{lang}}faq', link: '/faq', image: '/images/faq.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }, { title: '{{lang}}terms', link: '/pages/terms', image: '/images/blog.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }, { title: '{{lang}}products', link: '/categories', image: '/images/policies.jpeg', desc: 'This would be the description for this section. when you click on this section it would refer to the related page' }],

  }

  sendContact() {

  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8' }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h1 className=" text-ultra-big white">دندانپزشکی دکتر ادیب</h1>
          <h1 className=" text-mega-big white">درباره ما</h1>
        </div>




        <div style={{ paddingTop: 10, paddingTop: 50, paddingBottom: 30, backgroundColor: '#fff' }}>
          <div className="container mb-5 mediumiransansfont d-flex flex-column flex-md-row text-center text-md-right px-5"  >

            <div>
              <img src="/images/adib/profile.jpg" style={{ height: 200, width: 200, borderRadius: 100 }} />
            </div>

            <div className="mr-0 mr-md-5 mt-4 mt-md-0 ">

              <h2 className=' mb-1 text-ultra-big' style={{ marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', fontWeight: 'bold' }}>درباره دکتر ادیب</h2>

              <p>
                دکتر محمود ادیب دارای گواهینامه رسمی ایمپلنت از آکادمی ایمپیلنتولوژی فرانسه و گواهینامه پیوند استخوان (Bone Graft) از دانشگاه NYU آمریکا (نیویورک) میباشند.
                📍جناب آقای دکتر محمود ادیب دندانپزشک متخصص و با تجربه ، تمامی انتظارات شما را از یک دندانپزشک ماهر برآورده میکنند.
                📍اگر خواستار ایمپلنت های فوری هستید و وقت زیادی برای رفتن به دندانپزشکی ندارید و یا اگر از بی نظمی دندانهایتان دل خوشی ندارید و از ارتودنسی خوشتان نمی آید ، تنها یک راه دارید،کلینیک تخصصی دندانپزشکی دکتر محمود ادیب را انتخاب کنید.
                 </p>
            </div>



          </div>




        </div>



        <section>
          <div className="container pt-5 px-5">
            <div className="row ">
              <div className="col-12 col-md-6 flexcc flex-column">
                <div className="">
                  <span className="text-ultra-big font-bold mb-3">تخصصها</span>

                  <div className="">
                    <p className="text-small">دكتر اديب جراح دندانپزشك ، ايمپلنتولوژيست ، ايمپلنت فوري ،All on 4 ، درمان لقي دست دندان ، دندانپزشكي زيبايي ، لبخند هاليوودي ، مرتب كردن دندان ها بدون ارتودنسي ، كامپوزيت ونير لامينيت ونير ، روكش هاي زيركونيا ، فول سرام ، سفيد كردن دندان ها ، بليچينگ ، (Bleaching) ، روكش هاي IPS ، ارتودنسي ، ارتودنسي نامرئي ، </p>
                    <p className="mt-3 font-bold mb-2">دارای گواهینامه رسمی :</p>
                    <p className="text-small">-    ایمپلنت از آکادمی ایمپیلنتولوژی فرانسه</p>
                    <p className="text-small">-    پیوند استخوان (Bone Graft)   از دانشگاه NYU آمریکا (نیویورک)</p>
                    <p className="text-small">-    عضو کنگره بین المللی ایمپلنتولوژی ICOI</p>
                  </div>

                </div>
              </div>

              <div className="col-12 col-md-6 position-relative mt-md-3 flexcc">


                <figure style={{ width: '100%', maxWidth: 400 }}>
                  <img src="/images/adib/certificate.jpg" width="100%" alt="" style={{ borderRadius: 8 }} />
                </figure>



              </div>
            </div>
          </div>
        </section>



        <div className="container mt-5" ref="contact">



          <div className="row pt-4 d-flex pb-4 mb-5 mt-5">


            <div className="col-12 col-lg-6 mb-4">
              <div className="w-100 h-100 py-3 px-4 px-md-5" style={{ backgroundColor: '#fff', borderRadius: 8, marginBottom: 30 }}>

                <p className="text-center font-weight-bold mt-4 text-ultra-big mb-4">خدمات ما</p>
                {/* <p className="text-center  mt-1 mb-5" style={{ fontSize: 15 }}>Please let us know your enquiry</p> */}


                {this.state.services.map((prop, index) => {
                  return (
                    <TLink to={'/services'} className="mt-4">
                      <div className="d-flex mb-4">
                        <div className="ml-4">
                          <img src={prop.image} style={{ width: 70 }} />
                        </div>
                        <div>
                          <p className={'text-semibig font-bold'}>{prop.title}</p>
                          <p className={'text-small mt-1'}>{prop.desc}</p>

                        </div>
                      </div>
                    </TLink>
                  )
                })}

              </div>
            </div>

            <div className="col-12 col-lg-6 mb-4">
              <div className="w-100 h-100  py-3 px-4 px-md-5" style={{ backgroundColor: '#fff', borderRadius: 8, marginBottom: 30 }}>
                <p className="text-center font-weight-bold mt-4 text-ultra-big mb-4">تماس با ما</p>

                <div>
                  {/* <div className="d-flex align-items-center "><img src="https://image.flaticon.com/icons/svg/2399/2399925.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">Company: </span><span>{siteConfig.siteName}</span></p></div> */}
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/636/636199.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">آدرس: </span><span>{siteConfig.addressFa}</span></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/684/684912.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">شماره تماس: </span><span>{phoneStandardView(siteConfig.phone)}</span></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/726/726623.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">شماره همراه: </span><a href="mailto://info@antbuildz.com">09333400757</a></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/2111/2111463.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">اینستاگرام: </span><a href={'https://www.instagram.com/dr.adib.dental/'}>@dr.adib.dental</a></p></div>
                  {/* <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/1409/1409943.svg" width="35px" /><p className="mld-4" style={{  color: '#456' }}><span className="font-weight-bold">Facebook: </span><a href={siteConfig.linkedin}>Follow us</a></p></div> */}


                  <iframe className="mt-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1619.95425227069!2d51.40152065811688!3d35.70386909504726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e01d46a36126f%3A0x5de8ab8007d3deb0!2z2K_Zhtiv2KfZhtm-2LLYtNqp24wg2K_aqdiq2LEg2YXYrdmF2YjYryDYp9iv24zYqA!5e0!3m2!1sen!2sus!4v1595321203833!5m2!1sen!2sus" style={{ border: 'none' }} width="100%" height="250" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

                </div>
              </div>
            </div>


          </div>
        </div>


        {/* 
        <div style={{ marginTop: 0, paddingBottom: 10, }}>
          <div className="container mb-5 mediumiransansfont"  >
            <div className={' pr-0 mb-1 mt-5 d-flex align-items-center justify-content-center'} >
              <div className="text-center">
                <h2 className=' mb-1 ' style={{ fontSize: 26, marginTop: 0, whiteSpace: 'nowrap', color: '#13191d', marginLeft: 20, marginRight: 20, fontWeight: 'bold' }}>{translate("learn-more-about-us")}</h2>
              </div>
            </div>

            <div className={'row text-center mt-3 ml-1 mr-1'}>
              {this.state.fastAccess.map((prop, index) => {
                return (
                  <TLink to={prop.link} className="col-12  col-md-4 col-lg-4 mt-4">
                    <div>
                      <img src={prop.image} style={{ boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', width: '100%', marginBottom: 5, borderRadius: 8 }} />
                      <p className={'text-big font-bold'}>{checkTextTranslation(prop.title)}</p>
                    </div>
                  </TLink>
                )
              })}

            </div>

          </div>
        </div> */}





      </section>
    );
  }
}

export default AdibAboutus;
