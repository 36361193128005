import React from 'react';
import { translate, imageAddress } from '../../utils/useful'
import Section from '../../components/Section'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import TLink from '../../components/TLink';
import DataGridEH from '../../components/DataGridEH'
import Resources from '../../components/Resource'
import Modal from '../../components/Modal'
import HttpService from '../../utils/Http.services';
import { siteConfig } from '../../variables/config'
import Loader from 'react-loader-spinner'


class Profile extends React.Component {

  state = {
    data: { _id: 1 },
    resources: [],
    currentImageIndex: 0,
    modalOpacity: 0,
    isLoadingPage: true
  }



  componentDidMount() {
    // this.paymentModal.showModal()
    this.fetch()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.location != newProps.location) {
      this.setState({ isLoadingPage: true, userNotFound: false, data: {}, resources: [] }, () => {
        this.fetch()
      })
    }
  }


  fetch() {
    let search = this.props.location.search
    let params = new URLSearchParams(search)
    let id = params.get('id')

    this.setState({ isLoadingPage: true })
    HttpService.request("getOneUser", { id: id }, (fetchResult, fetchError) => {
      this.setState({ isLoadingPage: false })
      if (fetchError) { this.setState({ userNotFound: true }); return }
      console.log(fetchResult.info)
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info }, () => {
          this.fetchData()
        })
      }
    })
  }


  fetchData() {
    this.setState({ isLoadingData: true })
    HttpService.request("getAllWithData", { filter: { applet: 'resources', creator: this.state.data._id, 'values.status': 'Active'  } }, (fetchResult, fetchError) => {
      this.setState({ isLoadingData: false })
      if (fetchError) { return }
      this.setState({ resources: fetchResult.info })
    })
  }


  render() {

    if (!this.state.isLoadingPage) {
      if (!this.state.userNotFound) {
        return (
          <Section>
            <div className="container mb-5">
              <div style={{ padding: '90px 0px 30px 0px' }}>

                <div className="row w-100 m-0 p-0">
                  <div className="col-12 col-lg-7 ">

                    <div className="row d-flex">
                      <div className="p-0 flexcc">
                        <img src={imageAddress(this.state.data?.image, "profile")} style={{ width: 80, height: 80, borderRadius: '50%', objectFit: 'cover' }} />
                        <div className="mld-4">
                          <h1 className="text-ultra-big">{this.state.data?.name}</h1>
                          <p className="">{this.state.data?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>



              <div className="row w-100 ml-0 mr-0 ">
                <div className="col-12 p-0 mb-5">
                  <h1 className="mb-4 mt-0">User Resources</h1>

                  <DataGridEH isLoading={this.state.isLoadingData} data={this.state.resources} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Resources} col="col-12 col-md-6 col-lg-4" />


                </div>
              </div>

            </div>



          </Section>
        )
      } else {
        return (
          <div className="flexcc flex-column w-100 text-center" style={{ paddingTop: 90 }}>
            <h1 className=" text-mega-big ">404</h1>
            <h1 className=" text-ultra-big mb-2">User Not Found</h1>
            <TLink to={'/'}>Go to Home</TLink>
          </div>
        )
      }
    } else {
      return (
        <div className="flexcc w-100" style={{ paddingTop: 90 }}>
          <Loader
            type={this.props.type ?? "Oval"}
            color={this.props.color ?? "#202020"}
            height={this.props.width ?? 50}
            width={this.props.height ?? 50}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({ card: state.card, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
