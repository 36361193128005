import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { translate } from '../../utils/useful'

class AddAddInfoStep extends React.Component {

    state = {
        errors: {},
        // initData:{phone:'0098'},
        formHeaders: [
            { key: 'images', type: 'ImageInput', information: { label: 'تصاویر', single: false, hint: 'حداکثر ۵ تصویر - حداکثر حجم هر تصویر ۳ مگابایت', required: false, maxLength: 5, maxSize: 3000, cropper: true, ratio: '1:1' } },

            { key: 'title', type: 'TextInput', information: { label: 'عنوان', maxLength: "60", icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: 'عنوان را وارد نمایید', required: true } },
            { key: 'description', type: 'TextAreaInput', information: { label: 'توضیحات', rows: 5, maxLength: "500", placeholder: 'توضیحات را وارد نمایید', required: true } },

            { key: 'phone', type: 'PhoneInput', information: { label: '{{lang}}phone', minLength: 4, placeholder: '{{lang}}phone', required: true } },

            // { key: 'categories', type: 'TextInput', information: { label: 'دسته بندی', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: 'انتخاب دسته بندی', required: true } },
            { key: 'categories', type: 'MultiLevelInput', information: { isSearchable: true, label: 'دسته بندی', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', address: 'getAllWithData', filter: JSON.stringify({ applet: 'categories' }), fields: { title: 'values.name', value: '_id' }, type: 'api', root: '', reference: 'values.parent', placeholder: 'انتخاب دسته بندی', required: true } },
            { key: 'city', type: 'MultiLevelInput', information: { label: 'شهر', isSearchable: true, icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', address: 'getAllWithData', filter: JSON.stringify({ applet: 'cities' }), sort: "values.name", fields: { title: 'values.name', value: '_id', parent: 'values.parent' }, type: 'api', root: '', reference: 'values.parent', placeholder: 'انتخاب شهر', required: true } },

            // { key: 'city', type: 'SelectWithParentInput', information: { label: 'شهر', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', address: 'getAllWithData', filter: { applet: 'cities' },isSearchable:true, sort: "values.name", fields: { title: 'values.name', value: '_id',parent:'values.parent' }, type: 'api', placeholder: 'انتخاب شهر', required: true } },
            { key: 'address', type: 'TextAreaInput', information: { label: 'آدرس', placeholder: 'آدرس را وارد کنید', required: false } },

            // { key: 'location', type: 'MapInput', information: { label: 'موقعیت', placeholder: 'موقعیت را وارد کنید', required: false } },
            { key: 'price', type: 'PriceInput', information: { label: 'قیمت (تومان)', hint: 'برای قیمت توافقی این بخش را پر نکنید', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: 'قیمت کالا', required: false } },

            { key: 'used', type: 'SwitchInput', information: { label: 'کالا دست دوم است', placeholder: 'قیمت کالا', required: false }, singleLineLabel: true },

            // { key: 'title', type: 'TextInput', information: { label: 'عنوان', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: 'عنوان را وارد نمایید', required: true } },

        ]
    }




    postAdInfo = () => {
        let data = this.form.getForm()
        if (data && data.socialNumber && data.socialNumber.length != 10) {
            let err = "{{lang}}errors.socialNumber"
            this.setState({ errors: err })
            data = null
        }
        if (data) {
            this.setState({ isLoading: true, errors: {} })
            this.props.setData(data, () => {
                this.props.postAdInfo((result, err) => {
                    if (err) { this.setState({ errors: err }) }
                    this.setState({ isLoading: false })
                })
            })
        }
    }


    render() {
        return (

            <div className="flexcc flex-column">
                {/* <h2 className="mt-0 mb-0 text-center" style={{ fontSize: 27 }}>{translate('information')}</h2> */}

                <div className="w-100" style={{ maxWidth: 400 }}>
                    <p className=" mt-0 text-center" style={{ fontSize: 15 }}>اطلاعات آگهی را وارد نمایید</p>
                    <p className="mb-5 text-center" style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>از درستی اطلاعات ورودی اطمینان حاصل نمایید. در صورت مغایرت اطلاعات آگهی شما حذف خواهد شد</p>

                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postAdInfo}
                            isLoading={this.state.isLoading}
                            text={translate('submit')}
                            type={"Oval"}
                            className="mt-4 mb-2"
                            buttonStyle={{ outline: 'none', backgroundColor: 'rgb(253, 121, 45)', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                    </div>



                    {/* <p className="text-center" style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>With becoming partner you are accepting our partner <TLink pathname="/pages/terms" style={{ color: '#007aff' }}>terms</TLink> and <TLink pathname="/pages/policies" style={{ color: '#007aff' }}>Policies</TLink></p> */}

                    <div className="w-100 text-center mb-5">
                        <button className="mb-1" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>

                </div>
            </div>

        )
    }
}

export default AddAddInfoStep;