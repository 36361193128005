import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import TLink from '../TLink'
import { translate } from '../../utils/useful'

class PartnerFirstStep extends React.Component {
    state = {
        key: '',
        selectedCountry: {},
        data: {},
        isLoading: false,
        formHeaders: []
    }



    letsStart = () => {
        this.props.letsStart()
    }


    render() {
        return (
            <div className="flexcc flex-column">

                <img src="https://image.flaticon.com/icons/svg/3062/3062602.svg" width="100px" />
        <h2 className="mt-3 mb-3" style={{ fontSize: 27 }}>{translate('send-new-resource')}</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

        <p className="text-center" style={{ fontSize: 15, margin: '0px 20px 20px 20px' }}>{translate('send-new-resource-desc')}</p>

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.letsStart}
                            isLoading={this.state.isLoading}
                            text={translate('letsStart')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        <TLink to={'/pages/terms'}><p style={{ fontSize: 15, margin: '10px 20px 20px 20px' }}>{translate('resource-accept-terms')}</p></TLink>
                        {/* <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>This website uses cookies to deliver the best possible user experience. With using the website you are accepting it</p> */}
                    </div>

                   
                </div>
            </div>
        )
    }
}

export default PartnerFirstStep;