import React from 'react';
import { translate, phoneStandardView, checkTextTranslation } from '../../utils/useful'
import { siteConfig } from '../../variables/config';
import TLink from '../../components/TLink'
import FormViewer from '../../components/FormViewer'
import LoaderButton from '../../components/LoaderButton';

class Samples extends React.Component {


  state = {
    stage: 0,
    portfolio: [
      { image: '/images/adib/1.jpg', desc: 'ونیرکامپوزیت و مرتب کردن دندانها در یک جلسه' },
      { image: '/images/adib/2.jpg', desc: 'بستن فاصله های بین دندانی' },
      { image: 'http://www.dr-adib.com/uploads/posts/2015-05/1432466596_aa8.jpg', desc: 'روکش های تمام سرامیک E - MAX' },
      { image: 'http://www.dr-adib.com/uploads/posts/2015-05/1432466545_aa12.jpg', desc: 'اصلاح خط لبخند (Hollywood Smile)' },
      { image: 'http://www.dr-adib.com/uploads/posts/2015-05/1432466596_aa8.jpg', desc: 'روکش های تمام سرامیک E - MAX' },
      { image: '/images/adib/1.jpg', desc: 'ونیرکامپوزیت و مرتب کردن دندانها در یک جلسه' },
    ],

    services: [
      { title: 'دندانپزشکی زیبایی', image: 'https://image.flaticon.com/icons/svg/2035/2035135.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد', slug: 'beauty' },
      { title: 'ایمپلنت', image: 'https://image.flaticon.com/icons/svg/662/662793.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد' },
      { title: 'تکنولوژی لیزر', image: 'https://image.flaticon.com/icons/svg/893/893872.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد' },
      { title: 'کد / کم', image: 'https://image.flaticon.com/icons/svg/2035/2035123.svg', desc: 'توضیحات این نوع از خدمات در این بخش آورده میشود که میتواند توضیحاتی در حد دو یا سه خط باشد' }
    ],
  }

  sendContact() {

  }

  render() {

    return (
      <section style={{ paddingBottom: 80, backgroundColor: '#f2f6f8' }}>

        <div className="text-center" style={{ padding: '80px 20px 40px 20px', backgroundColor: '#000' }}>
          <h1 className=" text-ultra-big white">دندانپزشکی دکتر ادیب</h1>
          <h1 className=" text-mega-big white">خدمات ارائه شده</h1>
        </div>

        <div style={{ backgroundColor: '#fff' }}>
          <div className="container pt-5" >
            <div className="row mt-4">
              {this.state.portfolio.map((prop, index) => {
                return (
                  <div className="col-12 col-md-6 mb-5 pb-5 flexcc flex-column" key={index}>
                    <img src={prop.image} style={{ maxWidth: '90%', objectFit: 'contain' }} />
                    <TLink className="w-100 mt-2" style={{ display: 'inline-flex', justifyContent: "center", alignItems: 'center' }}>
                      <p className={' text-center text-small'} style={{ padding: '8px 25px', borderRadius: 20 }}>{prop.desc}</p>
                    </TLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>



        <div className="container mt-5" ref="contact">

          <div className="row pt-4 d-flex pb-4 mb-5 mt-5">


            <div className="col-12 col-lg-6 mb-4">
              <div className="w-100 h-100  py-3 px-4 px-md-5" style={{ backgroundColor: '#fff',  borderRadius: 8, marginBottom: 30 }}>

                <p className="text-center font-weight-bold mt-4 text-ultra-big mb-4">خدمات ما</p>
                {/* <p className="text-center  mt-1 mb-5" style={{ fontSize: 15 }}>Please let us know your enquiry</p> */}


                {this.state.services.map((prop, index) => {
                  return (
                    <TLink to={prop.link} className="mt-4">
                      <div className="d-flex mb-4">
                        <div className="ml-4">
                          <img src={prop.image} style={{ width: 70 }} />
                        </div>
                        <div>
                          <p className={'text-semibig font-bold'}>{prop.title}</p>
                          <p className={'text-small mt-1'}>{prop.desc}</p>

                        </div>
                      </div>
                    </TLink>
                  )
                })}

              </div>
            </div>

            <div className="col-12 col-lg-6 mb-4">
              <div className="w-100 h-100  py-3 px-4 px-md-5" style={{ backgroundColor: '#fff',  borderRadius: 8, marginBottom: 30 }}>
                <p className="text-center font-weight-bold mt-4 text-ultra-big mb-4">تماس با ما</p>

                <div>
                  {/* <div className="d-flex align-items-center "><img src="https://image.flaticon.com/icons/svg/2399/2399925.svg" width="35px" /><p className="mld-4" style={{ lineHeight: 1, color: '#456' }}><span className="font-weight-bold">Company: </span><span>{siteConfig.siteName}</span></p></div> */}
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/636/636199.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">آدرس: </span><span>{siteConfig.addressFa}</span></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/684/684912.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">شماره تماس: </span><span>{phoneStandardView(siteConfig.phone)}</span></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/726/726623.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">شماره همراه: </span><a href="mailto://info@antbuildz.com">09333400757</a></p></div>
                  <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/2111/2111463.svg" width="35px" /><p className="mld-4" style={{ color: '#456' }}><span className="font-weight-bold">اینستاگرام: </span><a href={'https://www.instagram.com/dr.adib.dental/'}>@dr.adib.dental</a></p></div>
                  {/* <div className="d-flex align-items-center mt-4"><img src="https://image.flaticon.com/icons/svg/1409/1409943.svg" width="35px" /><p className="mld-4" style={{  color: '#456' }}><span className="font-weight-bold">Facebook: </span><a href={siteConfig.linkedin}>Follow us</a></p></div> */}


                  <iframe className="mt-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1619.95425227069!2d51.40152065811688!3d35.70386909504726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e01d46a36126f%3A0x5de8ab8007d3deb0!2z2K_Zhtiv2KfZhtm-2LLYtNqp24wg2K_aqdiq2LEg2YXYrdmF2YjYryDYp9iv24zYqA!5e0!3m2!1sen!2sus!4v1595321203833!5m2!1sen!2sus" style={{ border: 'none' }} width="100%" height="250" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

                </div>
              </div>
            </div>


          </div>
        </div>






      </section>
    );
  }
}

export default Samples;
