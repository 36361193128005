import { combineReducers } from 'redux';
import settingsReducer from './reducers/settings.reducer';
import userReducer from './reducers/user.reducer';
import cartReducer from './reducers/cart.reducer';
import resourceReducer from './reducers/resource.reducer';

// import shoppingCartReducer from './reducers/shppoingCart.reducer.js';

export default combineReducers({
    settings: settingsReducer,
    user: userReducer,
    cart: cartReducer,
    resource: resourceReducer

    // shoppingCart: shoppingCartReducer
});