import React from 'react'
import LoaderButton from '../LoaderButton'
// import FormViewer from '../FormViewer'
import moment from 'moment';
import TLink from '../TLink'
import { siteConfig } from '../../variables/config';
import { imageAddress, translate } from '../../utils/useful';

class GuaranteeInfoStep extends React.Component {
    state = {
        data: {},
        isLoading: false,
    }


    goToSubmitPage = () => {
        this.props.goToSubmitPage()
    }


    render() {
        let data = this.props.initData

        return (
            <div className="flexcc flex-column">

                <div className="w-100" style={{ maxWidth: 400 }}>
                    <div className="flexcc flex-column">
                        <img src={imageAddress(data.product?.values?.images[0], 'product')} style={{ borderRadius: 4, width: 120, height: 120, objectFit: 'contain' }} />
                        <p className=" font-bold" style={{ marginTop: 15 }}>{data.product?.values?.name}</p>
                    </div>


                    {data.status == "Active" && (
                        <div className="text-center mt-4">

                            <div style={{ backgroundColor: '#007aff10', borderRadius: 4, padding: '20px 10px', marginTop: 20 }}>
                                <p className="text-center" style={{ fontSize: 15 }}>This guarantee code is submited in {moment(data?.activationDate).format('YYYY MMMM DD').toString()}</p>
                            </div>

                            <div className="mt-4">
                                <TLink pathname={'/'} style={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}>
                                    Home Page
                                </TLink>
                            </div>
                        </div>
                    )}


                    {data.status == "Not Active" && (
                        <div className="text-center mt-4">

                    <p className="text-center" style={{ fontSize: 15 }}>{translate('guarantee-ask-to-confirm')}</p>

                            <div className="mt-4">
                                <LoaderButton
                                    onClick={this.goToSubmitPage}
                                    isLoading={this.state.isLoading}
                                    text={translate('confirm-product')}
                                    type={"Oval"}
                                    className="mt-4 mb-4"
                                    buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                                    width={40}
                                    height={40}
                                    color={'#202020'}
                                />
                            </div>
                        </div>
                    )}


                    {/* <p className="mt-4 mb-2 text-center" style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>More info about the guarantee and customer service would be shown here</p> */}

                    <div className="w-100 text-center mb-5">
                    <button className="mb-1" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default GuaranteeInfoStep;