import React from 'react';
import Hero from '../../components/Hero'
import { translate } from '../../utils/useful'
import DataGrid from '../../components/DataGrid'
import Estate from '../../components/addestate/Estate'
import HttpService from '../../utils/Http.services'
import { chooseWallpaper } from '../../utils/useful'
import FastHelmet from '../../components/FastHelmet';
import TLink from '../../components/TLink';

class RaeesOpia extends React.Component {

  state = {
    products: []
    // products: [
    //   {
    //     name: "Test producs", description: 'This is the description of product', price: 100000, image: 'https://dkstatics-public.digikala.com/digikala-products/112145268.jpg?x-oss-process=image/resize,m_lfit,h_600,w_600/quality,q_80',
    //     lng: {
    //       en: { name: "Test producs", description: 'This is the description of product', price: "90" },
    //       fa: { name: "محصول تست", description: 'توضیحات محصول در اینجا آورده میشد', price: "100000" }
    //     },
    //     portals: ["normal", "plus"]
    //   },

    //   {
    //     name: "Test producs", description: 'This is the description of product', price: 100000, image: '/images/products/2-1.png',
    //     lng: {
    //       en: { name: "Test producs", description: 'This is the description of product', price: "90" },
    //       fa: { name: "محصول تست", description: 'توضیحات محصول در اینجا آورده میشد', price: "100000" }
    //     },
    //     portals: ["plus", "luxury", "custom"]

    //   },

    //   {
    //     name: "Test producs", description: 'This is the description of product', price: 100000, image: 'https://dkstatics-public.digikala.com/digikala-products/119595512.jpg?x-oss-process=image/resize,m_lfit,h_600,w_600/quality,q_90',
    //     lng: {
    //       en: { name: "Test producs", description: 'This is the description of product', price: "4000" },
    //       fa: { name: "محصول تست", description: 'توضیحات محصول در اینجا آورده میشد', price: "100000" }
    //     },
    //     portals: ["normal", "plus", "luxury"]

    //   }

    // ]
  }


  componentDidMount() {
    this.fetch()
    this.fetchWallpapers()
  }

  fetch() {
    this.setState({ isLoadingProducts: true })
    HttpService.request("getAllWithData", { applet: 'estates' }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      // console.log(fetchError)
      this.setState({ isLoadingProducts: false })

      if (fetchError) { return }
      // console.log(fetchResult.info)
      this.setState({ estates: fetchResult.info })
    })
  }



  fetchWallpapers() {

    HttpService.request('getAllWithData', { applet: 'wallpapers', filter: { 'values.page': 'خانه' } }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ wallpapers: fetchResult.info })
      }
    })
  }



  render() {

    return (
      <div className={"Page container-fluid p-0"}>

        <FastHelmet title={'خانه'} description={'| Your Dreams Are Our Business'} />


        {/* <Hero type={"image"} background={'#f2f4f6'} height="100vh" source={chooseWallpaper(this.state.wallpapers)} /> */}


        <div className="row flexcc w-100 p-0 m-0" style={{ background: this.props.background, height: this.props.height ? this.props.height : '100vh', position: 'relative' }}>

          {/* {this.props.type === 'image' && ( */}
          <div className="position-absolute w-100 h-100" style={{ backgroundOrigin: 'center', top: 0, left: 0, backgroundImage: "url(" + chooseWallpaper(this.state.wallpapers) + ")", backgroundPosition: 'center', backgroundSize: 'cover', }}></div>
          {/* )} */}

          <div className="position-absolute w-100 h-100" style={{ background: '#10152050' }}>
          </div>

          <div className="text-center w-100" style={{ position: 'relative' }}>
            {/* <h1 className="text-mega-big" style={{ margin: 0, color: '#fff', fontWeight: '600' }}>{translate('Opia Home')}</h1> */}
            <img className="invert" src="/images/raeesMelk.png" width="300px" />

            <div className="flexcc mt-4">
              <TLink to={'/aboutus'} className="mrd-2" style={{ borderRadius: 30, color: '#fff', background: '#ffffff10', border: '#fff 2px solid', padding: '8px 30px', fontSize: 14 }}>{translate('ABOUTUS')}</TLink>
              <TLink to={'/addEstate'} className="mld-2" style={{ borderRadius: 30, color: '#202020', background: '#ffffff', border: '#fff 1px solid', padding: '8px 30px', fontSize: 14 }}>{translate('addEstate')}</TLink>
            </div>

          </div>
        </div>





        <section className="container-fluid p-0" >
          <div className="w-100 pb-2" style={{ backgroundColor: '#f2f6f8' }}>
            <div className="container">
              <div className="row mt-0 mb-3 text-center">

                <div className="col-12 mb-2 mt-4 pt-3">
                  <span className="font-bold text-center text-mega-big ">خدمات ما</span>
                  {/* <p className=" text-normal mt-2">{translate('hero-desc1')}</p>
                  <p className=" text-normal mt-2 mb-4">{translate('hero-desc2')}</p> */}
                </div>

                <div className="col-6 col-md-3 m-0 mt-3 ">
                  <div className="p-4 text-center h-100" style={{ borderRadius: 8 }}>
                    <img className="mb-3" src="https://image.flaticon.com/icons/svg/2095/2095557.svg" height="120px" />
                    <p className="font-bold text-ultra-big text-center">رهن و اجاره</p>
                    <div className="text-small mb-3 mt-2">توضیحات مربوط به این بخش در اینجا نمایش داده میشود. توضیحات مختصر باشد</div>
                  </div>
                </div>

                <div className="col-6 col-md-3 m-0 mt-3">
                  <div className="p-4 text-center h-100" style={{ borderRadius: 8 }}>
                    <img className="mb-3" src="https://image.flaticon.com/icons/svg/2095/2095555.svg" height="120px" />
                    <p className="font-bold text-ultra-big">خرید و فروش</p>
                    <div className="text-small mb-3 mt-2">توضیحات مربوط به این بخش در اینجا نمایش داده میشود. توضیحات مختصر باشد</div>
                  </div>
                </div>

                <div className="col-6 col-md-3 m-0 mt-3">
                  <div className="p-4 text-center h-100" style={{ borderRadius: 8 }}>
                    <img className="mb-3" src="https://image.flaticon.com/icons/svg/2095/2095575.svg" height="120px" />
                    <p className="font-bold text-ultra-big ">پیش فروش</p>
                    <div className="text-small mb-3 mt-2 ">توضیحات مربوط به این بخش در اینجا نمایش داده میشود. توضیحات مختصر باشد</div>
                  </div>
                </div>

                <div className="col-6 col-md-3 m-0 mt-3">
                  <div className="p-4 text-center h-100" style={{ borderRadius: 8 }}>
                    <img className="mb-3" src="https://image.flaticon.com/icons/svg/2095/2095538.svg" height="120px" />
                    <p className="font-bold text-ultra-big ">مشارکت</p>
                    <div className="text-small mb-3 mt-2 ">توضیحات مربوط به این بخش در اینجا نمایش داده میشود. توضیحات مختصر باشد</div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="container pb-4" style={{borderBottom:'#eee solid 1px'}}>

            <div className="col-12 mb-5 mt-5 flexcc flex-column">
              <span className="font-bold text-center " style={{ fontSize: 35 }}>جدیدترین آگهی ها</span>

              {/* <div className="flexcc w-100">
              <hr className="w-100 mrd-3" />
              <span className="font-bold text-center text-ultra-big mt-4">{translate('opia-products')}</span>
              <hr className="w-100  mld-3" />
            </div> */}
              {/* <div className="text-center text-normal flexcc mt-2 mb-4" style={{ maxWidth: 650 }}>{translate('opia-products-desc')}</div> */}
            </div>

            <div className="col-12 mb-5 mt-4">
              <DataGrid isLoading={this.state.isLoadingProducts} data={this.state.estates} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Estate} col="col-12 col-md-6 col-lg-4" />
            </div>
          </div>
        </section>



        <section>
          <div className="container pt-5 pb-5" style={{ overflow: 'hidden' }}>
            <div className="row pt-3 pb-5 flexcc">
              <div className="col-12 col-md-6">
                <span className="text-ultra-big font-bold">{translate('what-we-do')}</span>
                <div className="mt-3">
                  <div>
                    <p>گروه مشاورین مسکن رئیس با مدیریت حمید دیانی - مشاوره در زمینه خرید و فروش انواع ملک - آپارتمان - ویلا- مغازه و ... مکانی امن برای سرمایه گذاری شما</p>
                    <h3 className="mb-2">فعالیت در حوزه های</h3>
                    <div className="mld-2">
                      <p>- ملک های اداری</p>
                      <p>- ملک های تجاری</p>
                      <p>- ملک های مسکونی</p>
                      <p>- ملک های کلنگی</p>
                      <p>- ویلا و ملک های ویلایی</p>
                      <p>- باغ و باغچه</p>
                    </div>

                  </div>
                  {/* <div className="mt-2">{translate("what-we-do-desc2")}</div> */}

                </div>
              </div>

              <div className="col-12 col-md-6 position-relative" style={{ height: 300 }}>
                <figure style={{ position: 'absolute', top: '35%', left: '40%', width: '40%', }}>
                  <img src="/images/raeesmelk/cover1.jpg" width="100%" alt="" style={{ borderRadius: 8 }} />
                </figure>
                <figure style={{ position: 'absolute', top: '15%', left: '30%', width: '20%' }}>
                  <img src="/images/raeesmelk/cover2.jpg" width="180px" alt="" style={{ borderRadius: 8 }} />
                </figure>
                <figure style={{ position: 'absolute', top: '0%', left: '40%', width: '30%' }}>
                  <img src="/images/raeesmelk/cover3.jpg" width="180px" alt="" style={{ borderRadius: 8 }} />
                </figure>

              </div>
            </div>
          </div>
        </section>



        <section>
          <div className="container pt-5 pb-5 mb-5" style={{ overflow: 'hidden' }}>
            <img src="/images/raeesmelk/banner.jpg" width="100%" style={{borderRadius:15}} />
          </div>
        </section>



      </div>

    );
  }
}

export default RaeesOpia;
