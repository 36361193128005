import React from "react";
import { translate } from '../utils/useful'
import { siteConfig } from '../variables/config'
import i18n from "../i18n";
import TLink from "../components/TLink";
import HttpServices from "../utils/Http.services";
import Loader from "react-loader-spinner";
import moment from 'jalali-moment'

class OpiaFooter extends React.Component {

  state = {
    subscribed: false,
    email: '',
    socialNetworks: [
      { name: 'facebook', icon: '/images/facebook1.svg' },
      { name: 'telegram', icon: '/images/telegram1.svg' },
      { name: 'linkedin', icon: 'https://image.flaticon.com/icons/svg/1409/1409945.svg' },
      { name: 'instagram', icon: '/images/instagram.svg' },
      { name: 'youtube', icon: 'https://image.flaticon.com/icons/svg/2965/2965363.svg' },
      { name: 'aparat', icon: 'https://cdn.iconscout.com/icon/free/png-512/aparat-569197.png' },

    ],

    links:
    {
      company: [
        { title: 'راهنمای خرید امن', link: '/pages/shopping' },
        { title: 'قوانین و مقررات', link: '/pages/policy' },
        // { title: 'سوالات متداول', link: '/pages/faq' },
        // { title: 'تبلیغات', link: '/pages/advertisements' },
        // { title: 'مقالات آموزشی', link: '/pages/faq' },
        // { title: 'دوره آموزشی', link: '/pages/faq' },

      ],

      fastAccess: [
        { title: 'ثبت آگهی', link: '/new-ad' },
        { title: 'جست و جو', link: '/search' },
        // { title: 'مقالات آموزشی', link: '/pages/faq' },
        // { title: 'دوره آموزشی', link: '/pages/faq' },
      ],

      contactus: [
        { title: 'درباره ما', link: '/aboutus' },
        { title: 'تماس با ما', link: '/contactus' },
        // { title: 'مقالات آموزشی', link: '/pages/faq' },
        // { title: 'دوره آموزشی', link: '/pages/faq' },

      ],

      services: [
        { title: 'سوالات متداول', link: '/pages/faq' },
        { title: 'تبلیغات', link: '/pages/advertisements' },
        // { title: 'مقالات آموزشی', link: '/pages/faq' },
        // { title: 'دوره آموزشی', link: '/pages/faq' },

      ],


      agreements: [
        { title: translate("privacy-policy"), link: '/pages/policy' },
        { title: translate("terms"), link: '/pages/terms' },
      ],



    }


  }


  subscribe = () => {
    let data = { values: {} }
    // data.values = this.form.getForm()
    // if (data.values) {
    this.setState({ isLoadingSubscribe: true, emailMessage: null })

    data.applet = 'subscribes'
    let email = this.state.email
    data.values.email = email
    if ((/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/).test(email)) {

      HttpServices.request('addSubscribe', data, (fetchResult, fetchError) => {
        this.setState({ isLoadingSubscribe: false })
        // console.log(fetchResult)
        // console.log(fetchError)
        if (fetchError) { this.setState({ emailMessage: fetchError.message }); return }
        this.setState({ emailMessage: "تبریک! عضویت با موفقیت انجام شد", email: '' })
      })
    } else {
      this.setState({ isLoadingSubscribe: false })
      this.setState({ emailMessage: 'ایمیل به درستی وارد نشده است' })
      // console.log("DOES NOT MATCH")
    }
    // }

  }

  // subscribe() {
  //   localStorage.setItem('subscribed', this.state.email)
  //   this.setState({ subscribed: true })
  // }

  render() {
    return (
      <footer
        className={"w-100"}
        style={{ bottom: 0, position: 'relative' }}
      >
        <div className={""} style={{ color: '#fff', backgroundColor: '#fafafa', margin: 0, paddingBottom: 40 }}>

          <div className="w-100 h-100 position-absolute footer-back" style={{ backgroundColor: 'rgba(253,118,42,1)', top: 0, left: 0, borderRadius: '30%/70% 0px 0px 0px' }}>

          </div>

          <div className={'container px-4'}>
            <div className="row w-100 pt-4">

              <div className={'col-12 col-md-6  mt-4 mb-4'}>
                <p className="text-semibig">عضویت در خبرنامه</p>
                <div className="mt-2 mb-4" style={{ width: '25%', height: 1, backgroundColor: '#ffffffaa', borderRadius: 10 }}>
                </div>


                <div className="w-100" style={{ display: 'flex-inline', maxWidth: 350 }}  >
                  <div className="flexcc px-2 py-1" style={{ backgroundColor: '#fff', borderRadius: 30 }}>
                    <input value={this.state.email} className="subscribe-input" onChange={e => this.setState({ email: e.target.value })} style={{ fontSize: 14, flex: 1, padding: '0px 10px', backgroundColor: 'transparent', height: 35, border: '0px solid #ddd', direction: 'rtl' }} placeholder="برای عضویت ایمیل خود را وارد نمایید" />
                    {this.state.isLoadingSubscribe ? (
                      <div className="flexcc pt-2" style={{ height: 26, overflow: 'hidden' }}>
                        <Loader
                          type={"Oval"}
                          color={"rgba(253,121,45,1)"}
                          height={26}
                          width={26}
                        />
                      </div>
                    ) : (
                      <button onClick={() => this.subscribe()} style={{ backgroundColor: 'rgba(253,121,45,1)', color: '#fff', border: 'none', cursor: 'pointer', borderRadius: 30, padding: '8px 20px' }}>ثبت</button>
                    )}
                  </div>

                  {this.state.emailMessage && (
                    <p className="white text-small mt-2 px-3">{this.state.emailMessage}</p>
                  )}


                  <div className="mt-4 w-100 flexc" style={{ justifyContent: 'space-around' }}>
                    {this.state.socialNetworks.map((prop, index) => {
                      if (siteConfig[prop.name] && siteConfig[prop.name] != '')
                        return (
                          <a rel="no-follow noopener noreferrer" target="_blank" href={siteConfig[prop.name]}>
                            <img className="invert" src={prop.icon} width="26px" alt="" />
                          </a>
                        )
                    })}

                  </div>

                </div>
              </div>


              <div className={'col-12 col-md-6  mt-4 flexcc mb-4'}>
                {/* <img className="" src={'/images/samandehi1.png'} width="120px" alt="" /> */}
                <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=212736&amp;Code=lQrS6P97TVKppDxNij8p"><img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=212736&amp;Code=lQrS6P97TVKppDxNij8p" alt="" style={{ cursor: "pointer" }} id="lQrS6P97TVKppDxNij8p" /></a>
                {/* <img className="" src={'/images/samandehi1.png'} width="120px" alt="" /> */}
                <img id='nbqergvjjzpergvjapfujzpe' style={{ cursor: 'pointer' }} onClick={() => window.open("https://logo.samandehi.ir/Verify.aspx?id=237357&p=uiwkxlaojyoexlaodshwjyoe", "Popup", "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")} alt='logo-samandehi' src='https://logo.samandehi.ir/logo.aspx?id=237357&p=odrfqftiyndtqftiujynyndt' />

              </div>






              <div className={'col-6 col-sm-6 col-md-3 mt-4'}>
                <div className="mb-2 text-normal white">دسترسی سریع</div>
                <div className="mt-3 mb-3" style={{ width: '80%', height: 1, backgroundColor: '#ffffff50', borderRadius: 10 }}>
                </div>

                {this.state.links.fastAccess.map((prop, index) => {
                  return (
                    <div key={index} className="mb-2" style={{ display: 'block-inline' }}>
                      <TLink to={prop.link}>
                        <span className="text-small ">{prop.title}</span>
                      </TLink>
                    </div>
                  )
                })}
              </div>


              <div className={'col-6 col-sm-6 col-md-3 mt-4'}>
                <div className="mb-2 text-normal white">تماس با ما</div>
                <div className="mt-3 mb-3" style={{ width: '80%', height: 1, backgroundColor: '#ffffff50', borderRadius: 10 }}>
                </div>

                {this.state.links.contactus.map((prop, index) => {
                  return (
                    <div key={index} className="mb-2" style={{ display: 'block-inline' }}>
                      <TLink to={prop.link}>
                        <span className="text-small ">{prop.title}</span>
                      </TLink>
                    </div>
                  )
                })}
              </div>

              <div className={'col-6 col-sm-6 col-md-3 mt-4'}>
                <div className="mb-2 text-normal white">اطلاعات بیشتر</div>
                <div className="mt-3 mb-3" style={{ width: '80%', height: 1, backgroundColor: '#ffffff50', borderRadius: 10 }}>
                </div>

                {this.state.links.company.map((prop, index) => {
                  return (
                    <div key={index} className="mb-2" style={{ display: 'block-inline' }}>
                      <TLink to={prop.link}>
                        <span className="text-small ">{prop.title}</span>
                      </TLink>
                    </div>
                  )
                })}
              </div>


              <div className={'col-6 col-sm-6 col-md-3 mt-4'}>
                <div className="mb-2 text-normal white">خدمات</div>
                <div className="mt-3 mb-3" style={{ width: '80%', height: 1, backgroundColor: '#ffffff50', borderRadius: 10 }}>
                </div>

                {this.state.links.services.map((prop, index) => {
                  return (
                    <div key={index} className="mb-2" style={{ display: 'block-inline' }}>
                      <TLink to={prop.link}>
                        <span className="text-small ">{prop.title}</span>
                      </TLink>
                    </div>
                  )
                })}
              </div>

              <div className={'col-12 mt-5'}>
                <div className="flexcc">
                  <span style={{ whiteSpace: 'pre-wrap', fontSize: 14, }}> کلیه حقوق این سایت متعلق به انبار مازاد می باشد {(moment(new Date()).locale('fa').format('YYYY'))} &copy;</span>
                </div>
              </div>





              {/* <div className={'col-12  p-0'}>
                <hr style={{ borderTop: "0px solid #758595", opacity: 0.7 }}></hr>
              </div> */}

              {/* 
              <div className={'col-12 col-md-6 '}>
                <span className='' style={{ whiteSpace: 'pre-wrap', fontSize: 14, }}>{i18n.language === "fa" ? siteConfig.addressFa : siteConfig.addressEn}</span>
                <span className='flexc' style={{ whiteSpace: 'pre-wrap', fontSize: 14, }}>&copy; {translate('copyright', { name: siteConfig.name })}</span>

                <div className="mt-3 d-flex">
                 
                  <div className="m-1">
                    <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=180012&amp;Code=ik1AMnvHf9vgxxpyHXwD"><img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=180012&amp;Code=ik1AMnvHf9vgxxpyHXwD" alt="" style={{ cursor: "pointer" }} id="ik1AMnvHf9vgxxpyHXwD" /></a>
                  </div>
                  <div className="m-1">
                    <img id='jxlzsizpapfujzpergvjjzpe' style={{ cursor: 'pointer' }} onClick={() => window.open("https://logo.samandehi.ir/Verify.aspx?id=195737&p=rfthpfvldshwjyoexlaojyoe", "Popup", "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")} alt='logo-samandehi' src='https://logo.samandehi.ir/logo.aspx?id=195737&p=nbpdbsiyujynyndtqftiyndt' />
                  </div>
                </div>

              </div> */}


              {/* <div className={'col-12 col-md-6 text-right'}>
                <div className="d-flex mb-1 justify-content-start justify-content-md-end">
                  {this.state.links.agreements.map((prop, index) => {
                    return (
                      <div key={index} className="mld-2 flexcc">
                        <TLink to={prop.link}>
                          <span style={{ fontSize: 15, marginTop: 5 }}>{prop.title}</span>
                        </TLink>
                        {index == 0 && (
                          <p className="mx-2 my-0 opacity-7">|</p>
                        )}
                      </div>
                    )
                  })}
                </div>

                <div className="mt-1 d-flex justify-content-start justify-content-md-end" style={{ filter: 'grayscale(0%) contrast(100%)' }}>

                </div>
              </div> */}



              {/* <div className={'col-12 col-md-6'}>

                {!this.state.subscribed && (
                  <div className=" d-md-flex d-block mt-4 mb-4 mb-lg-0 mt-lg-0 justify-content-center align-items-center mt-md-0  mt-4 text-center text-md-left"  >
                    <div className="ml-3 mt-2 mt-lg-0">
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input onChange={e => this.setState({ email: e.target.value })} style={{ padding: '0px 10px', backgroundColor: '#fff', height: 40, borderRadius: '4px 0px 0px 4px', border: '1px solid #ddd' }} placeholder="info@company.com" />
                        <button onClick={() => this.subscribe()} style={{ color: '#fff', background, border: 'none', cursor: 'pointer', height: 40, borderRadius: '0px 4px 4px 0px' }}>Subscribe</button>
                      </div>
                      <p style={{ fontSize: 15, color: '#757575' }} className={"ml-2 mt-1"}>Subscribe to our newsletter</p>
                    </div>
                  </div>
                )}


                {this.state.subscribed && (
                  <div className="flex-column d-md-flex d-block mt-4 mb-4 mb-lg-0 mt-lg-0 justify-content-center align-items-center mt-md-0  mt-4 text-center text-md-left"  >
                    <p href="mailto:info@antbuildz.com" style={{ fontWeight: 'bold', fontSize: 18, color: '#345' }}>Thanks,</p>
                    <p style={{ fontSize: 14, color: '#222' }}>You are subscribed to our newsletter</p>
                  </div>
                )}


              </div> */}




            </div>




          </div>


        </div>
      </footer>
    );
  }
}



export default OpiaFooter;
