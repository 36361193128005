import React from 'react'
import { checkTextTranslation } from '../../utils/useful'

class TextInput extends React.Component {
    state = {
    }


    changeInputValue(target) {
        let value = (target.validity.valid) ? target.value : null
        if (value !== null) {
            this.props.changeValue(this.props.header.key, value)
        }
    }


    render() {
        return (
            <div className="w-100 h-100 ">
                <input autoFocus={this.props.header.information.autoFocus} ref={el => this.input = el} value={this.props.data ? this.props.data : ''} onChange={(e) => this.changeInputValue(e.target)} placeholder={checkTextTranslation(this.props.header?.information?.placeholder)} maxLength={this.props.header?.information?.maxLength} className="transpanet-input text-small" />
            </div>
        )
    }
}

export default TextInput;