import React from 'react'
import { translate } from '../../utils/useful'
import { siteConfig } from '../../variables/config'
import { withRouter } from 'react-router-dom'
import { pathMaker, imageAddress } from '../../utils/useful'
import TLink from '../TLink'

class PartnerWelcomeStep extends React.Component {


    render() {
        return (

            <div className="flexcc flex-column">
                <div className="w-100 mt-0" style={{ maxWidth: 400 }}>
                    <div className="p-4 mt-3 text-center" style={{ backgroundColor: '#fff', borderRadius: 8 }}>

                        <img src={'https://image.flaticon.com/icons/svg/3004/3004584.svg'} width="80px" alt="congratulation" />
                        <h2 className="mt-2 mb-0 text-semibig">Partner Request</h2>
                        <h2 className="mt-0 mb-0 text-ultrabig">is submitted</h2>
                       
                        <p className="mb-4 mt-0 mt-3" style={{ fontSize: 15 }}>We will review your info within 24 hours and your if everything is ok, your partner permissions would be activated</p>
                        
                        <TLink pathname={'/'} style={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}>
                            Home Page
                        </TLink>

                    </div>


                </div>
            </div>

        )
    }
}

export default withRouter(PartnerWelcomeStep);