import React from 'react'
import { translate, currentLng, imageAddress } from '../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import TLink from './TLink';
// import { siteConfig } from '../variables/config'

// import { siteTheme } from '../variables/config'
// import TLink from './TLink'
// import Modal from './Modal'

class Resource extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    dataBuilder(data, schema) {
        let prop = {}
        for (const [key] of Object.entries(this.state.schema)) {
            prop[key] = this.valueBuilder(schema[key], data)
        }
        return prop
    }

    valueBuilder(key, data) {
        if (key != null && data.lng) {
            let keysArray = key.split('.')
            let newData = data
            if (keysArray[0] === "{{lng}}") {
                newData = data.lng[currentLng()]
            } else {
                newData = data[keysArray[0]]
            }
            if (keysArray[1] != null) {
                return this.valueBuilder(keysArray[1], newData)
            } else {
                return newData
            }
        } else {
            return key
        }
    }


    openModal() {
        // let location = window.location.href
        // window.history.pushState(null, null, '/resource' + '?id=' + this.props.data._id);
        // this.setState({ location }, () => {
        this.props.actions.setResource(this.props.data)
        // this.modal.showModal()
        // })

    }


    changeUrl = () => {
        window.history.pushState(null, null, this.state.location);
        this.setState({ showModal: false })
    }

    render() {
        let prop = this.props.data//this.dataBuilder(this.props.data, this.props.schema)
        // <TLink pathname={'/resource'} search={'?id=' + prop._id} ref={el => this.box = el} className="text-center h-100">
        //     <img className="h-100" src={imageAddress(prop.values.file, 'resource', 'thumb')} alt="" style={{ backgroundColor: '#f2f6f8', objectFit: 'contain', borderRadius: 2 }} />
        // </TLink>
        return (
            <React.Fragment>
                <div className="text-center h-100 m-0 p-0 position-relative">
                    {this.props.options && (
                        <TLink to={'/editResource'} search={"?id=" + prop._id} className="py-1 px-2 blur-back" style={{ position: 'absolute', top: 10, left: 10, backgroundColor: '#000000dd', borderRadius: 4 }}>
                            <p className="text-small white">{translate('edit')}</p>
                        </TLink>
                    )}
                    <button onClick={() => this.openModal()} ref={el => this.box = el} className="text-center h-100 m-0 p-0">
                        <img className="h-100" src={imageAddress(prop.values.file, 'resource', 'thumb')} alt="" style={{ backgroundColor: '#f2f6f8', objectFit: 'contain', borderRadius: 2 }} />
                    </button>
                </div>


            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, resource: state.resource })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Resource);