import React from "react";
import Loader from 'react-loader-spinner'
import HttpService from '../utils/Http.services';


class Pages extends React.Component {

  state = {
    isLoadingPage: true,
    data: null
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.loadPageData()
    });
    this.loadPageData()
  }

  componentWillUnmount() {
    this.unlisten();
  }

  loadPageData() {
    this.setState({ data: null, isLoadingPage: true }, () => {

      this.fetch()
    })
  }


  fetch() {
    let address = this.props.match.params.name
    this.setState({ isLoadingPage: true })
    HttpService.request('getOneWithData', { applet: 'staticPages', filter: { 'values.address': address } }, (fetchResult, fetchError) => {
      console.log(fetchResult)
      if (fetchError) { return }
      if (fetchResult.info) {
        this.setState({ data: fetchResult.info })
      }
      this.setState({ isLoadingPage: false })
    })
  }



  render() {
    if (!this.state.isLoadingPage) {
      return (

        <div className=" mediumiransansfont" style={{ flexDirection: 'column', width: '100%', overflow: 'hidden', backgroundColor: '#FBFBFB', direction: 'ltr', minHeight: '100vh' }}  >

          <div style={{ width: '100%', padding: 20, backgroundColor: '#FF6C00', paddingTop: 40, paddingBottom: 50 }}>
            <div style={{ paddingLeft: '5%', paddingRight: '5%', marginTop: 30, textAlign: 'center' }}>
              {this.state.data ? (
                <div>
                  <h1 className=" text-ultra-big white">{this.state.data?.values?.description}</h1>
                  <h1 className=" text-mega-big white">{this.state.data?.values?.title}</h1>
                </div>
              ) : (
                  <div>
                    <h1 className=" text-ultra-big white">صفحه یافت نشد</h1>
                    <h1 className=" text-mega-big white">۴۰۴</h1>
                  </div>
                )}
            </div>
          </div>

          <div className="container flexcc w-100" style={{ paddingLeft: '5%', paddingRight: '5%', marginTop: 60 }}>

            {this.state.data ? (
              <div className="page-loader w-100" style={{ backgroundColor: '#fff', borderRadius: 10, padding: '5%', marginBottom: 100 }}>
                <div style={{ color: '#202020', fontSize: 15 }} dangerouslySetInnerHTML={{ __html: this.state.data?.values?.body }}></div>
              </div>
            ) : (
                <p className="text-center text-normal mt-5 mb-5 opacity-5" style={{ maxWidth: 400 }}>متاسفانه صفحه مورد نظر شما یافت نشد. لطفا از آدرس صفحه اطمینان حاصل نمایید</p>
              )}


          </div>
        </div>


      );
    } else {
      return (
        <div style={{ textAlign: 'center', width: '100%', paddingTop: 90, minHeight: '100vh' }}>
          <Loader
            type="Oval"
            color="rgba(20,20,20,1)"
            height="50"
            width="50"
          />
        </div>
      )
    }
  }
}

export default Pages;
