import React from 'react'
import { translate, currentLng, converNumberToPersian } from '../utils/useful'
import { siteTheme, siteConfig } from '../variables/config'
import TLink from './TLink'
import stripHtml from "string-strip-html"
import { imageAddress } from '../utils/useful'

class AccessoryCategory extends React.Component {
    state = {
        schema: { title: null, description: null, price: null, image: null, portals: null }
    }

    dataBuilder(data, schema) {
        let prop = {}
        for (const [key] of Object.entries(this.state.schema)) {
            prop[key] = this.valueBuilder(schema[key], data)
        }
        return prop
    }

    valueBuilder(key, data) {
        if (key != null && data) {
            let keysArray = key.split('.')
            let newData = data
            if (data.lng && keysArray[0] === "{{lng}}") {
                newData = data.lng[currentLng()]
            } else {
                newData = data[keysArray[0]]
            }
            if (keysArray[1] != null) {
                return this.valueBuilder(keysArray[1], newData)
            } else {
                return newData
            }
        } else {
            return null
        }
    }


    render() {
        let prop = this.props.data//this.dataBuilder(this.props.data, this.props.schema)
        return (
            <TLink to={'/accessories/' + (prop._id)} ref={el => this.box = el} className="text-center mt-0 flexcc flex-column pb-4 pt-2" style={{ border: '0px solid #eee', borderRadius: 15, backgroundColor: "#fff" }} >
                <img src={imageAddress(prop.values?.image, 'product', 'thumb')} alt="" style={{ borderRadius: '15px 15px 15px 15px', width: '100%', height: 200, objectFit: 'contain' }} />
                <div className="mb-2">
                    <p className="mt-3 text-smibig">{prop.values.name}</p>
                    <p className="mt-1 text-small" style={{ color: '#9ab' }}>{translate('مشاهده لوازم جانبی')}</p>
                </div>

            </TLink>
        )
    }
}

export default AccessoryCategory;