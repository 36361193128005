import React from 'react';
import { phoneStandardView, translate, imageAddress, priceStandardView } from '../../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import stripHtml from "string-strip-html";

class ShoppingList extends React.Component {



  getInfo = (cb) => {
    if (this.props.cart?.items.length > 0) {
      let list = []

      this.props.cart.items.forEach(item => {
        list.push({ count: item.count, item: item.data._id, type: item.type, color: item.color?.name, applet: item.data.applet })
      });

      let data = { list: list }
      cb(data)
    } else {
      cb(null, "{{lang}}errors.emptyCart")
    }
  }



  render() {

    return (
      <section>

        <div style={{ backgroundColor: '#fff', borderRadius: 8, overflow: 'hidden' }}>
          <div className="p-3" style={{ backgroundColor: '#e0e6e8' }}>
            <p className="font-bold text-center" style={{ fontSize: 18 }}>{translate('shoppingList')}</p>
            {/* <p className=" white" style={{ fontSize: 12 }}>{translate('Description about this list')}</p> */}
          </div>


          <div className="w-100 " style={{ padding: '0px 25px' }}>
            {this.props.cart?.items?.map((prop, index) => {
              // console.log(prop)
              return (
                <div key={index} style={{ borderRadius: 8, borderBottom: '1px solid #f2f6f8', padding: '25px 0px' }}>
                  <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>


                    <div style={{ flex: 3, display: 'flex' }}>

                      <div>
                        <img className="" src={(!Array.isArray(imageAddress(prop.data.values.image, 'product', 'small')) ? imageAddress(prop.data.values.images[0], 'product', 'small') : imageAddress(prop.data.values.images, 'product'))} style={{ width: 90, borderRadius: 8 }} />
                      </div>
                      <div className=" ml-3 mr-3 ">
                        <p style={{ fontSize: 16, color: '#111', fontWeight: 'bold' }}>{prop.data.values.name}</p>

                        <p className="mt-2 mb-3" style={{ color: '#9ab', fontSize: 12 }}>{stripHtml(prop.data.values?.description ? prop.data.values.description : '')}</p>

                        <div className="flexc">

                          <div style={{ display: 'inline-block' }}>
                            <div className=" d-flex" style={{ justifyContent: 'space-between', backgroundColor: '#fff', border: '1px solid #e2e6e8', padding: '0px 10px', color: '#9ab', fontWeight: 'bold', textAlign: 'center', width: '100%', borderRadius: 4, fontSize: 16 }}>
                              <button className="p-0 mrd-4 text-big " style={{ color: '#9ab' }} onClick={() => this.props.actions.reduceFromCart(prop)} >-</button>
                              <div className="flexcc" style={{ flex: 1, borderLeft: '1px #e2e6e8 solid', borderRight: '1px #e2e6e8 solid', padding: '0px 14px' }}>
                                <p style={{ color: '#007aff' }}>{prop.count}</p>
                              </div>
                              <button className="p-0 mld-4 text-big " style={{ color: '#007aff' }} onClick={() => this.props.actions.addToCart({ data: prop.data, type: prop.type, color: prop.color })} >+</button>
                            </div>
                          </div>

                          <div className="flexcc">
                            <button onClick={() => this.props.actions.removeFromCart(prop)} style={{ color: '#9ab', padding: '5px 10px', fontSize: 13, fontWeight: 'bold' }}>{translate('remove')}</button>
                          </div>
                        </div>

                        {prop.color && (
                          <div className="mt-2" style={{ display: 'inline-flex' }}>
                            <div className="flexc mrd-2 px-2 py-1" style={{ borderRadius: 4, border: '1px solid #ddd', transition: 'all 0.5s' }}>
                              <div style={{ width: 15, height: 15, border: '#eee 1px solid', borderRadius: 2, backgroundColor: '#' + prop.color.code }}></div>
                              <p className="font-small mr-2 text-small" style={{ color: '#789' }}>{prop.color.name}</p>
                            </div>
                          </div>
                        )}


                        {prop.data.values.unit && (<p className={"text-small mt-2"} style={{ whiteSpace: 'nowrap', fontSize: 12, color: '#789' }}>واحد اندازه گیری: {prop.data.values.unit}</p>)}

                        {/* <p style={{ fontSize: 11, color: '#789' }}>{prop.package.title}</p> */}
                        {/* <p style={{ fontSize: 11, color: '#789' }}>هنرمند: {prop.data.creator ? prop.data.creator.name : ''}</p> */}


                        {/* {prop.data.self != '0' && (
                          <p style={{ fontSize: 13, marginTop: 3, fontWeight: 'normal', color: '#ee5050', whiteSpace: 'nowrap', fontWeight: 'bold' }}>Self Pickup</p>
                        )} */}

                      </div>
                    </div>


                    <div style={{ flex: 1, textAlign: 'right', flexDirection: 'column', display: 'flex', marginTop: 5, marginBottom: 5 }}>
                      <div style={{ textAlign: 'center', display: 'inline-flex', flexDirection: 'column' }}>
                        <p style={{ whiteSpace: 'nowrap', fontSize: 17, color: '#202020', fontWeight: 'bold', padding: 10, borderRadius: 4 }}>{translate('currency', { price: priceStandardView(prop.data.values.price) })}</p>


                      </div>
                      {/* <span onClick={() => this.props.setCart(prop, 'remove')} style={{ textAlign: 'center', marginTop: 5, cursor: 'pointer', color: '#fff', backgroundColor: '#ee5050', padding: '5px 10px', borderRadius: 4, fontSize: 13, fontWeight: 'bold' }}>Remove</span> */}

                    </div>
                  </div>
                </div>
              )
            })}

            {!this.props.cart?.items || this.props.cart?.items.length == 0 && (
              <div className="flexcc flex-column w-100 mb-5 mt-5">
                <img src="https://image.flaticon.com/icons/svg/3144/3144490.svg" style={{ width: 80, height: 80, objectFit: 'contain' }} />
                <p className="text-small mt-3  ">{translate('emptyBasket')}</p>
              </div>
            )}

          </div>

        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, cart: state.cart })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { forwardRef: true }
)(ShoppingList);


