import React from "react";
import { checkTextTranslation } from "../../utils/useful";

// used for making the prop types of this component
// import PropTypes from "prop-types";

class PriceInput extends React.Component {
    state = {
        value: '',
        showValue: ''
    }

    componentDidMount() {
        if (this.props.data != null) {
            this.setState({ value: this.props.data, showValue: this.changeToPriceValue(this.props.data) })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data != prevProps.data) {
            this.setState({ value: this.props.data, showValue: this.changeToPriceValue(this.props.data) })
        }
    }

    persianNumbers = { '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4', '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9' }
    handleChange(evt) {
        let value = ''

        if (evt.target.value != "") {

            value = evt.target.value.split('')
            let translatedValue = ''
            value.forEach((element, index) => {
                if (this.persianNumbers[element]) {
                    value[index] = this.persianNumbers[element]
                }
                translatedValue = translatedValue + value[index]
            });
           

            value = translatedValue.replace(new RegExp(',', 'g'), '')
            value = String(Number(value))
        }

        if (Number(value) || Number(value) == 0) {
            this.setState({ value, showValue: this.changeToPriceValue(value) });

            this.props.changeValue(this.props.header.key, value, this.props.extra)
        }
    }

    conditionalSettings(param, condition) {
        if (this.props.header.information.settings) {
            if (this.props.header.information.settings[param] == condition) {
                return true
            }
        }
        return false
    }


    changeToPriceValue(value) {
        console.log(value)
        let newValue = String(value).split('')
        let final = ''
        let count = 0
        for (let i = newValue.length - 1; i >= 0; i--) {
            final = newValue[i] + final
            count++
            if (count == 3 && i != 0) {
                final = ',' + final
                count = 0
            }
        }
        // console.log(final)
        return final
    }


    render() {
        let info = this.props.header.information

        return (
            <div className='mt-0 w-100' >
                {/* <label className={'mb-2 mr-1 ml-1 ' + (this.conditionalSettings('required', true) ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{this.props.label}</label> */}
                <input pattern="[0-9]*" onInput={this.handleChange.bind(this)} disabled={this.conditionalSettings('disabled', true)} value={this.state.showValue ? this.state.showValue : ''} placeholder={checkTextTranslation(info.placeholder)} className="transpanet-input" />
            </div>
        );
    }
}

export default PriceInput;
