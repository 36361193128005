import React from "react";
// used for making the prop types of this component
// import PropTypes from "prop-types";

class SwitchInput extends React.Component {
    state = { checked: true }
    componentWillMount() {

        this.setState({ checked: this.props.title })
    }

    checkSettings(param) {
        if (this.props.settings) {
            return this.props.settings[param]
        } else {
            return false
        }
    }

    render() {
        let info = this.props.info
        return (
            <div className='mt-1 '>
                {/* <label className={'p-0 mr-1 ml-1 ' + (info.required ? 'required' : '')} style={{ marginBottom: 3, fontSize: 12, marginTop: 5, color: '#999' }}>{info.label}</label> */}
                <div>
                    {/* <label className="switch mb-0 p-0"> */}
                        <input className="m-0 p-0" type="checkbox" name={this.props.header.key} id={this.props.header.key} checked={this.state.checked} disabled={this.checkSettings("disabled")} onChange={e => { this.props.changeValue(this.props.header.key, !this.props.data, this.props.extra); this.setState({ checked: !this.state.checked }) }} />
                        {/* <span></span>
                    </label> */}
                </div>
            </div>
        );
    }
}

export default SwitchInput;
