import React from 'react';
import { translate, imageAddress, priceStandardView, converNumberToPersian } from '../../utils/useful'
import Section from '../../components/Section'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import * as THREE from "three";
import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";
import OrbitControls from "three-orbitcontrols";
import Stats from 'three/examples/jsm/libs/stats.module';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { RoomEnvironment } from '../../components/RoomEnvironment';
import HttpService from '../../utils/Http.services';
import Collapsible from 'react-collapsible';
import Loader from 'react-loader-spinner'
import DataGrid from '../../components/DataGrid';
import Product from '../../components/SingleProduct'
import stripHtml from "string-strip-html"

// import AccessoryCategory from '../../components/AccessoryCategory'


// import { DRACOLoader } from 'three/examples/js/libs/draco';


class ThreeD extends React.Component {

  state = {
    width: window.innerWidth,
    height: window.innerHeight,
    collapses: {},
    selectedParts: {},
    stage: 0,
    groups: {},
    selectedAccessoreis: {},
    hiddens: {},
    isPlay: true
  }

  componentDidMount() {
    this.fetch()
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    document.body.classList.add("body-no-sroll")
    window.addEventListener('resize', this.onWindowResize, false);

  }

  componentWillUnmount() {
    document.body.classList.remove("body-no-sroll")
    window.removeEventListener('resize', this.onWindowResize, false);
    document.removeEventListener('mouseup', this.onDocumentMouseUp, false);
    document.removeEventListener('mousedown', this.onDocumentMouseDown, false);
    this.stop()
  }



  fetchAccessories() {
    let product = this.state.data.values?.product?._id
    // console.log(category)

    HttpService.request("getAllWithData", { filter: { applet: 'accessories', 'values.category': product } }, (fetchResult, fetchError) => {
      // console.log(fetchResult)
      this.setState({ isLoading: false })
      if (fetchError) { return }


      let groups = {}

      fetchResult.info.sort((a, b) => {
        if (a.values.accessoryCategory?._id) { return -1; } else { return 1; }
        // if (a.values.accessoryCategory?._id > b.values.accessoryCategory?._id) { return 1; }
        // return 0;
      })

      let selectedAccessoreis = {}

      fetchResult.info.forEach(accessory => {

        let id = '*'
        let name = accessory.values.accessoryCategory?.values?.name

        if (accessory.values.accessoryCategory?._id) {
          id = accessory.values.accessoryCategory?._id
        } else {
          name = 'سایر'
        }

        if (!groups[id]) {
          groups[id] = { name: name, id, accessories: [] }
        }
        groups[id].accessories.push(accessory)


        if (this.state.data.values?.baseAccessories?.indexOf(accessory._id) > -1) {
          selectedAccessoreis[accessory._id] = { accessory: accessory, plus: 0, group: id }
        }

        // fetchResult.info.values.baseAccessories
      });

      // fetchResult.info.


      // console.log(selectedAccessoreis)
      this.setState({ groups, selectedAccessoreis })
    })

  }


  fetch() {


    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    this.setState({ isLoadingPage: true })

    // console.log("HERE")
    // , filter: { _id: id }

    HttpService.request("getOneWithData", { filter: { applet: '3d' } }, (fetchResult, fetchError) => {
      // console.log(fetchResult.info)
      // console.log(this.state.data?.values?.title)
      if (fetchError) { return }
      if (fetchResult.info) {

        let modelInfo = fetchResult.info.values.info
        modelInfo.sort((a, b) => (a.priority - b.priority))
        fetchResult.info.values.info = modelInfo

        // console.log()
        // this.setState({selectedAccessories})

        this.setState({ data: fetchResult.info }, () => {
          this.fetchAccessories()

          if (this.mount) {

            this.init()
          }
        })
      }
    })
  }



  init() {

    this.stats = new Stats();

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(width / height);
    this.renderer.setSize(width, height);
    // this.renderer.setAnimationLoop(() => this.renderAnimation());
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 0.85;

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();


    this.mount.appendChild(this.renderer.domElement);

    // this.mount.appendChild( this.stats.dom );

    // this.mount.appendChild(this.renderer.domElement);
    //add Camera
    this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);


    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.enableDamping = true
    this.controls.enablePan = false;
    // this.camera.zoom = 0.5
    // this.camera.updateProjectionMatrix();
    // this.controls.enableZoom = false;
    // this.controls.enableDamping = true;
    // this.controls.minPolarAngle = 0.8;
    // this.controls.maxPolarAngle = 2.4;
    // this.controls.dampingFactor = 0.07;
    // this.controls.rotateSpeed = 0.07;
    this.controls.target.set(0, 0.5, 0);
    this.controls.minDistance = 8
    this.controls.maxDistance = 20

    this.camera.position.set(9.5, 9.5, - 9.5);


    this.controls.update();



    var environment = new RoomEnvironment();
    var pmremGenerator = new THREE.PMREMGenerator(this.renderer);



    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color("#f2f6f8");
    this.scene.environment = pmremGenerator.fromScene(environment).texture;
    this.scene.fog = new THREE.Fog("#eeeeee", 10, 1000);

    this.grid = new THREE.GridHelper(100, 0, "#000000", "#000000");
    this.grid.material.opacity = 0.05;
    this.grid.material.depthWrite = false;
    this.grid.material.transparent = true;
    this.grid.position.set(0, -1.4, 0)
    this.scene.add(this.grid);

    // this.camera.zoom = 4


    // var bodyMaterial = new THREE.MeshPhysicalMaterial({
    //   color: "#c3323f", metalness: 0.6, roughness: 0.4, clearcoat: 0.05, clearcoatRoughness: 0.05
    // });
    var backBodyMaterial = new THREE.MeshPhysicalMaterial({
      color: "#ffffff", metalness: 0.4, roughness: 0.6, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var frontBodyMaterial = new THREE.MeshPhysicalMaterial({
      color: "#ffffff", metalness: 0.4, roughness: 0.6, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var fanBodyMaterial = new THREE.MeshPhysicalMaterial({
      color: "#ffffff", metalness: 0.4, roughness: 0.6, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var wheelMaterial = new THREE.MeshPhysicalMaterial({
      color: "#ffffff", metalness: 0.4, roughness: 0.6, clearcoat: 0.05, clearcoatRoughness: 0.05
    });


    var blackMaterial = new THREE.MeshPhysicalMaterial({
      color: "#000", metalness: 0.6, roughness: 0.4, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var glassMaterial = new THREE.MeshPhysicalMaterial({
      color: "#ffffff", metalness: 0.5, roughness: 0.1, transmission: 0.9, transparent: true
    });

    var handleMaterial = new THREE.MeshStandardMaterial({
      color: "#444550", metalness: 0, roughness: 0.8, clearcoat: 1, reflectivity: 1, clearcoatRoughness: 0.5
    });

    var bottomMaterial = new THREE.MeshStandardMaterial({
      color: "#444550", metalness: 0.2, roughness: 0.7, clearcoat: 1, reflectivity: 1, clearcoatRoughness: 0.5
    });


    var fontOpenerMaterial = new THREE.MeshPhysicalMaterial({
      color: "#ffffff", metalness: 0.4, roughness: 0.6, clearcoat: 0.05, clearcoatRoughness: 0.05
    });


    // var fontOpenerMaterial = new THREE.MeshStandardMaterial({
    //   color: "#444550", metalness: 0.2, roughness: 0.7, clearcoat: 1, reflectivity: 1, clearcoatRoughness: 0.5
    // });


    // 54b38a سبز یواش
    // ee0000 قرمز
    // 000a28 نفتی
    // 000 مشکی
    // fffff
    // d5b1fe یاسی
    // ff172d صورتی سخت
    // 78a طوسی فیلی
    // ff7100 خردلی
    // 202022 دودی

    // 54b38a-ee0000-000a28-000-ffffff-d5b1fe-ff172d-7788aa-ff7100-202022

    // 83d9da-cb0606-000a28-000-ffffff-d5b1fe-fb314a-7788aa-ff7100-1d1d1d

    // مشکی-قرمز
    // 100000-50000

    var plugMaterial = new THREE.MeshPhysicalMaterial({
      color: "#789", metalness: 0, roughness: 1, clearcoat: 0.05, clearcoatRoughness: 1
    });

    var metalMaterial = new THREE.MeshPhysicalMaterial({
      color: "#bbcbcb", metalness: 0.6, roughness: 0, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var metalMaterial0 = new THREE.MeshPhysicalMaterial({
      color: "#bbcbcb", metalness: 0.8, roughness: 0, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var wheelHolderMaterial = new THREE.MeshPhysicalMaterial({
      color: "#bbcbcb", metalness: 0.3, roughness: 0.2, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var connectorMaterial = new THREE.MeshPhysicalMaterial({
      color: "#bbcbcb", metalness: 0.3, roughness: 0.2, clearcoat: 0.05, clearcoatRoughness: 0.05
    });

    var plugPlaceMaterial = new THREE.MeshPhysicalMaterial({
      color: "#bbcbcb", metalness: 0.3, roughness: 0.2, clearcoat: 0.05, clearcoatRoughness: 0.05
    });




    var shadowMaterial = new THREE.MeshPhysicalMaterial({
      color: "#000", metalness: 0, roughness: 1, clearcoat: 1, clearcoatRoughness: 1, reflectivity: 0
    });

    var switchMaterial = new THREE.MeshStandardMaterial({
      color: "#444550", metalness: 0, roughness: 0.8, clearcoat: 1, reflectivity: 1, clearcoatRoughness: 0.5
    });



    // var detailsMaterial2 = new THREE.MeshStandardMaterial({
    //   color: "#444550", metalness: 0.2, roughness: 0.7, clearcoat: 1, reflectivity: 1, clearcoatRoughness: 0.5
    // });

    var balaye_switch_pattern = new THREE.TextureLoader().load('/patterns/balaye_switch.png', (tex) => {

      // console.log(tex.image.width)
      // console.log(tex.image.height)
      // console.log( texture.image.width, texture.image.height );

    })//'https://c.static-nike.com/a/images/w_1920,c_limit/bzl2wmsfh7kgdkufrrjq/image.jpg');

    var touch_pattern_pattern = new THREE.TextureLoader().load('/patterns/touch_pattern.png', (tex) => { })
    var balaye_switch_pattern = new THREE.TextureLoader().load('/patterns/balaye_switch.png', (tex) => { })
    var zire_brand_pattern = new THREE.TextureLoader().load('/patterns/zire_brand.png', (tex) => { })
    var zire_dariche_pattern = new THREE.TextureLoader().load('/patterns/zire_dariche.png', (tex) => { })

    var zire_dariche_pattern = new THREE.TextureLoader().load('/patterns/zire_dariche.png', (tex) => { })

    var bottom_plate_pattern = new THREE.TextureLoader().load('/patterns/bottom_plate.png', (tex) => { })

    // var balaye_switch_pattern = new THREE.TextureLoader().load('/patterns', (tex) => {    })

    // var texture = new THREE.TextureLoader().load('https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')//'https://c.static-nike.com/a/images/w_1920,c_limit/bzl2wmsfh7kgdkufrrjq/image.jpg');
    let patterns = [balaye_switch_pattern, touch_pattern_pattern, zire_brand_pattern, zire_dariche_pattern, bottom_plate_pattern]

    patterns.forEach(pattern => {

      pattern.wrapS = THREE.RepeatWrapping
      pattern.wrapT = THREE.RepeatWrapping
      pattern.magFilter = THREE.LinearFilter;
      pattern.minFilter = THREE.LinearFilter;
      pattern.mapping = THREE.CubeReflectionMapping;
      pattern.flipY = false;
      // pattern.flipX = false;

      pattern.repeat.set(1, 1)

    });

    bottom_plate_pattern.repeat.x = - 1

    // balaye_switch_pattern.mapping = THREE.CubeReflectionMapping;
    // balaye_switch_pattern.userData = {
    //   fitTo: 1
    // };
    // balaye_switch_pattern.rotation = 90
    // let repeatX = 1
    // let repeatY = 1

    // console.log("here")


    // texture.offset.x = (repeatX - 1) / 2 * -1

    var touch_pattern = new THREE.MeshStandardMaterial({ map: touch_pattern_pattern, transparent: true, metalness: 0.4, roughness: 0.6, clearcoat: 0.05, clearcoatRoughness: 0.05 });


    var balaye_switch = new THREE.MeshBasicMaterial({ map: balaye_switch_pattern, transparent: true });
    var zire_brand = new THREE.MeshBasicMaterial({ map: zire_brand_pattern, transparent: true });
    var zire_dariche = new THREE.MeshBasicMaterial({ map: zire_dariche_pattern, transparent: true });
    var bottom_plate = new THREE.MeshBasicMaterial({ map: bottom_plate_pattern, transparent: true });

    var shadow = new THREE.TextureLoader().load('/ferrari_ao.png');

    this.setState({ width: window.innerWidth, height: window.innerHeight })

    // console.log("HERE")
    var dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    // dracoLoader.setDecoderPath('../node_modules/three/examples/js/libs/draco/gltf/');

    var loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);
    // console.log("HERE11")
    this.wheels = []

    // intersects
    // let address = '/assets/images/'+this.state.data.values?.file.address
    // let address = siteConfig.assetsDomain+'images/'+this.state.data.values?.file.address

    let materials = {
      backBodyMaterial,
      frontBodyMaterial,
      fanBodyMaterial,
      wheelMaterial,
      blackMaterial,
      glassMaterial,
      handleMaterial,
      bottomMaterial,
      fontOpenerMaterial,
      switchMaterial,
      plugMaterial,
      wheelHolderMaterial,
      shadowMaterial,
      metalMaterial,
      connectorMaterial,
      plugPlaceMaterial
    }

    let values = this.state.data.values

    loader.load('/12.glb', (gltf) => {

      this.setState({ isLoadingPage: false })

      // console.log(gltf)
      this.model = gltf.scene;
      console.log(this.model)

      // this.intersects = this.raycaster.intersectObjects(this.model.children)
      // console.log(this.intersects)
      // console.log(this.model.getObjectByName('Plane003'))

      // values.info?.map((prop, index) => {

      let selectedParts = this.state.selectedParts

      values.info.forEach((prop, index) => {
        if (prop.material && prop.material != '') {
          let object = this.model.getObjectByName(prop.partName)
          // console.log(object)
          if (object) {
            if (materials[prop.material]) {
              object.material = materials[prop.material]
            }
            if (prop.defaultColor && prop.defaultColor != '') {
              // this.model.getObjectByName(prop.partName)
              object.material.color.setHex('0x' + prop.defaultColor)
            }
            selectedParts[prop.partName] = { partName: prop.partName, plus: 0, color: prop.defaultColor }

          }
        } else {
          if (prop.defaultSpecification && prop.defaultSpecification != '') {
            if (this[prop.defaultSpecification]) {
              this[prop.defaultSpecification]()

              selectedParts[prop.partName] = { partName: prop.partName, plus: 0, func: prop.defaultSpecification }

            }
          }
        }
      });

      // })

      this.model.getObjectByName('zire_dariche').material = zire_dariche;
      this.model.getObjectByName('balaye_switch').material = balaye_switch;
      this.model.getObjectByName('zire_brand').material = zire_brand;
      this.model.getObjectByName('touch_pattern').material = touch_pattern;
      this.model.getObjectByName('bottom_plate_branding1').material = bottom_plate;


      // this.model.getObjectByName('Object002').material = wheelHolderMaterial;
      // this.model.getObjectByName('Object001').material = wheelHolderMaterial;
      // this.model.getObjectByName('Object003').material = blackMaterial;
      // this.model.getObjectByName('').material = blackMaterial;


      // this.model.getObjectByName('matte_glass').visible = false;
      // this.model.getObjectByName('switch_2').visible = false;
      // this.model.getObjectByName('switch_plate').visible = false//frontBodyMaterial;
      // this.model.getObjectByName('touch_pattern').visible = false;
      // this.model.getObjectByName('touch').visible = false;

      // this.showModelTouch()
      // balaye_switch_pattern,,,
      this.model.getObjectByName('bottom_plate_branding').material = metalMaterial0;

      this.model.getObjectByName('switch_plate').material = frontBodyMaterial;


      this.model.getObjectByName('touch').material = frontBodyMaterial;

      // this.model.getObjectByName('pattern1').material = textureMaterial;
      // this.model.getObjectByName('bottom_plate_branding').material = metalMaterial;
      // this.model.getObjectByName('bottom_plate_branding').material = textureMaterial;


      // this.model.getObjectByName('plate_1_touch').material = textureMaterial;
      // this.model.getObjectByName('plate_2').material = textureMaterial;

      // this.model.getObjectByName('plate_1').material = frontBodyMaterial;
      // this.model.getObjectByName('plate_2').material = bodyMaterial;
      // this.model.getObjectByName('bottom_plat').material = detailsMaterial2;
      // this.model.getObjectByName('switch_2').material = bodyMaterial;
      this.model.getObjectByName('top_plate_opener_').material = fanBodyMaterial;
      // this.model.getObjectByName('plug').material = detailsMaterial1;
      // this.model.getObjectByName('wheel_holder').material = wheelHolderMaterial;
      // this.model.getObjectByName('bottom_plate_branding').material = metalMaterial;
      this.model.getObjectByName('black').material = shadowMaterial;
      // this.model.getObjectByName('powe_plug_place').material = blackMaterial;
      // this.model.getObjectByName('handle').material = detailsMaterial;
      // this.model.getObjectByName('switch_2').material = detailsMaterial;
      // this.model.getObjectByName('matte_metal').material = metalMaterial;
      // this.model.getObjectByName('pipe_connoctor').material = connectorMaterial;


      // this.model.getObjectByName('touch_control').material = bodyMaterial;

      // this.model.getObjectByName('the_wheel').material = blackMaterial;
      // this.model.getObjectByName('plate_1_touch').material = bodyMaterial;
      // this.model.getObjectByName('plate_1_touch').material.emissive.setHex(0x0000ff )
      // this.model.getObjectByName('plate_1_touch').material.emissive.setHex(0x0000ff )



      // this.model.getObjectByName('plate2').visible = false 
      // this.model.getObjectByName('switch_plate').material = bodyMaterial;


      // this.model.getObjectByName('matte_glass').material = glassMaterial;




      // console.log(this.model.getObjectByName('Plane003'))


      // this.model.getObjectByName('top_front_plate_opener').material = detailsMaterial;
      // this.model.getObjectByName('front_plate_opener').material = detailsMaterial;

      // this.model.getObjectByName('Plane005').material = bodyMaterial;
      // this.model.getObjectByName('Circle024').material = detailsMaterial;


      // this.model.getObjectByName('glass').material = glassMaterial;

      // this.wheels.push(
      //   this.model.getObjectByName('wheel_fl'),
      //   this.model.getObjectByName('wheel_fr'),
      //   this.model.getObjectByName('wheel_rl'),
      //   this.model.getObjectByName('wheel_rr')
      // );

      // shadow
      var mesh = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(0.655 * 4, 1.3 * 4),
        new THREE.MeshBasicMaterial({
          map: shadow, blending: THREE.MultiplyBlending, toneMapped: false, transparent: true
        })
      );
      mesh.rotation.x = - Math.PI / 2;
      mesh.renderOrder = 2;
      this.model.add(mesh);

      // let intersection = ( intersections.length ) > 0 ? intersections[ 0 ] : null;





      this.scene.add(this.model);
      this.setState({ width: window.innerWidth, height: window.innerHeight })


      // this.raycaster.setFromCamera(this.mouse, this.camera);


      document.addEventListener('mouseup', this.onDocumentMouseUp, false);
      document.addEventListener('mousedown', this.onDocumentMouseDown, false);


    });






    //ADD Your 3D this.models here
    //this.renderScene();
    //start animation
    this.start();


  }


  showModelTouch() {
    this.model.getObjectByName('balaye_switch').visible = false;

    this.model.getObjectByName('matte_glass').visible = false;
    this.model.getObjectByName('switch_2').visible = false;
    this.model.getObjectByName('switch_plate').visible = false//frontBodyMaterial;
    this.model.getObjectByName('touch_pattern').visible = true;
    this.model.getObjectByName('touch').visible = true;

    let hiddens = this.state.hiddens

    hiddens.matte_glass = true
    hiddens.balaye_switch = true
    hiddens.switch_2 = true
    hiddens.switch_plate = true

    delete hiddens.touch_pattern
    delete hiddens.touch
    this.emit(this.model.getObjectByName('touch_pattern'))

    this.setState({ hiddens })

  }

  showModelManual() {

    this.model.getObjectByName('balaye_switch').visible = true;

    this.model.getObjectByName('matte_glass').visible = true;
    this.model.getObjectByName('switch_2').visible = true;
    this.model.getObjectByName('switch_plate').visible = true//frontBodyMaterial;
    this.model.getObjectByName('touch_pattern').visible = false;
    this.model.getObjectByName('touch').visible = false;

    let hiddens = this.state.hiddens

    delete hiddens.matte_glass
    delete hiddens.balaye_switch
    delete hiddens.switch_2
    delete hiddens.switch_plate

    hiddens.touch_pattern = true
    hiddens.touch = true

    this.setState({ hiddens })
    this.emit(this.model.getObjectByName('switch_2'))

  }


  onDocumentMouseDown = () => {
    this.isDraging = false
    setTimeout(() => {
      this.isDraging = true
    }, 300);
    // console.log("DRAG")
  }


  emit = (object) => {

    let emissive = Object.assign({}, object.material.emissive)
    // console.log(emissive)
    object.material.emissive.setHex(0x0000dd)
    setTimeout(() => {
      object.material.emissive.setHex(emissive)
    }, 200);

  }


  onDocumentMouseUp = (e) => {
    // console.log(e)
    // console.log(e.nativeEvent)

    if (this.mount && this.mount.contains(e.target)) {

      let bounding = this.mount.getBoundingClientRect()


      if (!this.isDraging) {
        e.preventDefault()

        let clickPlace = {}
        clickPlace.x = e.clientX - bounding.x
        clickPlace.y = e.clientY - bounding.y



        this.mouse.x = (clickPlace.x / bounding.width) * 2 - 1;
        this.mouse.y = - (clickPlace.y / bounding.height) * 2 + 1;



        this.raycaster.setFromCamera(this.mouse, this.camera);

        // console.log(this.model)

        let intersects = this.raycaster.intersectObjects(this.model.children);

        // console.log(intersects)

        let rawIntersect = intersects.length > 0 ? intersects[0] : null

        // for (var i = 0; i < intersects.length; i++) {
        if (rawIntersect) {
          let intersect
          // console.log(rawIntersect)
          let values = this.state.data.values
          let keys = ['colors', 'colorsPlusPrice', 'specifications', 'specificationsPlusPrice']
          let hasOptions = false

          values.info.forEach((prop, index) => {
            if (prop.partName == rawIntersect.object.name) {
              intersect = rawIntersect

              console.log(prop.partName)
              this.setState({ selectedPart: prop })
              keys.forEach(key => {

                if (prop[key] && prop[key] != '') {
                  let array = prop[key].split('-')
                  if (Array.isArray(array)) {
                    hasOptions = true
                  }
                  // console.log(array)
                  // this.setState({ [key]: array })
                }

              });
              // this.setState({colors:prop.colors})

              // colorsPlusPrice
              // specifications
              // specificationsPlusPrice
            }
          });

          // intersect.material.emissive.setHex(0x0000ff)

          if (intersect && hasOptions) {
            this.emit(intersect.object)
            // console.log(intersect.object)
            this.scrollToElement(intersect.object.name)
            // let line = new THREE.Line();
            // mesh = new THREE.Mesh(geometry, material);
            // var face = intersect.face;
            var point = intersect.point;
            // console.log(this.camera.position)
            var camDistance = this.camera.position.length();
            let cameraPoint = this.camera.position.clone()
            let objectCameraRef

            this.state.data.values.info.forEach(oneItem => {
              if (oneItem.partName == intersect.object.name && oneItem.cameraPosition && oneItem.cameraPosition != '') {
                let array = oneItem.cameraPosition.split(',')
                // console.log('jjkhhjk')
                // console.log(array)
                point = new THREE.Vector3(parseFloat(array[0]), parseFloat(array[1]), parseFloat(array[2]))

                // console.log(oneItem.cameraPosition)
              }
            });
            // console.log(cameraPoint)


            // if (objectCameraRef && objectCameraRef != '') {
            //   cameraPoint = new THREE.Vector3(0, 0, 0)
            // }
            // this.camera.lookAt(new THREE.Vector3(1,2,3))
            // console.log("CLONE")
            // console.log(this.camera.position)
            // this.camera.position.copy(point).normalize().multiplyScalar(camDistance);
            this.times = this.easeAmount

            this.refPoints.x = (point.x - cameraPoint.x) / this.easeAmount
            this.refPoints.y = (point.y - cameraPoint.y) / this.easeAmount
            this.refPoints.z = (point.z - cameraPoint.z) / this.easeAmount
            this.finalPoint = point
            this.startPoint = this.camera.position

            this.camDistance = camDistance
            // console.log(this.refPoints)
          }
          // console.log("point")
          // console.log(point)

          // const quaternion = new THREE.Quaternion(point.x, point.y, point.z, 1);

          // this.model.applyQuaternion(quaternion); // Apply Quaternion

          // this.model.quaternion.normalize();  // Normalize Quaternion

          // var qm = new THREE.Quaternion();
          // THREE.Quaternion.slerp(this.camera.quaternion, 45, qm, 0.07);
          // this.camera.quaternion = qm;
          // this.camera.quaternion.normalize();

          // this.controls.target.set(point);

          // this.controls.update();

          // var linePosition = line.geometry.attributes.position;
          // var meshPosition = this.modal.geometry.attributes.position;

          // linePosition.copyAt(0, meshPosition, face.a);
          // linePosition.copyAt(1, meshPosition, face.b);
          // linePosition.copyAt(2, meshPosition, face.c);
          // linePosition.copyAt(3, meshPosition, face.a);
          // this.modal.updateMatrix();

          // line.geometry.applyMatrix4(this.modal.matrix);

          // line.visible = true;
          // scene.add(mesh);

          // this.scene.add(line);

          // this.camera.position.y = 45//.lookAt( intersect );
          // this.camera.lookAt(20,20,20)
          // this.model.position.y = Math.atan2( ( this.camera.position.x - this.model.position.x ), ( this.camera.position.z - this.model.position.z ) );

          // console.log(intersect)

          // intersect.object.material.color.set(Math.random() * 0xffffff);
        }
      }
    }
    // }

    // let vector = new THREE.Vector3(
    //   (e.clientX / window.innerWidth) * 2 - 1,
    //   - (e.clientY / window.innerHeight) * 2 + 1,
    //   0.5
    // );

    // console.log(vector)


    // this.raycaster.setFromCamera(this.mouse, this.camera);


    // this.raycaster.setFromCamera(this.mouse, this.camera);
    // this.camera.lookAt(1, 1, 1);

  }






  start = () => {
    if (!this.frameId && this.state.isPlay) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };
  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  easeAmount = 20;

  // -10,6.8,10.3

  point = {}
  times = 0
  refPoints = {}


  animate = () => {
    //Animate Models Here
    //ReDraw Scene with Camera and Scene Object
    this.renderScene();

    if (this.times > 0) {

      let cameraPoint = this.camera.position
      // console.log(cameraPoint)
      // console.log(cameraPoint.x + this.refPoints.x)
      cameraPoint.x = cameraPoint.x + this.refPoints.x
      cameraPoint.y = cameraPoint.y + this.refPoints.y
      cameraPoint.z = cameraPoint.z + this.refPoints.z
      // console.log(this.camera.position.x)
      // console.log(cameraPoint.x)
      this.camera.position.copy(cameraPoint).normalize().multiplyScalar(this.camDistance);

      // if(this.times == 10){
      //   console.log("HERE!!")
      //   console.log(this.camera.position)
      // }

      // if(this.times == this.easeAmount){
      //   setTimeout(() => {
      //     console.log(this.camera.position)

      //   }, 1000);
      // }

      this.times = this.times - 1
      if (this.times == 0) {

        // console.log(this.refPoints)
        // console.log(this.finalPoint)


        this.camera.position.copy(this.finalPoint).normalize().multiplyScalar(this.camDistance)
        // console.log(this.camera.position)

      }

      this.controls.update();

    }
    // console.log(this.camera.position)
    // this.camera.lookAt(new THREE.Vector3(this.number,this.number,this.number))
    // this.number = this.number+0.001
    this.frameId = window.requestAnimationFrame(this.animate);
  };
  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  };


  onWindowResize = () => {
    if (this.mount) {
      const width = this.mount.clientWidth;
      const height = this.mount.clientHeight;
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    }

    this.setState({ width: window.innerWidth, height: window.innerHeight })
    if (this.camera && this.renderer) {

    }
  }


  renderAnimation() {

    var time = - performance.now() / 1000;

    for (var i = 0; i < this.wheels.length; i++) {

      this.wheels[i].rotation.x = time * Math.PI;

    }

    this.grid.position.z = - (time) % 5;

    this.renderer.render(this.scene, this.camera);

    // stats.update();

  }


  changeColor = (partName, color, pricePlus) => {
    // console.log(pricePlus)
    if (this.model) {
      this.model.getObjectByName(partName).material.color.setHex('0x' + color)
    }
    let statePlusPrice = this.state.selectedParts
    let plusPrice = pricePlus ? pricePlus : 0
    statePlusPrice[partName] = { partName, plus: plusPrice, color }
    this.setState({ selectedParts: statePlusPrice })
    this.changeCameraView(partName)

    // this.emit(this.model.getObjectByName(partName))

    // object.material.color.setHex('0x' + color)
  }


  changeSpecification = (partName, func, pricePlus) => {
    // console.log(pricePlus)
    if (this[func]) {
      this[func]()
      // this.model.getObjectByName(partName).material.color.setHex('0x' + color)
    }
    let statePlusPrice = this.state.selectedParts
    let plusPrice = pricePlus ? pricePlus : 0

    statePlusPrice[partName] = { partName, plus: plusPrice, func }
    this.setState({ selectedParts: statePlusPrice })
    this.changeCameraView(partName)
    // object.material.color.setHex('0x' + color)
  }


  changeCameraView = (partName) => {
    var camDistance = this.camera.position.length();
    let cameraPoint = this.camera.position.clone()
    let objectCameraRef
    let point

    this.state.data.values.info.forEach(oneItem => {
      if (oneItem.partName == partName && oneItem.cameraPosition && oneItem.cameraPosition != '') {
        let array = oneItem.cameraPosition.split(',')

        point = new THREE.Vector3(parseFloat(array[0]), parseFloat(array[1]), parseFloat(array[2]))
      }
    });
    if (point) {

      this.times = this.easeAmount

      this.refPoints.x = (point.x - cameraPoint.x) / this.easeAmount
      this.refPoints.y = (point.y - cameraPoint.y) / this.easeAmount
      this.refPoints.z = (point.z - cameraPoint.z) / this.easeAmount
      this.finalPoint = point
      this.startPoint = this.camera.position

      this.camDistance = camDistance
    }
  }


  showOptions = (ref, key) => {

    // let keys = ['colors', 'colorsPlusPrice', 'specifications', 'specificationsPlusPrice']


    if (ref[key] && ref[key] != '') {
      let array = ref[key].split('-')

      // console.log(array)
      if (Array.isArray(array)) {
        if (key == 'colors') {
          let namesArray = []
          if (ref['colorsNames'] && ref['colorsNames'] != '') {
            namesArray = ref['colorsNames'].split('-')
          }

          let priceArray = []
          if (ref['colorsPlusPrice'] && ref['colorsPlusPrice'] != '') {
            priceArray = ref['colorsPlusPrice'].split('-')
          }

          return (
            <div className="mb-1 ">
              <p className="mb-2 mt-1 " style={{ fontSize: 11, color: '#789' }}>رنگ مورد نظر خود را انتخاب نمایید</p>
              {key == 'colors' && (
                <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                  {/* <p></p> */}
                  {array.map((prop, index) => {
                    return (
                      <button onClick={() => this.changeColor(ref.partName, prop, priceArray[index])} key={index} className="mb-2 flexc flex-column mx-1 p-2" style={{ border: '1px solid #ddd', borderRadius: 4 }}>
                        <div className="mld-1 mb-0" style={{ width: 30, height: 30, borderRadius: '50%', background: '#' + prop, border: '1px solid #eee' }}></div>
                        <span className="mt-1" style={{ color: '#789', fontSize: 12, textAlign: 'center' }}>{namesArray[index]}</span>
                        {priceArray[index] && priceArray[index] != '0' && (<span className="mt-0" style={{ color: '#789', fontSize: 12, textAlign: 'center' }}>{converNumberToPersian(priceStandardView(priceArray[index]))}+</span>)}

                      </button>
                    )
                  })}
                </div>
              )}

            </div>
          )
        }

        if (key == 'specifications') {

          let namesArray = []
          if (ref['specificationsName'] && ref['specificationsName'] != '') {
            namesArray = ref['specificationsName'].split('-')
          }

          let priceArray = []
          if (ref['specificationsPlusPrice'] && ref['specificationsPlusPrice'] != '') {
            priceArray = ref['specificationsPlusPrice'].split('-')
          }

          let imageArray = []
          if (ref['specificationsImages'] && ref['specificationsImages'] != '') {
            imageArray = ref['specificationsImages'].split(',')
          }

          // console.log(ref['specificationsImages'] )
          // console.log(priceArray)

          return (
            <div className="d-flex">
              {array.map((prop, index) => {
                return (
                  <button onClick={() => this.changeSpecification(ref.partName, prop, priceArray[index])} key={index} className="mb-2 flexc flex-column mx-1 p-2 mt-2" style={{ border: '1px solid #ddd', borderRadius: 4 }}>
                    <img className="mld-1 mb-0" src={imageArray[index]} style={{ width: 50, height: 50, borderRadius: '50%', background: '#' + prop, border: '1px solid #eee' }} />

                    <span className="mt-1" style={{ color: '#789', fontSize: 12, textAlign: 'center' }}>{namesArray[index]}</span>
                    {priceArray[index] && priceArray[index] != '0' && (<span className="mt-0" style={{ color: '#789', fontSize: 12, textAlign: 'center' }}>{converNumberToPersian(priceStandardView(priceArray[index]))}+</span>)}

                  </button>
                )
              })}
            </div>
          )

        }

        // if (key == 'names') {
        //   return (
        //     <div >
        //       <p className="mb-2 mt-1" style={{ fontSize: 11, color: '#789' }}>رنگ مورد نظر خود را انتخاب نمایید</p>
        //         <div className="flexc" style={{ flexWrap: 'wrap' }}>
        //           {/* <p></p> */}
        //           {array.map((prop, index) => {
        //             return (
        //               <div key={index}>
        //                 <div onClick={() => this.changeColor(ref.partName, prop)} className="mld-1 mb-1" style={{ width: 25, height: 25, borderRadius: '50%', background: '#' + prop, border: '1px solid #eee' }}></div>
        //               </div>
        //             )
        //           })}
        //         </div>
        //     </div>
        //   )
        // }

      }
      // console.log(array)


      // this.setState({ [key]: array })

    }


    // values.info.forEach((prop, index) => {
    //   if (prop.partName == rawIntersect.object.name) {
    //     intersect = rawIntersect

    //     this.setState({ selectedPart: prop })

    //     keys.forEach(key => {

    //       if (prop[key] && prop[key] != '') {
    //         let array = prop[key].split('-')
    //         console.log(array)
    //         this.setState({ [key]: array })
    //       }

    //     });
    //   }
    // });

  }


  checkIfHasOption(prop) {
    let keys = ['colors', 'specifications',]


    // keys.forEach(key => {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (prop[key] && prop[key] != '') {
        let array = prop[key].split('-')
        if (Array.isArray(array)) {
          return true
        }
        // console.log(array)
        // this.setState({ [key]: array })
      }
    }
    // })
  }


  changeCollapse = (index, state) => {
    let collapses = this.state.collapses
    if (!collapses) {
      collapses = {}
    }
    collapses[index] = (state != null) ? state : (collapses[index] ? !collapses[index] : true)
    this.setState({ collapses })
  }

  calcTotalPrice = (selectedParts, selectedCategories) => {
    let plus = 0
    for (const [key, value] of Object.entries(selectedParts)) {
      plus = plus + Number(value.plus)
    }

    if (selectedCategories) {
      for (const [key, value] of Object.entries(Object.values(selectedCategories))) {

        //  console.log(value.plus)
        plus = plus + Number(value.plus)
      }
    }


    return this.state.data?.values?.price ? (Number(this.state.data?.values.price) + plus) : 0
  }

  scrollToElement = (element) => {
    if (this[element]) {
      // let top = this[element].getBoundingClientRect().top
      // console.log( )
      // this[element].scrollIntoView({ behavior: "smooth" ,block: 'start'});
      // window.scrollTo(0,0)
      let target = document.getElementById(element);
      this.listContainer.scroll({ top: target.offsetTop - (this.state.width >= 720 ? 90 : 10), behavior: 'smooth' })
      // target.style.border = '2px solid #ee3030'
      target.classList.add('glow')

      // console.log(target)
      this.changeCollapse(target.getAttribute('index'), false)

      setTimeout(() => {
        target.classList.remove('glow')

      }, 1000);

      // target.parentNode.scrollTop = target.offsetTop + ;
    }
  }

  gotoAccessories = () => {
    if (this.state.groups == {}) {
      this.fetchAccessories()
    }

    this.setState({ stage: 1, isPlay: false }, () => {
      this.stop();

      // this.init()
    })

  }

  goto3D = () => {

    this.setState({ stage: 0, isPlay: true }, () => {
      // this.start();
      this.animate();

      // this.init()
    })

  }


  changeSelectedAccessory(prop, group) {
    // console.log(group)
    let plusPrice = 0
    let selectedAccessoreis = this.state.selectedAccessoreis
    if (!selectedAccessoreis[prop._id]) {
      let plus = prop.values.plusPrice ? prop.values.plusPrice : 0

      if (group != '*') {
        Object.values(selectedAccessoreis).forEach(selected => {
          if (selected.group === group) {
            // plusPrice = plusPrice - selectedAccessoreis[selected.accessory._id].plus
            delete selectedAccessoreis[selected.accessory._id]
          }
        });
      }


      selectedAccessoreis[prop._id] = { group, plus: Number(plus), accessory: prop }
      // plusPrice = plusPrice + plus
      // console.log("TRUE")



    } else {
      if (selectedAccessoreis[prop._id].group === '*') {
        // plusPrice = plusPrice - selectedAccessoreis[prop._id].plus
        delete selectedAccessoreis[prop._id]
      }
    }
    // console.log(selectedAccessoreis)

    this.setState({ selectedAccessoreis })
  }

  render() {

    let isDesktop = this.state.width >= 720

    // console.log(this.state.width)
    return (
      <Section >
        {true && (

          <div className={"container-fluid p-0 m-0 " + (isDesktop ? 'd-flex' : '')} style={{ position: this.state.stage == 0 ? '' : 'absolute', opacity: this.state.stage == 0 ? 1 : 0, backgroundColor: '#f2f6f8' }}>

            <div
              style={{ width: isDesktop ? "calc(100% - 300px)" : '100%', height: isDesktop ? "100vh" : '60vh', outline: 'none' }}
              ref={mount => { this.mount = mount }}
            />

            <div style={{ backgroundColor: '#f2f6f8', width: isDesktop ? '300px' : '100%', paddingTop: isDesktop ? 70 : 0, height: isDesktop ? "100vh" : '40vh' }}>
              {/* <p className="text-center">Opia Home</p> */}
              <div style={{ position: 'relative', backgroundColor: '#fff', boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', borderRadius: isDesktop ? '0px 15px 15px 0px' : '15px 15px 0px 0px', overflow: 'hidden', height: isDesktop ? 'calc(100% - 60px)' : '100%' }}>
                <div ref={el => this.listContainer = el} style={{ overflow: 'auto', height: '100%', width: '100%' }}>
                  <div className="blur-back text-center" style={{ borderBottom: '1px solid #eee', width: '100%', position: isDesktop ? 'sticky' : null, top: 0, backgroundColor: '#ffffffee', padding: '10px 15px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>شخصی سازی</p>
                    <p className="text-small" style={{ fontSize: 11, color: '#789' }}>شما میتوانید محصول را مطابق با سلیقه خود طراحی نمایید و سفارش آن را ثبت نمایید</p>

                    {/* <p className="text-small">{translate('opia-custom')}</p> */}

                  </div>
                  <div style={{ padding: '15px 10px 60px 10px', borderRadius: 15 }}>
                    {this.state.data?.values?.info?.map((prop, index) => {
                      if (this.checkIfHasOption(prop)) {
                        if (!this.state.hiddens[prop.partName])
                          return (
                            <div ref={el => this[prop.partName] = el} key={index} index={index} id={prop.partName} className="mb-2" style={{ border: '1px solid #fff', backgroundColor: '#f2f6f8', borderRadius: 8, padding: '10px' }}>
                              <div className="flexcb ">
                                <p style={{ fontWeight: 'bold', fontSize: 18 }}>{prop.title}</p>
                                <button onClick={() => this.changeCollapse(index)} style={{ backgroundColor: '#e2e8f2', borderRadius: 8, padding: '0px 10px', fontWeight: 'bold', fontSize: 20, color: '#789', width: 30 }}>{this.state.collapses[index] ? '+' : '-'}</button>
                              </div>
                              <Collapsible open={!this.state.collapses[index]}  >
                                {this.showOptions(prop, 'colors')}
                                {this.showOptions(prop, 'specifications')}
                              </Collapsible>

                            </div>
                          )
                      }
                    })}
                  </div>
                  {this.calcTotalPrice(this.state.selectedParts, this.state.selectedAccessoreis) != null && (
                    <button onClick={() => this.gotoAccessories()} className="blur-back flexcb" style={{ padding: '10px 20px', position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: '#101010ee' }}>
                      <p className="text-center white text-small">ادامه سفارش</p>
                      <p className="text-center white text-small">{converNumberToPersian(translate('currency', { price: priceStandardView(this.calcTotalPrice(this.state.selectedParts, this.state.selectedAccessoreis)) }))}</p>
                    </button>
                  )}

                </div>
              </div>


            </div>



          </div>

        )}


        {this.state.stage == 1 && (

          <div className={"container-fluid p-0 m-0 " + (isDesktop ? 'd-flex' : '')} style={{ backgroundColor: '#f2f6f8' }}>

            <div style={{ width: isDesktop ? "calc(100% - 300px)" : '100%', height: isDesktop ? "100vh" : '60vh', outline: 'none', overflow: 'auto' }}>

              <div className="container mt-4 py-4 px-5">
                <div className="row mt-4">
                  {Object.values(this.state.groups)?.map((prop, index) => {
                    return (
                      <div className="col-12 mb-5" key={index}>
                        <p>{prop.name}</p>
                        <div className="row">
                          {prop.accessories?.map((cprop, cindex) => {
                            let selected = false
                            if (this.state.selectedAccessoreis[cprop._id]) {
                              selected = true
                            }
                            return (
                              <button onClick={() => this.changeSelectedAccessory(cprop, prop.id)} className={"col-6 col-md-6 col-lg-3 mt-4 flexcc flex-column  "} key={cindex} style={{}}>
                                <div className={"border-radius-4 px-0 pb-3 h-100  " + (selected ? 'glow-static' : '')} style={{ backgroundColor: '#fff' }}>

                                  <img src={imageAddress(cprop.values?.images[0], 'product', 'thumb')} alt="" style={{ borderRadius: '4px 4px 0px 0px', width: '100%', objectFit: 'contain' }} />
                                  <div className="mb-2">
                                    <p className="mt-3 text-small">{cprop.values.name}</p>
                                    {/* <p className="text-normal mt-2 mb-2" style={{ fontWeight: 'normal' }}>{stripHtml(cprop.values.description)}</p> */}
                                    {cprop.values.plusPrice && (
                                      <p className="text-small mt-2" >+ {converNumberToPersian(priceStandardView(cprop.values.plusPrice))} تومان</p>
                                    )}
                                    {/* {prop?.values?.unit && (<p className={'text-small mt-2'}>در واحد {prop?.values?.unit}</p>)} */}
                                  </div>


                                  {/* {cprop.values.name} */}
                                </div>
                              </button>
                            )
                          })}

                        </div>
                      </div>
                    )
                  })}
                  {/* <div className="col-12 mb-5 mt-4">
                    <DataGrid isLoading={this.state.isLoading} data={this.state.accessories} schema={{ title: '{{lng}}.name', description: '{{lng}}.description', price: '{{lng}}.price', image: 'image', portals: 'portals' }} component={Product} col="col-6 col-md-6 col-lg-3" background={"#fff"} />
                  </div> */}
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: '#f2f6f8', width: isDesktop ? '300px' : '100%', paddingTop: isDesktop ? 70 : 0, height: isDesktop ? "100vh" : '40vh' }}>
              {/* <p className="text-center">Opia Home</p> */}
              <div style={{ position: 'relative', backgroundColor: '#fff', boxShadow: '0px 0px 30px rgba(0,0,0,0.1)', borderRadius: isDesktop ? '0px 15px 15px 0px' : '15px 15px 0px 0px', overflow: 'hidden', height: isDesktop ? 'calc(100% - 60px)' : '100%' }}>
                <div ref={el => this.listContainer = el} style={{ overflow: 'auto', height: '100%', width: '100%' }}>
                  <div className="blur-back text-center" style={{ borderBottom: '1px solid #eee', width: '100%', position: isDesktop ? 'sticky' : null, top: 0, backgroundColor: '#ffffffee', padding: '10px 15px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>شخصی سازی</p>
                    <p className="text-small" style={{ fontSize: 11, color: '#789' }}>شما میتوانید محصول را مطابق با سلیقه خود طراحی نمایید و سفارش آن را ثبت نمایید</p>

                    {/* <p className="text-small">{translate('opia-custom')}</p> */}

                  </div>
                  <div style={{ padding: '15px 10px 90px 10px', borderRadius: 15 }}>
                    {Object.values(this.state.selectedAccessoreis)?.map((prop, index) => {
                      return (
                        <div ref={el => this[prop.partName] = el} key={index} index={index} id={prop.partName} className="mb-2 flexc" style={{ border: '1px solid #fff', backgroundColor: '#f2f6f8', borderRadius: 8, padding: '10px' }}>
                          <img src={imageAddress(prop.accessory.values?.images[0], 'product', 'thumb')} alt="" style={{ borderRadius: '50px', width: '50px', height: '50px', objectFit: 'fit' }} />
                          <div className="mb-2 mld-3">
                            <p className="mt-3 text-small">{prop.accessory.values.name}</p>
                            <p className="text-normal mt-2 mb-2" style={{ fontWeight: 'normal' }}>{stripHtml(prop.accessory.values.description)}</p>
                            {prop.accessory.values.plusPrice && (
                              <p className="text-small mt-2" >+ {converNumberToPersian(priceStandardView(prop.accessory.values.plusPrice))} تومان</p>
                            )}
                            {/* {prop?.values?.unit && (<p className={'text-small mt-2'}>در واحد {prop?.values?.unit}</p>)} */}
                          </div>

                        </div>
                      )

                    })}
                  </div>
                  {this.calcTotalPrice(this.state.selectedParts, this.state.selectedAccessoreis) != null && (
                    <div className="blur-back " style={{ padding: '10px 20px', position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: '#101010ee' }}>
                      <button className="flexcb w-100" onClick={() => this.gotoAccessories()} >
                        <p className="text-center white text-small">ادامه سفارش</p>
                        <p className="text-center white text-small">{converNumberToPersian(translate('currency', { price: priceStandardView(this.calcTotalPrice(this.state.selectedParts, this.state.selectedAccessoreis)) }))}</p>
                      </button>

                      <button className="w-100 mt-2 pt-2" onClick={() => this.goto3D()} style={{ borderTop: '1px solid #ffffff50' }}>
                        <p className="text-center white text-small">بازگشت</p>
                      </button>
                    </div>
                  )}



                </div>
              </div>


            </div>

          </div>


        )}


        {this.state.isLoadingPage && (
          <div className="flexcc" style={{ backgroundColor: '#00000080', textAlign: 'center', width: '100%', position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh' }}>
            <Loader
              type="Oval"
              color="rgba(255,255,255,1)"
              height="100"
              width="100"
            />
          </div>
        )}


        {/* </div> */}


      </Section>
    );
  }
}

const mapStateToProps = state => ({ cart: state.cart, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreeD);
