import React from 'react';
import { translate } from '../utils/useful'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import Section from '../components/Section'
import StagesManager from '../components/StagesManager';
import CheckoutSide from './checkout/CheckoutSide';
import HttpService from '../utils/Http.services';

import ShoppingList from './checkout/ShoppingList';
import AddressList from './checkout/AddressList';
import CheckoutInfo from './checkout/CheckoutInfo';
import PaymentMethod from './checkout/PaymentMethod';
import { setObject } from "../utils/useful";
import YouShouldLogin from './checkout/YouShouldLogin';
import Modal from '../components/Modal'
import TLink from '../components/TLink'
import { siteConfig } from '../variables/config';


class CheckOut extends React.Component {

  state = {
    info: {},
    currentStage: 0,
    stages: [
      { component: ShoppingList, name: 'ShoppingList', stage: 0, stageName: 'ShoppingList' },
      { component: AddressList, name: 'AddressList', stage: 1, stageName: 'AddressList' },
      { component: CheckoutInfo, name: 'CheckoutInfo', stage: 2, stageName: 'CheckoutInfo' },
      { component: PaymentMethod, name: 'PaymentMethod', stage: 3, stageName: 'PaymentMethod' },
      { component: YouShouldLogin, name: 'YouShouldLogin', stage: 10, stageName: 'YouShouldLogin' },
    ]
  }

  componentDidMount() {
    //this.stageManager.changeStage("PaymentMethod")
    // this.successModal.showModal()

  }


  nextShoppingStage = () => {

    let info = {}
    this.setState({ error: null })

    if (this.state.currentStage === 0 && !this.props.user.loggedin) {
      this.stageManager.changeStage('YouShouldLogin')
      return
    }

    this.state.stages.forEach(oneStage => {
      if (this.state.currentStage === oneStage.stage || this.state.currentStage === oneStage.stageName) {
        if (this[oneStage.name] && this[oneStage.name].getInfo) {
          this[oneStage.name].getInfo((takenInfo, err) => {
            if (err) {
              info = null
              this.setState({ error: err })
              return
            } else {
              info = takenInfo
            }
          })
        }
      }
    });

    if (info) {
      this.setData(info, () => {
        if (this.state.currentStage === this.state.stages.length - 2) {
          // console.log(this.state.info)
          // GUIDE THIS IS THE LAST STAGE
          this.sumbitOrder()
        } else {
          this.stageManager.nextStage((currentStage) => {
            this.setState({ currentStage })
          })
        }

      })
    }

  }


  sumbitOrder = () => {
    // console.log("this.sumbitOrder")
    // console.log(this.state.info)
    if (this.state.info.method == 'cash') {

      this.setState({ isLoading: true, errors: {} })
      HttpService.request('submitOrder', this.state.info, (fetchResult, fetchError) => {
        this.setState({ isLoading: false })

        if (fetchError) { this.setState({ errors: fetchError.message }); return }

        this.successModal.showModal()
        this.props.actions.clearAllCart()
        this.stageManager.changeStage(0)

      })
    } else {

      this.setState({ isLoading: true, errors: {} })
      // console.log(this.state.info)
      HttpService.request('submitPreorder', this.state.info, (fetchResult, fetchError) => {
        this.setState({ isLoading: false })
        // console.log(fetchError)
        if (fetchError) { this.setState({ errors: fetchError.message }); return }
        if (fetchResult.url && fetchResult.url !== '') {
          window.location = fetchResult.url
        }
        // console.log(fetchResult)

        // this.successModal.showModal()
        // this.props.actions.clearAllCart()
        // this.stageManager.changeStage(0)

      })

    }
  }


  lastStage = () => {
    this.setState({ error: null })
    this.stageManager.lastStage((currentStage) => {
      this.setState({ currentStage })
    })
  }


  setData = (data, cb) => {
    if (data) {
      let newData = this.state.info
      for (const [key, value] of Object.entries(data)) {
        if (key == '**files') {
          if (!newData["**files"]) {
            newData["**files"] = {}
          }
          newData["**files"] = { ...value }
        } else {
          setObject(newData, key, value)
        }
      }

      this.setState({ info: newData }, () => {
        if (cb) { cb() }
      })
    }
  }


  useOffCode = (info) => {
    // console.log(info._id)
    this.setState({ off: info })
    this.setData({ discount: info._id })
  }


  render() {

    return (
      <Section background="#f2f6f8" minHeight="100vh">
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
          <div className="row">
            <div className="col-12  col-md-6 col-lg-4">
              <CheckoutSide off={this.state.off} currentStage={this.state.currentStage} stages={this.state.stages} user={this.props.user} nextShoppingStage={this.nextShoppingStage} lastStage={this.lastStage} currentStage={this.state.currentStage} error={this.state.error} />
            </div>

            <div className="col-12  col-md-6 col-lg-8">
              <StagesManager ref={el => this.stageManager = el}>

                {this.state.stages.map((prop, index) => {
                  let Component = prop.component
                  return (
                    <Component key={index} ref={el => this[prop.name] = el} data={this.state.info} stageName={prop.stageName} stage={prop.stage} useOffCode={this.useOffCode} off={this.state.off} />
                  )

                })}

              </StagesManager>
            </div>
          </div>
        </div>


        <Modal ref={el => this.successModal = el} maxWidth={300} position="" >
          <div className="text-left" style={{ padding: '30px 25px' }}>
            <p className="text-center mb-2 font-weight-bold text-semibig">{translate("successful")}</p>

            <p className="text-center mb-3 text-normal" style={{ color: '#789' }}>{translate('orderSuccessDesc')}</p>

            <div className='text-center'>

              <TLink to={'/'} >
                <button className="w-100 font-bold text-small" style={{ backgroundColor: '#007aff', color: '#fff', padding: '10px 15px 10px 15px', borderRadius: 8 }}>
                  {translate('goToHome')}
                </button>
              </TLink>

              <TLink to={'/me/rentals'} className="w-100" >
                <button className="w-100 mt-1 font-bold text-small" style={{ backgroundColor: '#789', color: '#fff', padding: '10px 15px 10px 15px', borderRadius: 8 }}>
                  {translate("goToOrders")}
                </button>
              </TLink>

            </div>
          </div>
        </Modal>


      </Section>
    );
  }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckOut);

